import React, { useEffect, useState } from 'react'
import { Main } from '../styled';
import { PageHeader } from '../../components/page-headers/page-headers';
import { Cards } from '../../components/cards/frame/cards-frame';
import { Col, Form, Input, Row, Select, Table, Tooltip, message } from 'antd';
import { Button } from '../../components/buttons/buttons';
import UilSearch from '@iconscout/react-unicons/icons/uil-search';
import UilCheckCircle from '@iconscout/react-unicons/icons/uil-check-circle';
import UilTimesCircle from '@iconscout/react-unicons/icons/uil-times-circle';
import { DataTableStyleWrap } from '../../components/table/Style';
import { useDispatch, useSelector } from 'react-redux';
import { getTeacherMonitorData } from '../../redux/teacher-monitor/actionCreator'
import { getRegulationYearsems, getScheduleWeeks } from '../../redux/schedule/actionCreator';
import { getSectionsRead } from '../../redux/section/actionCreator';
import CustomSpinner from '../ui-elements/spinner/CustomSpinner';
import topicReadBadge from '../../static/img/TOPIC-READ/TOPIC-READ-24X24.png'
import topicCompleteBadge from '../../static/img/TOPIC-COMPLETE/TOPIC-COMPLETE-24X24.png'
import { readTeacherMonitorSuccess } from '../../redux/teacher-monitor/actions'
import actions from '../../redux/schedule/actions';
import { CSVLink } from 'react-csv';

const { getregyrsemSuccess, getScheduleSuccess } = actions

function Monitor() {

    const dispatch = useDispatch()
    const [form] = Form.useForm();
    const [subjectId, setSubjectId] = useState(0)
    const [unitId, setUnitId] = useState(0)
    const [sectionId, setSectionId] = useState(0)
    const [regulationId, setRegulationId] = useState(0)
    const [yearsemId, setYearsemId] = useState(0)

    const { TeacherMonitor, TeacherMonitorLoading, SubjectsUnits, SubjectsUnitsLoading, Weeks, weeksLoading, Sections, SectionsLoading, RegYrsem, RegYrsemLoading } = useSelector((state) => {
        return {
            TeacherMonitor: state.TeacherMonitor.data,
            TeacherMonitorLoading: state.TeacherMonitor.loading,
            SubjectsUnits: state.SubjectsUnits.data,
            SubjectsUnitsLoading: state.SubjectsUnits.loading,
            Weeks: state.Weeks.data,
            weeksLoading: state.Weeks.loading,
            Sections: state.Sections.data,
            SectionsLoading: state.Sections.loading,
            RegYrsem: state.RegYrsem.data,
            RegYrsemLoading: state.RegYrsem.loading,
        }
    })

    useEffect(() => {
        dispatch(readTeacherMonitorSuccess([]))
    }, [])

    useEffect(() => {
        dispatch(getregyrsemSuccess(null))
        if (subjectId !== 0) {
            dispatch(getRegulationYearsems(subjectId))
        }
    }, [subjectId])

    useEffect(() => {
        if (regulationId !== 0 && yearsemId !== 0) {
            dispatch(getScheduleSuccess([]))
            dispatch(getScheduleWeeks(regulationId, yearsemId))
            dispatch(getSectionsRead(regulationId, yearsemId, subjectId))
        }
    }, [regulationId, yearsemId])

    const columnsSort = [
        {
            title: 'S.No.',
            dataIndex: 'sno',
        },
        {
            title: 'Roll No',
            dataIndex: 'rollno',
            // defaultSortOrder: 'descend',
            sorter: (a, b) => a.rollno > b.rollno ? 1 : -1,
            sortDirections: ['ascend'],
        },
        {
            title: 'Name',
            dataIndex: 'student_name',
            onFilter: (value, record) => record.student_name.indexOf(value) === 0,
            sorter: (a, b) => a.student_name.length - b.student_name.length,
            sortDirections: ['descend'],
        },
        {
            title: <Tooltip title='MCQ Badge'><img src={topicReadBadge} width={16} /></Tooltip>,
            dataIndex: 'readBadgeCount',
            // defaultSortOrder: 'descend',
            sorter: (a, b) => a.readBadgeCount - b.readBadgeCount,
        },
        {
            title: 'QA Score',
            dataIndex: 'assesmentQaScore',
            sorter: (a, b) => a.assesmentQaScore - b.assesmentQaScore,
        },
        {
            title: 'Program Score',
            dataIndex: 'assesmentProgramScore',
            sorter: (a, b) => a.assesmentProgramScore - b.assesmentProgramScore,
        },
        {
            title: 'Assesment Score',
            dataIndex: 'assesmentScore',
            sorter: (a, b) => a.assesmentScore - b.assesmentScore,
        },
        // {
        //     title: <Tooltip title='Assessment Badge'><img src={topicCompleteBadge} width={16} /></Tooltip>,
        //     dataIndex: 'assesmentBadge',
        //     // defaultSortOrder: 'descend',
        //     sorter: (a, b) => a.assesmentBadge - b.assesmentBadge,
        // },
    ];


    const [tableData, setTableData] = useState([])
    const [filteredData, setFilteredData] = useState([])

    useEffect(() => {
        let data = []
        TeacherMonitor.length !== 0 && TeacherMonitor.map((obj, index) => {
            const { userId, userName, studentName, readBadgeCount, assesmentBadge, assesmentScore, assesmentQaScore, assesmentProgramScore, totalTopics, sectionName, subjectName, unitName } = obj
            data.push({
                key: index,
                "sno": index + 1,
                student_id: userId,
                rollno: userName,
                student_name: studentName,
                readBadgeCount,
                assesmentScore: assesmentScore !== null ? assesmentScore : 'NA',
                assesmentQaScore: assesmentQaScore !== null ? assesmentQaScore : 'NA',
                assesmentProgramScore: assesmentProgramScore !== null ? assesmentProgramScore : 'NA',
                assesmentBadge: assesmentBadge !== null ? (assesmentBadge === 2 ? <UilCheckCircle className="text-success" size={16} /> : <UilTimesCircle className="text-danger" size={16} />) : 'NA',
                totalTopics,
                section_name: sectionName,
                subject_name: subjectName,
                unitName
            })
        })
        setTableData(data)
        setFilteredData(data)
    }, [TeacherMonitor])

    const loadStudentsData = () => {
        if (subjectId !== 0 && unitId !== 0 && sectionId !== 0) {
            dispatch(getTeacherMonitorData(unitId, regulationId, yearsemId, sectionId))
        }
        else {
            message.warn("Select all filters!!")
        }
    };

    const searchkeys = ['rollno', 'student_name']
    const searchStudent = (e) => {
        const { value } = e.currentTarget;
        const searchData = tableData.length !== 0 && tableData.filter((obj) => searchkeys.some((key) => obj[key].toLowerCase().includes(value.toLowerCase())))
        setFilteredData(searchData)
    };


    //export
    const headers = [
        { label: "S.No", key: "sno" },
        { label: "RollNo", key: "rollno" },
        { label: "StudentName", key: "student_name" },
        { label: "Section", key: "section_name" },
        { label: "SubjectName", key: "subject_name" },
        { label: "UnitName", key: "unitName" },
        { label: "Total Topics", key: "totalTopics" },
        { label: "ReadBadgeCount", key: "readBadgeCount" },
        { label: "QA_Score", key: "assesmentQaScore" },
        { label: "Program_Score", key: "assesmentProgramScore" },
        { label: "AssesmentScore", key: "assesmentScore" },
        // { label: "AssesmentBadge", key: "assesmentBadge" },
    ];

    const csvReport = {
        filename: "Student_Badges_List.csv",
        headers: headers,
        data: filteredData
    }

    return (
        <div>
            <Main className='pt-20'>
                {/* <PageHeader className="ninjadash-page-header-main p-0" title="Monitor" /> */}
                <Cards title="Activity Monitor">
                    <DataTableStyleWrap>
                        <div className="ninjadash-datatable-filter">
                            <div className="ninjadash-datatable-filter__left">
                                <div className="ninjadash-datatable-filter__input">
                                    <span className="label">Subject:</span>
                                    <Select id="monitor_select-subject" style={{ width: 200 }} className="ninjadash-data-status" placeholder="select" onChange={(e) => { setSubjectId(e); setRegulationId(0); setYearsemId(0); setUnitId(0); setSectionId(0) }} loading={SubjectsUnitsLoading}>
                                        {
                                            SubjectsUnits !== null && SubjectsUnits.subjects.length !== 0 && SubjectsUnits.subjects.sort((a, b) => a.name > b.name ? 1 : -1).map((subjectObj, subjectIndex) =>
                                                <Select.Option value={subjectObj.id} key={subjectIndex}>{subjectObj.name}</Select.Option>
                                            )
                                        }
                                    </Select>
                                </div>
                                <div className="ninjadash-datatable-filter__input">
                                    <span className="label">Unit:</span>
                                    <Select id="monitor_select-unit" style={{ width: 150 }} className="ninjadash-data-status" placeholder="select" value={unitId} onChange={(e) => { setUnitId(e); setRegulationId(0); setYearsemId(0); setSectionId(0) }} loading={SubjectsUnitsLoading}>
                                        <Select.Option value={0} key={'selectunit'} >Select</Select.Option>
                                        {
                                            subjectId !== 0 && SubjectsUnits !== null && SubjectsUnits.subjects.length !== 0 && SubjectsUnits.subjects.filter((obj) => obj.id === subjectId).map((subjectObj, subjectIndex) =>
                                                subjectObj !== null && subjectObj.units?.map((unit, unitIndex) =>
                                                    <Select.Option value={unit.id} key={unitIndex}>{unit.name}</Select.Option>
                                                )
                                            )
                                        }
                                    </Select>
                                </div>
                                <div className="ninjadash-datatable-filter__input">
                                    <span className="label">Regulation:</span>
                                    <Select id="monitor_select-regulation" style={{ width: 200 }} className="ninjadash-data-status" placeholder="select" value={regulationId} onChange={(e) => { setRegulationId(e); setYearsemId(0); setSectionId(0) }} loading={RegYrsemLoading}>
                                        <Select.Option value={0} key={'selectreg'} >Select</Select.Option>
                                        {
                                            RegYrsem !== null && RegYrsem.regulations.length !== 0 && RegYrsem.regulations.sort((a, b) => a.code > b.code ? 1 : -1).map((reg, regIndex) =>
                                                <Select.Option value={reg.id} key={regIndex}>{reg.code}</Select.Option>
                                            )
                                        }
                                    </Select>
                                </div>
                                <div className="ninjadash-datatable-filter__input">
                                    <span className="label">YearSem:</span>
                                    <Select id="monitor_select-yearsem" style={{ width: 200 }} className="ninjadash-data-status" placeholder="select" value={yearsemId} onChange={(e) => { setYearsemId(e); setSectionId(0) }} loading={RegYrsemLoading}>
                                        <Select.Option value={0} key={'selectyrsem'} >Select</Select.Option>
                                        {
                                            regulationId !== 0 && RegYrsem !== null && RegYrsem.yearsemesters.length !== 0 && RegYrsem.yearsemesters.sort((a, b) => a.code > b.code ? 1 : -1).map((yrsem, yrsemIndex) =>
                                                <Select.Option value={yrsem.id} key={yrsemIndex}>{yrsem.code}</Select.Option>
                                            )
                                        }
                                    </Select>
                                </div>
                                <div className="ninjadash-datatable-filter__input">
                                    <span className="label">Section:</span>
                                    <Select id="monitor_select-section" style={{ width: 160 }} className="ninjadash-data-status" placeholder="select" value={sectionId} onChange={(e) => setSectionId(e)} loading={SectionsLoading}>
                                        <Select.Option value={0} key={'selectsection'} >Select</Select.Option>
                                        {
                                            Sections.length !== 0 && Sections.sort((a, b) => a.name > b.name ? 1 : -1).map((section, sectionIndex) =>
                                                <Select.Option value={section.id} key={sectionIndex}>{section.name}</Select.Option>
                                            )
                                        }
                                    </Select>
                                </div>
                                <div className="ninjadash-datatable-filter__action">
                                    <Button id="monitor_get-button" type="primary" size="small" onClick={() => { loadStudentsData() }}>
                                        Get
                                    </Button>
                                </div>
                            </div>
                        </div>
                        {
                            TeacherMonitorLoading ?
                                <div style={{ width: '100%', height: '400px' }}>
                                    <div className="custom-spin">
                                        <CustomSpinner />
                                    </div>
                                </div>
                                :
                                <div>
                                    <div className='d-flex justify-content-between mb-15 p-0 align-center-v'>
                                        {
                                            TeacherMonitor.length !== 0 &&
                                            <p className='m-0'>
                                                Week Topics: {TeacherMonitor[0].totalTopics}
                                            </p>
                                        }
                                        <div className="d-flex align-center-v" style={{ marginLeft: 'auto' }}>
                                            <CSVLink {...csvReport}>
                                                <Button id="monitor_export-button" type="primary" size="small" className="mr-10" disabled={filteredData.length !== 0 ? false : true}>
                                                    Export
                                                </Button>
                                            </CSVLink>
                                            <Input id="monitor_search-input" className='' onChange={searchStudent} size="default" placeholder="Rollno. or Name" prefix={<UilSearch />} />
                                        </div>
                                    </div>
                                    <Table
                                        className="table-responsive"
                                        pagination={{
                                            defaultPageSize: 15,
                                            total: filteredData.length,
                                            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                                        }}
                                        columns={columnsSort}
                                        dataSource={filteredData}
                                        tableLayout='auto'
                                        size='middle'
                                    />
                                </div>
                        }
                    </DataTableStyleWrap>
                </Cards>
            </Main>
        </div >
    )
}

export default Monitor