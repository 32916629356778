import {
  UilCreateDashboard,
  UilBooks,
} from '@iconscout/react-unicons';
import { Menu } from 'antd';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

import UilEllipsisV from '@iconscout/react-unicons/icons/uil-ellipsis-v';
import propTypes from 'prop-types';
import { NavTitle } from './Style';
import { studentSubjectsDataRead } from '../redux/studentSubjectsUnits/actionCreator';

function MenuItems({ toggleCollapsed }) {  

  function getItem(label, key, icon, children, type) {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  }

  const { topMenu } = useSelector((state) => {
    return {
      topMenu: state.ChangeLayoutMode.topMenu,
    };
  });

  const dispatch = useDispatch();

  const path = '/student';

  const pathName = window.location.pathname;
  const pathArray = pathName.split(path);
  const mainPath = pathArray[1];
  const mainPathSplit = mainPath.split('/');

  const [openKeys, setOpenKeys] = React.useState(
    !topMenu ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : 'dashboard'}`] : [],
  );

  const onOpenChange = (keys) => {
    setOpenKeys(keys[keys.length - 1] !== 'recharts' ? [keys.length && keys[keys.length - 1]] : keys);
  };

  const onClick = (item) => {
    if (item.keyPath.length === 1) setOpenKeys([]);
  };

  useEffect(() => {
    dispatch(studentSubjectsDataRead());
  }, [dispatch]);

  const { StudentSubjects } = useSelector((state) => {
    return {
      StudentSubjects: state.StudentSubjects.data,
      isLoading: state.StudentSubjects.loading,
    };
  });

  const year1sem1 = []
  const year1sem2 = []
  const year2sem1 = []
  const year2sem2 = []
  const year3sem1 = []
  const year3sem2 = []
  const year4sem1 = []
  const year4sem2 = []


  StudentSubjects.length !== 0 && StudentSubjects.map((subjObj,index) => {
    subjObj.yearsemester_code === 'I-I' || subjObj.yearsemester_code === 'I' ?
    year1sem1.push(
      getItem(
        <NavLink
          to={`${path}/subject/${subjObj.subject_id}`}
          onClick={toggleCollapsed}
        >
          {subjObj.subject_name}
        </NavLink>,
        index,
        !topMenu && <UilBooks />,
      ),
    )
    :
    subjObj.yearsemester_code === 'I-II' || subjObj.yearsemester_code === 'II' ?
    year1sem2.push(
      getItem(
        <NavLink
          to={`${path}/subject/${subjObj.subject_id}`}
          onClick={toggleCollapsed}
        >
          {subjObj.subject_name}
        </NavLink>,
        index,
        !topMenu && <UilBooks />,
      ),
    )
    :
    subjObj.yearsemester_code === 'II-I' || subjObj.yearsemester_code === 'III' ?
    year2sem1.push(
      getItem(
        <NavLink
          to={`${path}/subject/${subjObj.subject_id}`}
          onClick={toggleCollapsed}
        >
          {subjObj.subject_name}
        </NavLink>,
        index,
        !topMenu && <UilBooks />,
      ),
    )
    :
    subjObj.yearsemester_code === 'II-II' || subjObj.yearsemester_code === 'IV' ?
    year2sem2.push(
      getItem(
        <NavLink
          to={`${path}/subject/${subjObj.subject_id}`}
          onClick={toggleCollapsed}
        >
          {subjObj.subject_name}
        </NavLink>,
        index,
        !topMenu && <UilBooks />,
      ),
    )
    :
    subjObj.yearsemester_code === 'III-I' || subjObj.yearsemester_code === 'V' ?
    year3sem1.push(
      getItem(
        <NavLink
          to={`${path}/subject/${subjObj.subject_id}`}
          onClick={toggleCollapsed}
        >
          {subjObj.subject_name}
        </NavLink>,
        index,
        !topMenu && <UilBooks />,
      ),
    )
    :
    subjObj.yearsemester_code === 'III-II' || subjObj.yearsemester_code === 'VI' ?
    year3sem2.push(
      getItem(
        <NavLink
          to={`${path}/subject/${subjObj.subject_id}`}
          onClick={toggleCollapsed}
        >
          {subjObj.subject_name}
        </NavLink>,
        index,
        !topMenu && <UilBooks />,
      ),
    )
    :
    subjObj.yearsemester_code === 'IV-I' || subjObj.yearsemester_code === 'VII' ?
    year4sem1.push(
      getItem(
        <NavLink
          to={`${path}/subject/${subjObj.subject_id}`}
          onClick={toggleCollapsed}
        >
          {subjObj.subject_name}
        </NavLink>,
        index,
        !topMenu && <UilBooks />,
      ),
    )
    :
    subjObj.yearsemester_code === 'IV-II' || subjObj.yearsemester_code === 'VIII' &&
    year4sem2.push(
      getItem(
        <NavLink
          to={`${path}/subject/${subjObj.subject_id}`}
          onClick={toggleCollapsed}
        >
          {subjObj.subject_name}
        </NavLink>,
        index,
        !topMenu && <UilBooks />,
      ),
    )
  });


  const items = [
    getItem(
      <NavLink onClick={toggleCollapsed} to={`${path}`}>
        Dashboard
      </NavLink>,
      'dashboard',
      !topMenu && <UilCreateDashboard />,
    ),
    getItem(
      !topMenu && <NavTitle className="ninjadash-sidebar-nav-title">1st year 1st Semester</NavTitle>,
      'Year - I Sem - I',
      null,
      null,
      'group',
    ),

    ...year1sem1,

    getItem(
      !topMenu && <NavTitle className="ninjadash-sidebar-nav-title">1st year 2nd Semester</NavTitle>,
      'Year - I Sem - II',
      null,
      null,
      'group',
    ),

    ...year1sem2,
    
    getItem(
      !topMenu && <NavTitle className="ninjadash-sidebar-nav-title">2nd year 1st Semester</NavTitle>,
      'Year - II Sem - I',
      null,
      null,
      'group',
    ),

    ...year2sem1,

    getItem(
      !topMenu && <NavTitle className="ninjadash-sidebar-nav-title">2nd year 2nd Semester</NavTitle>,
      'Year - II Sem - II',
      null,
      null,
      'group',
    ),

    ...year2sem2,

    getItem(
      !topMenu && <NavTitle className="ninjadash-sidebar-nav-title">3rd year 1st Semester</NavTitle>,
      'Year - III Sem - I',
      null,
      null,
      'group',
    ),

    ...year3sem1,

    getItem(
      !topMenu && <NavTitle className="ninjadash-sidebar-nav-title">3rd year 2nd Semester</NavTitle>,
      'Year - III Sem - II',
      null,
      null,
      'group',
    ),

    ...year3sem2,

    getItem(
      !topMenu && <NavTitle className="ninjadash-sidebar-nav-title">4th year 1st Semester</NavTitle>,
      'Year - IV Sem - I',
      null,
      null,
      'group',
    ),

    ...year4sem1,

    getItem(
      !topMenu && <NavTitle className="ninjadash-sidebar-nav-title">4th year 2nd Semester</NavTitle>,
      'Year - IV Sem - II',
      null,
      null,
      'group',
    ),

    ...year4sem2,

    // getItem(t('layouts'), 'layout', !topMenu && <UilWindowSection />, [
    //   getItem(
    //     <NavLink
    //       onClick={() => {
    //         toggleCollapsed();
    //         darkmodeDiactivated();
    //         changeLayout('lightMode');
    //       }}
    //       to="#"
    //     >
    //       {t('light')} {t('mode')}
    //     </NavLink>,
    //     'light',
    //     null,
    //   ),
    //   getItem(
    //     <NavLink
    //       onClick={() => {
    //         toggleCollapsed();
    //         darkmodeActivated();
    //         changeLayout('darkMode');
    //       }}
    //       to="#"
    //     >
    //       {t('dark')} {t('mode')}
    //     </NavLink>,
    //     'dark',
    //     null,
    //   ),
    //   getItem(
    //     <NavLink
    //       onClick={() => {
    //         toggleCollapsed();
    //         changeNavbar(true);
    //       }}
    //       to="#"
    //     >
    //       {t('top')} {t('menu')}
    //     </NavLink>,
    //     'topMenu',
    //     null,
    //   ),
    //   getItem(
    //     <NavLink
    //       onClick={() => {
    //         toggleCollapsed();
    //         changeNavbar(false);
    //       }}
    //       to="#"
    //     >
    //       {t('side')} {t('menu')}
    //     </NavLink>,
    //     'sideMenu',
    //     null,
    //   ),
    //   getItem(
    //     <NavLink
    //       onClick={() => {
    //         toggleCollapsed();
    //         changeLayoutDirection(true);
    //       }}
    //       to="#"
    //     >
    //       RTL
    //     </NavLink>,
    //     'rtl',
    //     null,
    //   ),
    //   getItem(
    //     <NavLink
    //       onClick={() => {
    //         toggleCollapsed();
    //         changeLayoutDirection(false);
    //       }}
    //       to="#"
    //     >
    //       LTR
    //     </NavLink>,
    //     'ltr',
    //     null,
    //   ),
    // ]),                    
  ];

  return (
    <Menu
      onOpenChange={onOpenChange}
      onClick={onClick}
      mode={!topMenu || window.innerWidth <= 991 ? 'inline' : 'horizontal'}
      // // eslint-disable-next-line no-nested-ternary
      defaultSelectedKeys={
        !topMenu
          ? [
            `${mainPathSplit.length === 1 ? 'dashboard' : mainPathSplit.length === 2 ? mainPathSplit[1] : mainPathSplit[2]
            }`,
          ]
          : []
      }
      defaultOpenKeys={!topMenu ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : 'dashboard'}`] : []}
      overflowedIndicator={<UilEllipsisV />}
      openKeys={openKeys}
      items={items}
    />
  );
}

MenuItems.propTypes = {
  toggleCollapsed: propTypes.func,
};

export default MenuItems;
