const actions = {

  SUBJECT_UNITS_READ_BEGIN: 'SUBJECT_UNITS_READ_BEGIN',
  SUBJECT_UNITS_READ_SUCCESS: 'SUBJECT_UNITS_READ_SUCCESS',
  SUBJECT_UNITS_READ_ERR: 'SUBJECT_UNITS_READ_ERR',

  SUBJECT_READ_BEGIN : 'SUBJECT_READ_BEGIN',
  SUBJECT_READ_SUCCESS : 'SUBJECT_READ_SUCCESS',
  SUBJECT_READ_ERR : 'SUBJECT_READ_ERR',

  UNIT_READ_BEGIN : 'UNIT_READ_BEGIN',
  UNIT_READ_SUCCESS : 'UNIT_READ_SUCCESS',
  UNIT_READ_ERR : 'UNIT_READ_ERR',
  
  TEACHER_SUBJECTS_BEGIN : 'TEACHER_SUBJECTS_BEGIN',
  TEACHER_SUBJECTS_SUCCESS : 'TEACHER_SUBJECTS_SUCCESS',
  TEACHER_SUBJECTS_ERR : 'TEACHER_SUBJECTS_ERR',

  GET_QA_BEGIN: 'GET_QA_BEGIN',
  GET_QA_SUCCESS: 'GET_QA_SUCCESS',
  GET_QA_ERR: 'GET_QA_ERR',


  subjectsUnitsReadBegin: () => {
    return {
      type: actions.SUBJECT_UNITS_READ_BEGIN,
    };
  },

  subjectsUnitsReadSuccess: (data) => {    
    return {
      type: actions.SUBJECT_UNITS_READ_SUCCESS,      
      data,
    };
  },

  subjectsUnitsReadErr: (err) => {
    return {
      type: actions.SUBJECT_UNITS_READ_ERR,
      err,
    };
  },

  subjectReadBegin: () => {
    return {
      type: actions.SUBJECT_READ_BEGIN,
    };
  },

  subjectReadSuccess: (data) => {    
    return {
      type: actions.SUBJECT_READ_SUCCESS,      
      data,
    };
  },

  subjectReadErr: (err) => {
    return {
      type: actions.SUBJECT_READ_ERR,
      err,
    };
  },

  unitReadBegin: () => {
    return {
      type: actions.UNIT_READ_BEGIN,
    };
  },

  unitReadSuccess: (data) => {    
    return {
      type: actions.UNIT_READ_SUCCESS,      
      data,
    };
  },

  unitReadErr: (err) => {
    return {
      type: actions.UNIT_READ_ERR,
      err,
    };
  },

  TeacherSubjectsBegin: () => {
    return {
      type: actions.TEACHER_SUBJECTS_BEGIN,
    };
  },

  TeacherSubjectsSuccess: (data) => {    
    return {
      type: actions.TEACHER_SUBJECTS_SUCCESS,      
      data,
    };
  },

  TeacherSubjectsErr: (err) => {
    return {
      type: actions.TEACHER_SUBJECTS_ERR,
      err,
    };
  },

  getQABegin: () => {
    return {
      type: actions.GET_QA_BEGIN,
    };
  },

  getQASuccess: (data) => {
    return {
      type: actions.GET_QA_SUCCESS,
      data,
    };
  },

  getQAErr: (err) => {
    return {
      type: actions.GET_QA_ERR,
      err,
    };
  },

};

export default actions;