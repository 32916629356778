import react, { useState } from 'react'
import CustomSpinner from '../ui-elements/spinner/CustomSpinner'
import { Button } from '../../components/buttons/buttons'
import { Badge, Form, Modal, Result, Select, message } from 'antd'
import { NoteCardWrap } from '../note/style'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { MapTessellatorUnitToNull, MapTessellatorUnit, TessellatorTopicsDataRead, TessellatorUnitProgramsDataRead } from '../../redux/tessellatorTopics/actionCreator'
import actions from '../../redux/tessellatorTopics/actions'
import { FormValidationWrap } from '../forms/overview/Style'
import { getUnitById } from '../../redux/teacherSubjectsUnits/actionCreator'
import { updateUnitStatusById } from '../../redux/unit/actionCreator'
import unitactions from '../../redux/unit/actions'
import UilArrowLeft from '@iconscout/react-unicons/icons/uil-arrow-left';
import UilArrowRight from '@iconscout/react-unicons/icons/uil-arrow-right';

const { unitStatusUpdateSuccess } = unitactions

const { mapTessellatorUnitUpdateSuccess, tessellatorUnitProgramsReadSuccess, mapTessellatorUnitToNullUpdateSuccess } = actions

const { Option } = Select

function UnitPrograms({ subjectId, unitId, getStatusIndex, UnitInfo, unitStatus }) {

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [form] = Form.useForm();

    const { TessellatorTopics, TessellatorTopicsLoading, TessellatorUnitPrograms, TessellatorUnitProgramsLoading, MapTessellatorUnitRes, MapTessellatorUnitResLoading, MapTsUnitToNullRes, isSettingToNull, unitStatusRes, isUnitStatusUpdating } = useSelector((state) => {
        return {
            TessellatorTopics: state.TessellatorTopics.data,
            TessellatorTopicsLoading: state.TessellatorTopics.loading,
            TessellatorUnitPrograms: state.TessellatorUnitPrograms.data,
            TessellatorUnitProgramsLoading: state.TessellatorUnitPrograms.loading,
            MapTessellatorUnitRes: state.MapTessellatorUnitRes.data,
            MapTessellatorUnitResLoading: state.MapTessellatorUnitRes.loading,
            MapTsUnitToNullRes: state.MapTsUnitToNullRes.data,
            isSettingToNull: state.MapTsUnitToNullRes.loading,
            unitStatusRes: state.unitStatus.data,
            isUnitStatusUpdating: state.unitStatus.loading,
        };
    });

    const [tessunitId, setTessUnitId] = useState(0)
    const [isProgramsExists, setProgramsExists] = useState(false)
    const [verifyCompletedModal, setVerifyCompletedModal] = useState(false)

    useEffect(() => {
        if (tessunitId) {
            dispatch(TessellatorUnitProgramsDataRead(parseInt(tessunitId)))
        }
    }, [tessunitId])

    useEffect(() => {
        if (UnitInfo !== null && UnitInfo.tessunitId) {
            dispatch(TessellatorUnitProgramsDataRead(parseInt(UnitInfo.tessunitId)))
        }
    }, [UnitInfo])

    useEffect(() => {
        if (MapTsUnitToNullRes !== null && MapTsUnitToNullRes.Error === false) {
            dispatch(mapTessellatorUnitToNullUpdateSuccess(null));
            dispatch(getUnitById(parseInt(unitId)));
        }
    }, [MapTsUnitToNullRes])

    useEffect(() => {
        if (isProgramsExists && subjectId) {
            dispatch(TessellatorTopicsDataRead(subjectId))
        }
    }, [isProgramsExists])

    useEffect(() => {
        if (MapTessellatorUnitRes !== null && MapTessellatorUnitRes.message === "updated") {
            dispatch(getUnitById(parseInt(unitId)));
            setProgramsExists(false)
            setTessUnitId(0)
            message.success("Data Submitted successfully")
            dispatch(mapTessellatorUnitUpdateSuccess(null))
        }
        else if (MapTessellatorUnitRes !== null && (MapTessellatorUnitRes.Error || MapTessellatorUnitRes.message !== "updated")) {
            message.error("Failed to submit")
        }
    }, [MapTessellatorUnitRes])

    const fetchPrograms = (tessunitId) => {
        setTessUnitId(tessunitId)
        if (parseInt(tessunitId) !== 0) {
            dispatch(TessellatorUnitProgramsDataRead(parseInt(tessunitId)))
        }
    }

    const saveTopicMapping = (formData) => {
        if (formData) {
            dispatch(MapTessellatorUnit(parseInt(unitId), parseInt(formData.tessellatorUnit)))
        }
    }

    const onCancel = () => {
        if (tessunitId) {
            dispatch(TessellatorUnitProgramsDataRead(parseInt(tessunitId)))
        }
        setProgramsExists(false);
        setTessUnitId(0)
    }


    const ChangeStatusProgramsVerify = () => {
        dispatch(tessellatorUnitProgramsReadSuccess([]))
        setProgramsExists(false)
        const datatoPost = {
            id: parseInt(unitId),
            qaStatus: "PROGRAMS VERIFY"
        }
        dispatch(updateUnitStatusById(datatoPost))
    }

    useEffect(() => {
        if (unitStatusRes && unitStatusRes.Error === false) {
            dispatch(getUnitById(unitId))
            // setVerifyCompletedModal(true)
        }
        dispatch(unitStatusUpdateSuccess(null));
    }, [unitStatusRes])

    const resetToNull = () => {
        setProgramsExists(false)
        dispatch(MapTessellatorUnitToNull(parseInt(unitId)))
    }

    const FreezeUnit = () => {
        setVerifyCompletedModal(false)
        navigate(`/teacher/unit-wise/unit-freeze`)
    }

    useEffect(() => {
        if (unitStatus !== null && unitStatus === "PROGRAMS VERIFY") {
            setVerifyCompletedModal(true)
        }
    }, [unitStatus])

    return (
        <div>
            <NoteCardWrap>
                {
                    getStatusIndex(UnitInfo !== null && UnitInfo.qaStatus) === 5 ?
                        <Result
                            status="success"
                            title="Content Freezed"
                            subTitle={`on ${UnitInfo !== null && new Date(UnitInfo.updatedon).toLocaleString()}`}
                        />
                        :
                        getStatusIndex(unitStatus !== null && unitStatus) === 3 && !isProgramsExists && (UnitInfo !== null && UnitInfo.tessunitId === null) ?
                            <div className='text-center mt-30'>
                                <p className="fontsize-16 m-0 mr-10">Does this unit contain programs?</p>
                                <div className="panel-actions mt-10">
                                    <Button id="programs_yes-button" className="mr-10" outlined type="success" onClick={() => { setProgramsExists(true); form.resetFields() }}>
                                        Yes
                                    </Button>
                                    <Button id="programs_no-button" outlined type="warning" onClick={() => ChangeStatusProgramsVerify()} disabled={isUnitStatusUpdating}>
                                        No
                                    </Button>
                                </div>
                                <div>
                                    <Button id="unitprograms_reviewqa-button2" type="dark" outlined style={{ color: 'black' }} className="mt-20" key="reviewQuiz" onClick={() => navigate(`/teacher/unit-wise/qa-review`)}>
                                     <UilArrowLeft size={18} />
                                        Review Q & A
                                    </Button>
                                </div>
                            </div>
                            :
                            getStatusIndex(unitStatus !== null && unitStatus) === 4 && !isProgramsExists && (UnitInfo !== null && UnitInfo.tessunitId === null) ?
                                <div>
                                    <Result
                                        status="success"
                                        title="Programs verified"
                                        subTitle={
                                            (tessunitId !== false && tessunitId !== null) ?
                                                <div className='d-flex'>
                                                    {
                                                        TessellatorUnitProgramsLoading ?
                                                            <div className=''>
                                                                <CustomSpinner Message={'Fetching programs. Please wait...'} />
                                                            </div>
                                                            :
                                                            <div className='m-auto'>
                                                                <div className='d-flex align-center-v mb-10'>
                                                                    <p className='m-0'>Tessellator Unit: &nbsp;</p>
                                                                    <h3 className='m-0'>{TessellatorUnitPrograms.length !== 0 && TessellatorUnitPrograms[0].topicname}</h3>
                                                                </div>
                                                                <p>No. of programs: {TessellatorUnitPrograms.length}</p>
                                                                {
                                                                    TessellatorUnitPrograms.map((program) =>
                                                                        <div key={program.id}>
                                                                            <Badge color='green' text={program.name} />
                                                                        </div>
                                                                    )
                                                                }
                                                                {/* <div className='text-center mt-20'>
                                                                <Button id="programs_edit-button" type="primary" onClick={() => { dispatch(tessellatorUnitProgramsReadSuccess([])); setProgramsExists(true); setTessUnitId(tessunitId); }}>
                                                                    Edit
                                                                </Button>
                                                            </div> */}
                                                            </div>
                                                    }
                                                </div>
                                                :
                                                <p>No. of programs: {TessellatorUnitPrograms.length}</p>
                                        }
                                        extra={[
                                            <Button  id="programs_reset-button" className="mt-20" outlined type='dark' key="reset" onClick={() => resetToNull()} disabled={isSettingToNull ? true : false}>
                                                {isSettingToNull ? 'Please Wait...' :  <><UilArrowLeft size={18} style={{marginTop:'5px',color:'black'}}/> Reset</>}
                                            </Button>,
                                             <Button id="programs_edit-button1" type="primary" onClick={() => { setProgramsExists(true); form.resetFields() }}>
                                             Edit
                                         </Button>
                                        ]}
                                    />
                                </div>
                                :
                                isProgramsExists ?
                                    <div className='d-flex'>
                                        <div className='m-auto'>
                                            <p className="fontsize-16 m-0 mb-10">Select corresponding unit in tessellator</p>
                                            <FormValidationWrap>
                                                <Form id="programs_topics-form" name="sDash_validation-form" form={form} layout="vertical" onFinish={saveTopicMapping}>
                                                    <Form.Item
                                                        name="tessellatorUnit"
                                                        rules={[{ required: true, message: 'Unit is required!' }]}
                                                        label="Select Unit"
                                                    >
                                                        <Select
                                                            id="programs_select-topic"
                                                            loading={TessellatorTopicsLoading}
                                                            showSearch
                                                            style={{ width: 300 }}
                                                            placeholder="Select a unit"
                                                            optionFilterProp="children"
                                                            // filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}                                                                                                                                            
                                                            onChange={(e) => fetchPrograms(e)}
                                                        >
                                                            {
                                                                TessellatorTopics.length !== 0 && TessellatorTopics.map((topic) =>
                                                                    <Option key={topic.id} value={topic.id}>{topic.name}</Option>

                                                                )
                                                            }
                                                        </Select>
                                                    </Form.Item>

                                                    <div className='text-right mb-20'>
                                                        <Button id="programs_cancel-button" className="mr-10" outlined type="light" style={{ color: '#000' }} onClick={() => onCancel()}>
                                                            Cancel
                                                        </Button>
                                                        <Button id="programs_topic-submit-button" htmlType="submit" type="primary" onClick={() => saveTopicMapping()} >
                                                            {MapTessellatorUnitResLoading ? 'Please wait...' : 'Submit'}
                                                        </Button>
                                                    </div>
                                                </Form>
                                            </FormValidationWrap>
                                            {
                                                TessellatorUnitProgramsLoading &&
                                                <div className=''>
                                                    <CustomSpinner Message={'Fetching programs. Please wait...'} />
                                                </div>
                                            }
                                            {
                                                !TessellatorUnitProgramsLoading && TessellatorUnitPrograms.length !== 0 && isProgramsExists && tessunitId !== 0 &&
                                                <div className='mt-25'>
                                                    <p>No. of programs: {TessellatorUnitPrograms.length}</p>
                                                    {
                                                        TessellatorUnitPrograms.map((program) =>
                                                            <div key={program.id}>
                                                                <Badge color='green' text={program.name} />
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    :
                                    tessunitId !== false && tessunitId !== null &&
                                    <div className=''>
                                        {
                                            TessellatorUnitProgramsLoading ?
                                                <div className=''>
                                                    <CustomSpinner Message={'Fetching programs. Please wait...'} />
                                                </div>
                                                :
                                                <Result
                                                    status="success"
                                                    title="Programs verified"
                                                    subTitle={
                                                        (tessunitId !== false && tessunitId !== null) ?
                                                            <div className='d-flex'>
                                                                {
                                                                    TessellatorUnitProgramsLoading ?
                                                                        <div className=''>
                                                                            <CustomSpinner Message={'Fetching programs. Please wait...'} />
                                                                        </div>
                                                                        :
                                                                        <div className='m-auto'>
                                                                            <div className='d-flex align-center-v mb-10'>
                                                                                <p className='m-0'>Tessellator Unit: &nbsp;</p>
                                                                                <h3 className='m-0'>{TessellatorUnitPrograms.length !== 0 && TessellatorUnitPrograms[0].topicname}</h3>
                                                                            </div>
                                                                            <p className=''>No. of programs: {TessellatorUnitPrograms.length}</p>
                                                                            {
                                                                                TessellatorUnitPrograms.map((program) =>
                                                                                    <div key={program.id}>
                                                                                        <Badge color='green' text={program.name} />
                                                                                    </div>
                                                                                )
                                                                            }
                                                                        </div>
                                                                }
                                                            </div>
                                                            :
                                                            <p className=''>No. of programs: {TessellatorUnitPrograms.length}</p>
                                                    }
                                                    extra={[
                                                        <Button id="programs_reset-button" className="" outlined key="reset" onClick={() => resetToNull()} disabled={isSettingToNull ? true : false}>
                                                            {isSettingToNull ? 'Please Wait...' :<><UilArrowLeft size={18} style={{marginTop:'5px',color:'black'}}/> Reset</>}
                                                        </Button>,
                                                        <Button id="programs_edit-button2" type="primary" key="edit" onClick={() => { dispatch(tessellatorUnitProgramsReadSuccess([])); setProgramsExists(true); setTessUnitId(tessunitId); }} disabled={isSettingToNull ? true : false} >
                                                        Edit
                                                    </Button>,
                                                    ]}
                                                />
                                        }
                                    </div>

                }
                <Modal open={verifyCompletedModal} title="Freeze Unit" onCancel={() => setVerifyCompletedModal(false)} onOk={() => FreezeUnit()} okText="Yes" cancelText="No">
                    <h4>Programs verified successfully</h4>
                    <h4>Do you want to freeze this unit now?</h4>
                </Modal>
            </NoteCardWrap >
        </div >
    )
}

export default UnitPrograms
