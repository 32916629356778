import React, { useState, useEffect } from 'react';
import { Row, Col, Table, Button, Input, Modal, Form, Space, Select, Tag, message } from 'antd';
import UilEdit from '@iconscout/react-unicons/icons/uil-edit';
import UilTrash from '@iconscout/react-unicons/icons/uil-trash-alt';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import {UserTableStyleWrapper} from './styles/tablestyle'
import { Cards } from '../../components/cards/frame/cards-frame';
import { TableWrapper, Main } from '../styled';
import { PageHeader } from '../../components/page-headers/page-headers';
import { ProjectList } from '../project/style';

const { Option } = Select;

function Topics() {

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [form] = Form.useForm();
  const [editForm] = Form.useForm();

  const [dataSource, setDataSource] = useState([]);

  const [editRecord, setEditRecord] = useState(null);

  useEffect(() => {
    fetch('https://jsonplaceholder.typicode.com/users')
      .then(response => response.json())
      .then(data => setDataSource(data))
      .catch(error => console.error(error));
  }, []);

  const columns = [
    {
      title: 'Code',
      dataIndex: 'code',
      key: 'code',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Unit ID',
      dataIndex: 'unitid',
      key: 'unitid',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (text, record) => (
        <Space>
          <Button icon={<UilEdit />} onClick={() => showEditModal(record)}>
          </Button>
          <Button icon={<UilTrash />} onClick={() => handleDelete(record)}>
          </Button>
        </Space>
      ),
    },
  ];

  const handleButtonClick = () => {
    setIsModalVisible(true);
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
  };

  const handleModalAdd = () => {
    form.validateFields().then((values) => {
        const newRow = {
          key: String(dataSource.length + 1),
          code: values.code,
          name: values.name,
          unitid: values.unitid,
          status: (
            <span className={`status-text ${values.status || 'active'}`}>{values.status || 'Active'}</span>
          ),
        };
        setDataSource([...dataSource, newRow]);
        form.resetFields();
        setIsModalVisible(false);
    });
  };

  const handleModalUpdate = () => {
    editForm.validateFields().then((values) => {
      const updatedRecord = {
        ...editRecord,
        code: values.code,
        name: values.name,
        unitid: values.unitid,
        status: <span className={`status-text ${values.status}`}>{values.status}</span>,
      };

      const updatedDataSource = dataSource.map((item) =>
        item.key === updatedRecord.key ? updatedRecord : item
      );

      setDataSource(updatedDataSource);
      setIsEditModalVisible(false);
    });
  };

  const handleEdit = (record) => {
    setEditRecord(record);
    setIsEditModalVisible(true);
    editForm.setFieldsValue({
      code: record.code,
      name: record.name,
      unitid: record.unitid,
      status: record.status.props.children,
    });
  };

  const handleDelete = (record) => {
    Modal.confirm({
      title: 'Are you sure you want to delete this record?',
      icon: <ExclamationCircleOutlined />,
      content: 'Once deleted the action can be undone',
      okText: 'Delete',
      okType: 'danger',
      cancelText: 'Cancel',
      onOk() {
        const updatedDataSource = dataSource.filter((item) => item.key !== record.key);
        setDataSource(updatedDataSource);
      },
    });
  };

  const showEditModal = (record) => {
    handleEdit(record);
  };

  return (
    <>
      <PageHeader className="ninjadash-page-header-main" title="Topics"/>
      <Main>
        <Row gutter={25}>
          <Col sm={24} xs={24}>
            <Cards headless>
            <ProjectList>
            <div className="table-responsive">
                  <Table dataSource={dataSource} columns={columns} />
                </div>
              </ProjectList>
            </Cards>
          </Col>
        </Row>
        <Row justify="center" style={{ marginTop: 16 }}>
          <Col>
            <Button type="primary" onClick={handleButtonClick}>
              Add Topic
            </Button>
          </Col>
        </Row>
      </Main>
      <Modal
        title="Add Topic"
        open={isModalVisible}
        onCancel={handleModalCancel}
        footer={[
          <Button key="add" type="primary" onClick={handleModalAdd}>
            Add
          </Button>,
        ]}
      >
        <Form form={form} layout="vertical">
          <Form.Item name="code" label="Code" rules={[{ required: true, message: 'Please enter Subject Code' }]}>
            <Input/>
          </Form.Item>
          <Form.Item name="name" label="Name" rules={[{ required: true }]}>
            <Input/>
          </Form.Item>
          <Form.Item name="unitid" label="Unit ID" rules={[{ required: true  }]}>
            <Select placeholder="Select Unit ID">
                <Option value="UNIT-I">1</Option>
                <Option value="UNIT-II">2</Option>
                <Option value="UNIT-III">3</Option>
                <Option value="UNIT-IV">4</Option>
                <Option value="UNIT-V">5</Option>
            </Select>
          </Form.Item>
          <Form.Item name="status" label="Status" >
            <Select defaultValue="Active">
              <Option value="active">Active</Option>
              <Option value="inactive">Inactive</Option>
            </Select>
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title="Edit Topic"
        visible={isEditModalVisible}
        onCancel={() => setIsEditModalVisible(false)}
        footer={[
          <Button key="update" type="primary" onClick={handleModalUpdate}>
            Update
          </Button>
        ]}
      >
        <Form form={editForm} layout="vertical">
          <Form.Item name="code" label="Code" rules={[{ required: true }]}>
            <Input/>
          </Form.Item>
          <Form.Item name="name" label="Name" rules={[{ required: true }]}>
            <Input/>
          </Form.Item>
          <Form.Item name="unitid" label="Unit ID" rules={[{ required: true  }]}>
            <Select >
                <Option value="UNIT-I">1</Option>
                <Option value="UNIT-II">2</Option>
                <Option value="UNIT-III">3</Option>
                <Option value="UNIT-IV">4</Option>
                <Option value="UNIT-V">5</Option>
            </Select>
          </Form.Item>
          <Form.Item name="status" label="Status" rules={[{ required: true }]}>
            <Select>
              <Option value="active">Active</Option>
              <Option value="inactive">Inactive</Option>
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

export default Topics;