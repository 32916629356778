import react, { useEffect } from 'react'
import { Button } from '../../components/buttons/buttons';
import { sendUnitToHod } from '../../redux/unit/actionCreator';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Result } from 'antd';
import { getUnitById } from '../../redux/teacherSubjectsUnits/actionCreator'
import actions from '../../redux/unit/actions';
import UilArrowLeft from '@iconscout/react-unicons/icons/uil-arrow-left';

const { sendUnitToHodSuccess } = actions

function FreezeUnit({ subjectId, unitId, getStatusIndex, UnitInfo, unitStatus }) {

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const { sendToHodRes, isSendingToHod } = useSelector((state) => {
        return {
            sendToHodRes: state.sendUnitToHodRes.data,
            isSendingToHod: state.sendUnitToHodRes.data,

        };
    });

    useEffect(() => {
        if (sendToHodRes !== null && sendToHodRes.Error === false) {
            dispatch(getUnitById(unitId))
            dispatch(sendUnitToHodSuccess(null))
        }
    }, [sendToHodRes])

    const HodStatusSetToNothing = () => {
        const datatoPost = {
            unitId: parseInt(unitId),
            hodStatus: "NOTHING",
            hodComment: UnitInfo?.hodComment
        }
        dispatch(sendUnitToHod(datatoPost, false))
    }

    const SendtoHOD = () => {
        const datatoPost = {
            unitId: parseInt(unitId),
            hodStatus: "PENDING",
            hodComment: "--"
        }
        dispatch(sendUnitToHod(datatoPost, true))
    }

    return (
        <div>
            <div>
                {
                    getStatusIndex(unitStatus) === 5 ?
                        <Result
                            status="success"
                            title="Content Freezed"
                            subTitle={`on ${UnitInfo !== null && new Date(UnitInfo.updatedon).toLocaleString()}`}
                        />
                        :
                        getStatusIndex(unitStatus) == 4 &&
                        <div className='spin mt-25'>
                            {
                                UnitInfo !== null && UnitInfo.hodStatus === "NOTHING" ?
                                    <div>
                                        <Result
                                            status="warning"
                                            title="Once the unit is sent to HOD, you cannot change the unit data anymore"
                                            extra={
                                                <div>
                                                    <Button id="freeze_verifyprograms-button1" type="dark" outlined style={{ color: 'black' }} className="mt-20 ml-10 mr-10" key="reviewQuiz" onClick={() => navigate(`/teacher/unit-wise/programs`)} disabled={isSendingToHod}>
                                                    <UilArrowLeft size={30}/>
                                                    
                                                        Verify Programs
                                                    </Button>
                                                    <Button id="freezeschedule_freezeunit-button" type="primary" onClick={() => SendtoHOD()} disabled={isSendingToHod}>
                                                        Send for Review
                                                    </Button>
                                                </div>

                                            }
                                        />
                                    </div>
                                    :
                                    UnitInfo !== null && UnitInfo.hodStatus === "PENDING" ?
                                        <div>
                                            <Result
                                                status="info"
                                                title="This unit is under review by HOD"
                                                subTitle="Please wait untill review completed"
                                            />
                                        </div>
                                        :
                                        UnitInfo !== null && UnitInfo.hodStatus === "REJECTED" &&
                                        <div>
                                            <Result
                                                status="warning"
                                                title="This unit is rejected by HOD"
                                                subTitle={<div>
                                                    Reason : {UnitInfo.hodComment}
                                                </div>}
                                                extra={
                                                    <div>
                                                        <Button id="freeze_verifyprograms-button1" type="warning" outlined style={{ color: 'black' }} className="mt-20 ml-10" key="reviewQuiz" onClick={() => { HodStatusSetToNothing(); navigate(`/teacher/unit-wise/programs`) }} disabled={isSendingToHod}>
                                                            <UilArrowLeft />
                                                            Verify Programs
                                                        </Button>
                                                    </div>

                                                }
                                            />
                                        </div>
                            }
                        </div>
                }
            </div>
        </div>
    );
}

export default FreezeUnit