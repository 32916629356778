const actions = {

  READ_TOPICS_BEGIN: 'READ_TOPICS_BEGIN',
  READ_TOPICS_SUCCESS: 'READ_TOPICS_SUCCESS',
  READ_TOPICS_ERR: 'READ_TOPICS_ERR',
  
  READ_TOPIC_CONTENT_BEGIN: 'READ_TOPIC_CONTENT_BEGIN',
  READ_TOPIC_CONTENT_SUCCESS: 'READ_TOPIC_CONTENT_SUCCESS',
  READ_TOPIC_CONTENT_ERR: 'READ_TOPIC_CONTENT_ERR',
  
  FLAG_UPDATE_BEGIN: 'FLAG_UPDATE_BEGIN',
  FLAG_UPDATE_SUCCESS: 'FLAG_UPDATE_SUCCESS',
  FLAG_UPDATE_ERR: 'FLAG_UPDATE_ERR',
  
  CONTENT_FLAG_UPDATE_BEGIN: 'CONTENT_FLAG_UPDATE_BEGIN',
  CONTENT_FLAG_UPDATE_SUCCESS: 'CONTENT_FLAG_UPDATE_SUCCESS',
  CONTENT_FLAG_UPDATE_ERR: 'CONTENT_FLAG_UPDATE_ERR',
  
  LEARNING_FLAG_UPDATE_BEGIN: 'LEARNING_FLAG_UPDATE_BEGIN',
  LEARNING_FLAG_UPDATE_SUCCESS: 'LEARNING_FLAG_UPDATE_SUCCESS',
  LEARNING_FLAG_UPDATE_ERR: 'LEARNING_FLAG_UPDATE_ERR',



  ReadTopicsBegin: () => {
    return {
      type: actions.READ_TOPICS_BEGIN,
    };
  },

  ReadTopicsSuccess: (data) => {    
    return {
      type: actions.READ_TOPICS_SUCCESS,      
      data,
    };
  },

  ReadTopicsErr: (err) => {
    return {
      type: actions.READ_TOPICS_ERR,
      err,
    };
  },
  
  ReadTopicContentBegin: () => {
    return {
      type: actions.READ_TOPIC_CONTENT_BEGIN,
    };
  },

  ReadTopicContentSuccess: (data) => {    
    return {
      type: actions.READ_TOPIC_CONTENT_SUCCESS,      
      data,
    };
  },

  ReadTopicContentErr: (err) => {
    return {
      type: actions.READ_TOPIC_CONTENT_ERR,
      err,
    };
  },
  
  flagUpdateBegin: () => {
    return {
      type: actions.FLAG_UPDATE_BEGIN,
    };
  },

  flagUpdateSuccess: (data) => {    
    return {
      type: actions.FLAG_UPDATE_SUCCESS,      
      data,
    };
  },

  flagUpdateErr: (err) => {
    return {
      type: actions.FLAG_UPDATE_ERR,
      err,
    };
  },
  
  
  contentflagUpdateBegin: () => {
    return {
      type: actions.CONTENT_FLAG_UPDATE_BEGIN,
    };
  },

  contentflagUpdateSuccess: (data) => {    
    return {
      type: actions.CONTENT_FLAG_UPDATE_SUCCESS,      
      data,
    };
  },

  contentflagUpdateErr: (err) => {
    return {
      type: actions.CONTENT_FLAG_UPDATE_ERR,
      err,
    };
  },

  learningflagUpdateBegin: () => {
    return {
      type: actions.LEARNING_FLAG_UPDATE_BEGIN,
    };
  },

  learningflagUpdateSuccess: (data) => {    
    return {
      type: actions.LEARNING_FLAG_UPDATE_SUCCESS,      
      data,
    };
  },

  learningflagUpdateErr: (err) => {
    return {
      type: actions.LEARNING_FLAG_UPDATE_ERR,
      err,
    };
  },
  
};

export default actions;
