const actions = {
  BRANCH_ADD_BEGIN: 'BRANCH_ADD_BEGIN',
  BRANCH_ADD_SUCCESS: 'BRANCH_ADD_SUCCESS',
  BRANCH_ADD_ERR: 'BRANCH_ADD_ERR',

  BRANCH_READ_BEGIN: 'BRANCH_READ_BEGIN',
  BRANCH_READ_SUCCESS: 'BRANCH_READ_SUCCESS',
  BRANCH_READ_ERR: 'BRANCH_READ_ERR',

  BRANCH_UPDATE_BEGIN: 'BRANCH_UPDATE_BEGIN',
  BRANCH_UPDATE_SUCCESS: 'BRANCH_UPDATE_SUCCESS',
  BRANCH_UPDATE_ERR: 'BRANCH_UPDATE_ERR',

  BRANCH_DELETE_BEGIN: 'BRANCH_DELETE_BEGIN',
  BRANCH_DELETE_SUCCESS: 'BRANCH_DELETE_SUCCESS',
  BRANCH_DELETE_ERR: 'BRANCH_DELETE_ERR',

  BRANCH_SINGLE_DATA_BEGIN: 'BRANCH_SINGLE_DATA_BEGIN',
  BRANCH_SINGLE_DATA_SUCCESS: 'BRANCH_SINGLE_DATA_SUCCESS',
  BRANCH_SINGLE_DATA_ERR: 'BRANCH_SINGLE_DATA_ERR',

  BRANCH_UPLOAD_BEGIN: 'BRANCH_UPLOAD_BEGIN',
  BRANCH_UPLOAD_SUCCESS: 'BRANCH_UPLOAD_SUCCESS',
  BRANCH_UPLOAD_ERR: 'BRANCH_UPLOAD_ERR',

  branchUploadBegin: () => {
    return {
      type: actions.BRANCH_UPLOAD_BEGIN,
    };
  },

  branchUploadSuccess: (data) => {
    return {
      type: actions.BRANCH_UPLOAD_SUCCESS,
      data,
    };
  },

  branchUploadErr: (err) => {
    return {
      type: actions.BRANCH_UPLOAD_ERR,
      err,
    };
  },

  branchAddBegin: () => {
    return {
      type: actions.BRANCH_ADD_BEGIN,
    };
  },

  branchAddSuccess: (data) => {
    return {
      type: actions.BRANCH_ADD_SUCCESS,
      data,
    };
  },

  branchAddErr: (err) => {
    return {
      type: actions.BRANCH_ADD_ERR,
      err,
    };
  },

  branchReadBegin: () => {
    return {
      type: actions.BRANCH_READ_BEGIN,
    };
  },

  branchReadSuccess: (data) => {
    return {
      type: actions.BRANCH_READ_SUCCESS,
      data,
    };
  },

  branchReadErr: (err) => {
    return {
      type: actions.BRANCH_READ_ERR,
      err,
    };
  },

  branchUpdateBegin: () => {
    return {
      type: actions.BRANCH_UPDATE_BEGIN,
    };
  },

  branchUpdateSuccess: (data) => {
    return {
      type: actions.BRANCH_UPDATE_SUCCESS,
      data,
    };
  },

  branchUpdateErr: (err) => {
    return {
      type: actions.BRANCH_UPDATE_ERR,
      err,
    };
  },

  branchDeleteBegin: () => {
    return {
      type: actions.BRANCH_DELETE_BEGIN,
    };
  },

  branchDeleteSuccess: (data) => {
    return {
      type: actions.BRANCH_DELETE_SUCCESS,
      data,
    };
  },

  branchDeleteErr: (err) => {
    return {
      type: actions.BRANCH_DELETE_ERR,
      err,
    };
  },

  branchSingleDataBegin: () => {
    return {
      type: actions.BRANCH_SINGLE_DATA_BEGIN,
    };
  },

  branchSingleDataSuccess: (data) => {
    return {
      type: actions.BRANCH_SINGLE_DATA_SUCCESS,
      data,
    };
  },

  branchSingleDataErr: (err) => {
    return {
      type: actions.BRANCH_SINGLE_DATA_ERR,
      err,
    };
  },
};

export default actions;
