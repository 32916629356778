import React, { useEffect, useState } from 'react';
import { NoteCardWrap } from '../note/style';
import { Alert, Col, Collapse, Modal, Row, Spin, Table, Tooltip } from 'antd';
import { Link, useParams } from 'react-router-dom';
import { Cards } from '../../components/cards/frame/cards-frame';
import { useDispatch, useSelector } from 'react-redux';
import { getStudentTopicsByUnitId } from '../../redux/topics/actionCreator';
import CustomSpinner from '../ui-elements/spinner/CustomSpinner';
import topicReadBadge from '../../static/img/TOPIC-READ/TOPIC-READ-16X16.png';
import topicCompleteBadge from '../../static/img/TOPIC-COMPLETE/TOPIC-COMPLETE-16X16.png';
import { setItem } from '../../utility/localStorageControl';
import { GetAttemptedQ, GetUnitAttemptedQA, EvaluationFeedback } from '../../redux/generateQuestions/actionCreator';
import UilPlus from '@iconscout/react-unicons/icons/uil-plus';
import UilMinus from '@iconscout/react-unicons/icons/uil-minus';
import { Button } from '../../components/buttons/buttons';
import UilLock from '@iconscout/react-unicons/icons/uil-lock';
import UilUnlock from '@iconscout/react-unicons/icons/uil-unlock';
import { UilSmile, UilSad, UilThumbsUp, UilThumbsDown } from '@iconscout/react-unicons';
const { Panel } = Collapse;

function TopicsList() {
    const { unitId } = useParams()
    const dispatch = useDispatch();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isUnitModalVisible, setIsUnitModalVisible] = useState(false);


    const { UnitTopics, topicsLoading, currentWeek, AttemptedQA, AttemptedQALoading, UnitAttemptedQA, UnitAttemptedQALoading, StudentFeedback, isStudentFeedbackLoading } = useSelector((state) => {
        return {
            UnitTopics: state.studentTopicsByUnit.data,
            topicsLoading: state.studentTopicsByUnit.loading,
            AttemptedQA: state.AttemptedQA.data,
            AttemptedQALoading: state.AttemptedQA.loading,
            UnitAttemptedQA: state.UnitAttemptedQA.data,
            UnitAttemptedQALoading: state.UnitAttemptedQA.loading,
            currentWeek: state.currentWeek.data,
            StudentFeedback: state.StudentFeedback.data,
            isStudentFeedbackLoading: state.StudentFeedback.loading
        };
    });

    const feedbackLastDate = UnitAttemptedQA?.feedbackLastDate;
    const dateObject = new Date(feedbackLastDate);

    const date = dateObject.toDateString();

    const time = dateObject.toLocaleTimeString();

    useEffect(() => {
        if (unitId !== undefined || unitId !== ':unitId') {
            dispatch(getStudentTopicsByUnitId(parseInt(unitId)));
            dispatch(GetUnitAttemptedQA(parseInt(unitId)));
        }
    }, [unitId]);

    // useEffect(() => {
    //     if (StudentFeedback !== null && StudentFeedback.Error === false) {
    //         dispatch(GetUnitAttemptedQA(parseInt(unitId)));
    //     }
    // }, [StudentFeedback])

    const GetQA = (topicId) => {
        dispatch(GetAttemptedQ(topicId))
        setIsModalVisible(true)
    }

    const TopicsColumns = [
        {
            title: 'Topic',
            dataIndex: 'name',
            key: 'name',
            align: 'left'
        },
        {
            title: 'Content',
            dataIndex: 'contentIcon',
            key: 'contentIcon',
            align: 'center'
        },
        {
            title: 'MCQs',
            dataIndex: 'learningIcon',
            key: 'learningIcon',
            align: 'center'
        },
        {
            title: 'Grade',
            dataIndex: 'QuizScore',
            key: 'QuizScore',
            align: 'center',
        },
        {
            title: 'Badge',
            dataIndex: 'badge',
            key: 'badge',
            align: 'center',
        },
        {
            title: 'Earned On',
            dataIndex: 'earnedon',
            key: 'earnedon',
        },
        // {
        //     title: 'Assessment',
        //     dataIndex: 'assessment',
        //     key: 'assessment',
        // },
    ];

    const matchrows = [];
    const nonmatchrows = [];


    UnitTopics !== null && Object.keys(UnitTopics).length !== 0 && UnitTopics.topics.length !== 0 && UnitTopics.topics.sort((a, b) => (a.status < b.status) ? -1 : 1).map((obj, index) => {
        const { id, name, pdf, status, testtopicId, videodescription, videourl, QuizAttemptData, QaAttemptData, contentFlag, learningFlag } = obj;
        const row = {
            key: index,
            id,
            name:
                status !== 'CONTENT FREEZED' ? (
                    <Tooltip title={'Content not found'} placement="right"><span style={{ color: '#bbb' }} className="">{name}</span></Tooltip>
                ) : (
                    <Link to={`topic/${id}`} onClick={() => setItem('activestudenttopic', index)} className="underline-hover">
                        {name}
                    </Link>
                ),
            pdf,
            QuizScore: QaAttemptData !== null && QaAttemptData.payload !== null && QaAttemptData.payload.badge !== 0 ? `${QaAttemptData.payload.percentage / 10} of 10` : QuizAttemptData !== null && QuizAttemptData.payload.score === -1 ? '-' : `${QuizAttemptData.payload.score} of ${QuizAttemptData.payload.totalquestions}`,
            status,
            testtopicId,
            videodescription,
            videourl,
            badge: (QaAttemptData !== null && QaAttemptData.payload !== null && QaAttemptData.payload.badge !== 0 ?
                <img src={topicCompleteBadge} className={`${QuizAttemptData.payload.badge !== 1 ? 'img-grayscale' : ''}`} />
                : QuizAttemptData !== null && <img src={topicReadBadge} className={`${QuizAttemptData.payload.badge !== 1 ? 'img-grayscale' : ''}`} />
            ),
            // earnedon: QuizAttemptData.payload.badge === 1 ? QuizAttemptData != null && QuizAttemptData.payload.lastsubmitted === null ? '-' : new Date(QuizAttemptData.payload.lastsubmitted).toLocaleString() : '-',
            earnedon: <i className='fontsize-13'>{((QaAttemptData !== null && QaAttemptData.payload !== null && QaAttemptData.payload.badge !== 0 && QaAttemptData.payload.lastsubmitted !== null) ?
                new Date(QaAttemptData.payload.lastsubmitted).toDateString()
                :
                (QuizAttemptData != null && QuizAttemptData.payload !== null && QuizAttemptData.payload.badge !== 0 && QuizAttemptData.payload.lastsubmitted !== null) ?
                    new Date(QuizAttemptData.payload.lastsubmitted).toDateString()
                    :
                    '-'
            )}</i>,
            assessment: QaAttemptData !== null && QaAttemptData.payload !== null && QaAttemptData.payload.QAstatus === "EVALUATING" ? "Evaluating" : QaAttemptData.payload.QAstatus === "INACTIVE" ? <Button type={`${QaAttemptData.payload.badge === 2 ? 'success' : 'danger'}`} transparented onClick={() => GetQA(id)}>View QA</Button> : '-',
            contentFlag,
            learningFlag,
            contentIcon: contentFlag === true ?
                <div className='d-flex justify-content-center'>
                    <span className='text-success cursor-pointer'><UilUnlock size={18} /></span>&nbsp;
                </div>
                :
                <div className='d-flex justify-content-center'>
                    <span className='text-danger cursor-pointer' ><UilLock size={18} /></span>&nbsp;
                </div>,
            learningIcon: learningFlag === true ?
                <div className='d-flex justify-content-center'>
                    <span className='text-success cursor-pointer'><UilUnlock size={18} /></span>&nbsp;
                </div>
                :
                <div className='d-flex justify-content-center'>
                    <span className='text-danger cursor-pointer' ><UilLock size={18} /></span>&nbsp;
                </div>
        };
        nonmatchrows.push(row);
    });

    const sortedData = matchrows.concat(nonmatchrows);

    const handleModalCancel = () => {
        setIsModalVisible(false);
    };

    return (
        <div>
            <Cards headless >
                <div className='d-flex mb-20'>
                    <h3> {UnitTopics !== null && UnitTopics.unitName}</h3>
                    &nbsp;&nbsp;
                    <span>| {UnitTopics !== null && UnitTopics.topics.length} Topics </span>
                </div>
                <NoteCardWrap>
                    {
                        topicsLoading ?
                            <div className='custom-spin'>
                                <CustomSpinner Message={'Loading Topics... Please wait.'} />
                            </div>
                            :
                            <div>
                                <span className='d-flex justify-content-end fontsize-12 mb-10'>Please note that the data you are viewing is cached and updated every 5 minutes</span>
                                {
                                    UnitTopics !== null && Object.keys(UnitTopics).length !== 0 && UnitTopics.topics.length !== 0 ?
                                        <div>
                                            <div className='d-flex justify-content-end'>
                                                {/* {
                                                    UnitTopics.qaStatus === "EVALUATING" ? "Evaluating" : UnitTopics.qaStatus === "INACTIVE" ?
                                                        <Button type='primary' transparented className="mb-10" onClick={() => { dispatch(GetUnitAttemptedQA(unitId)); setIsUnitModalVisible(true) }}>Unit Test Results</Button>
                                                        :
                                                        <Button type='primary' transparented className="mb-10" disabled={true}>Unit Test Results</Button>
                                                } */}
                                                {
                                                    UnitAttemptedQA !== null &&
                                                        UnitAttemptedQA !== undefined &&
                                                        Object.keys(UnitAttemptedQA).length === 0 ?
                                                        <Button
                                                            type='primary'
                                                            className="mb-10"
                                                            disabled={true}
                                                        >
                                                            Unit Test Results
                                                        </Button>
                                                        :
                                                        <Button
                                                            type='primary'
                                                            className="mb-10"
                                                            onClick={() => {
                                                                dispatch(GetUnitAttemptedQA(unitId));
                                                                setIsUnitModalVisible(true);
                                                            }}
                                                        >
                                                            Unit Test Results
                                                        </Button>

                                                }
                                            </div>
                                            <Table columns={TopicsColumns} dataSource={sortedData} pagination={false} />
                                        </div>
                                        :
                                        <div className='text-center' style={{ fontSize: '20px' }}>
                                            <p>Topics not found</p>
                                        </div>
                                }
                            </div>
                    }
                </NoteCardWrap>
            </Cards>

            {/* Topic Assessment modal */}
            <Modal
                title={`QA of ${!AttemptedQALoading && AttemptedQA !== null && AttemptedQA.attempt1 !== null && AttemptedQA.attempt1.data.length !== 0 && AttemptedQA.attempt1.data.length !== 0 ? AttemptedQA.attempt1.data[0].topicName : ''}`}
                open={isModalVisible}
                onCancel={handleModalCancel}
                width={1200}
                footer={<></>}
            >
                {
                    AttemptedQALoading ?
                        <div className='pt-20 pb-20 d-flex justify-content-center'>
                            <CustomSpinner Message={'Loading'} />
                        </div>
                        :
                        <div>
                            <div className='d-flex justify-content-between align-center-v mb-20'>
                                <h2 className='m-0'>Average Score : {AttemptedQA !== null && AttemptedQA.attempt1 !== null && AttemptedQA.attempt1.totalScore}</h2>
                                <div className='fontsize-12'>AvgScore = (QAScore1 + QAScore2)/2</div>
                            </div>
                            {
                                AttemptedQA !== null && AttemptedQA.attempt1 !== null && AttemptedQA.attempt1.data.length !== 0 && AttemptedQA.attempt1.data.map((obj, index) => <CollapseComponent QAObj={obj} index={index} />)
                            }
                        </div>
                }
            </Modal>

            {/* Unit Assessment modal */}
            <Modal
                title={`QA of ${UnitTopics !== null && UnitTopics.unitName}`}
                open={isUnitModalVisible}
                onCancel={() => setIsUnitModalVisible(false)}
                width={1200}
                footer={<></>}
            >
                {UnitAttemptedQALoading ? (
                    <div className='pt-20 pb-20 d-flex justify-content-center'>
                        <CustomSpinner Message={'Loading'} />
                    </div>
                ) : (
                    <div>
                        <div className='d-flex justify-content-between align-center-v mb-20'>
                            <h2 className='m-0'>Average Score : {UnitAttemptedQA !== null && UnitAttemptedQA?.attempt1 !== null && UnitAttemptedQA?.attempt1?.totalScore}</h2>
                            <div className='fontsize-12'>AvgScore = (QAScore1 + QAScore2 + QAScore3 + QAScore4 + QAScore5)/5</div>
                        </div>
                        {UnitAttemptedQA?.feedbackCondition && (
                            <div className='mb-10'>
                                <Alert
                                    className='fontsize-12 italic'
                                    type='info'
                                    message={
                                        <div>
                                            {/* <p className='m-0' style={{ fontWeight: 'bold' }}>
                                                By April 27, 2024, at 3:00 PM,
                                            </p>
                                            <p className='m-0'>
                                                If you are not satisfied with the evaluation of your answer, please provide your feedback for each evaluation.
                                               {UnitAttemptedQA?.feedbackDatetext}
                                            </p>
                                            <p className='m-0'>
                                                This will help us improve the evaluation system, and we will try to re-evaluate your answer if needed. Your input is valuable to us.
                                            </p> */}
                                            <p className='m-0' style={{ fontWeight: 'bold' }}>
                                                By {date} till {time}
                                            </p>
                                            <p className='m-0'>
                                                {UnitAttemptedQA?.feedbackDatetext.split('\n\n').map((line, index) => (
                                                    <React.Fragment key={index}>
                                                        {line}
                                                        <br />
                                                    </React.Fragment>
                                                ))}
                                            </p>
                                        </div>
                                    }
                                />
                            </div>
                        )}
                        {UnitAttemptedQA !== null && UnitAttemptedQA?.attempt1 !== null && UnitAttemptedQA?.attempt1?.data.length !== 0 && UnitAttemptedQA?.attempt1?.data.map((obj, index) => (
                            <CollapseComponent QAObj={obj} index={index} flag={UnitAttemptedQA?.feedbackCondition} />
                        ))}
                    </div>
                )}
            </Modal>
        </div>
    );
}

export default TopicsList

const CollapseComponent = ({ QAObj, index, flag }) => {
    const dispatch = useDispatch();
    return (
        <div>
            <Collapse defaultActiveKey={['1']} bordered={false} expandIcon={({ isActive }) => (isActive ? <UilMinus /> : <UilPlus />)} className='m-0 mb-5' >
                <Panel header={<div className='d-flex justify-content-between'><p className='m-0'>{index + 1 + '. ' + QAObj.question}</p><p className='fontsize-13'>Score: {QAObj.score} | {new Date(QAObj.submittedOn).toLocaleString()}</p></div>} key={index + 1}>
                    <h4>Student Answer</h4>
                    <p style={{ textAlign: 'justify', paddingBottom: '15px' }} className='m-0'>
                        {QAObj.userAnswer}
                    </p>
                    <div style={{ display: 'flex' }}>
                        <h4>Evaluation Feedback1</h4>
                        {flag && (
                            <div style={{ marginLeft: '650px', marginBottom: '7px', display: 'flex' }}>
                                {/* <Tooltip title='SATISFIED'>
                                <Button  type='success' onClick={() => { dispatch(EvaluationFeedback({ qnaId: QAObj.qnaId, evalNumber: 1, feedback: "SATISFIED" })) }}>
                                    <UilThumbsUp />
                                </Button>
                            </Tooltip>
                            &nbsp;
                            <Tooltip title='NOT SATISFIED'>
                                <Button  type='danger' onClick={() => { dispatch(EvaluationFeedback({ qnaId: QAObj.qnaId, evalNumber: 1, feedback: "NOT_SATISFIED" })) }}>
                                    <UilThumbsDown />
                                </Button>
                            </Tooltip> */}
                                <div style={{ marginTop: '7px', marginRight: '7px', fontSize: '18px', color: 'red' }}>
                                    <h6 className='italic' style={{ color: 'black' }}>Are you satisfied with the evaluation?</h6>
                                </div>
                                {QAObj.e1Flag === 'SATISFIED' ? (
                                    <>
                                        <Tooltip title='SATISFIED'>
                                            <Button type='success' onClick={() => { dispatch(EvaluationFeedback({ qnaId: QAObj.qnaId, evalNumber: 1, feedback: "SATISFIED" })) }}>
                                                <UilThumbsUp />
                                            </Button>
                                        </Tooltip>
                                        &nbsp;
                                        <Tooltip title='NOT SATISFIED'>
                                            <Button outlined type='danger' onClick={() => { dispatch(EvaluationFeedback({ qnaId: QAObj.qnaId, evalNumber: 1, feedback: "NOT_SATISFIED" })) }}>
                                                <UilThumbsDown />
                                            </Button>
                                        </Tooltip>
                                    </>
                                ) : (
                                    QAObj.e1Flag === 'NOT_SATISFIED' ? (
                                        <>
                                            <Tooltip title='SATISFIED'>
                                                <Button outlined type='success' onClick={() => { dispatch(EvaluationFeedback({ qnaId: QAObj.qnaId, evalNumber: 1, feedback: "SATISFIED" })) }}>
                                                    <UilThumbsUp />
                                                </Button>
                                            </Tooltip>
                                            &nbsp;
                                            <Tooltip title='NOT SATISFIED'>
                                                <Button type='danger' onClick={() => { dispatch(EvaluationFeedback({ qnaId: QAObj.qnaId, evalNumber: 1, feedback: "NOT_SATISFIED" })) }}>
                                                    <UilThumbsDown />
                                                </Button>
                                            </Tooltip>
                                        </>
                                    ) : (
                                        QAObj.e1Flag === 'NO_FEEDBACK' && (
                                            // JSX for NO_FEEDBACK
                                            // <div>Content for NO_FEEDBACK</div>
                                            <>
                                                <Tooltip title='SATISFIED'>
                                                    <Button outlined type='success' onClick={() => { dispatch(EvaluationFeedback({ qnaId: QAObj.qnaId, evalNumber: 1, feedback: "SATISFIED" })) }}>
                                                        <UilThumbsUp />
                                                    </Button>
                                                </Tooltip>
                                                &nbsp;
                                                <Tooltip title='NOT SATISFIED'>
                                                    <Button outlined type='danger' onClick={() => { dispatch(EvaluationFeedback({ qnaId: QAObj.qnaId, evalNumber: 1, feedback: "NOT_SATISFIED" })) }}>
                                                        <UilThumbsDown />
                                                    </Button>
                                                </Tooltip>
                                            </>
                                        )
                                    )
                                )}

                            </div>
                        )}
                    </div>
                    <p style={{ textAlign: 'justify', paddingBottom: '15px' }} className='m-0'>
                        {QAObj.description1 === null ? 'NA' : QAObj.description1}
                    </p>
                    <div className='mb-20'>{QAObj.description1 !== null && "Evaluation Score1: " + QAObj.evaluationscore1}</div>
                    <div style={{ display: 'flex' }}>
                        <h4>Evaluation Feedback2</h4>
                        {flag && (
                            <div style={{ marginLeft: '650px', marginBottom: '7px', display: 'flex' }}>
                                <div style={{ marginTop: '7px', marginRight: '7px', fontSize: '18px', color: 'red' }}>
                                    <h6 className='italic' style={{ color: 'black' }}>Are you satisfied with the evaluation?</h6>
                                </div>
                                {QAObj.e2Flag === 'SATISFIED' ? (
                                    <>
                                        <Tooltip title='SATISFIED'>
                                            <Button type='success' onClick={() => { dispatch(EvaluationFeedback({ qnaId: QAObj.qnaId, evalNumber: 2, feedback: "SATISFIED" })) }}>
                                                <UilThumbsUp />
                                            </Button>
                                        </Tooltip>
                                        &nbsp;
                                        <Tooltip title='NOT SATISFIED'>
                                            <Button outlined type='danger' onClick={() => { dispatch(EvaluationFeedback({ qnaId: QAObj.qnaId, evalNumber: 2, feedback: "NOT_SATISFIED" })) }}>
                                                <UilThumbsDown />
                                            </Button>
                                        </Tooltip>
                                    </>
                                ) : (
                                    QAObj.e2Flag === 'NOT_SATISFIED' ? (
                                        <>
                                            <Tooltip title='SATISFIED'>
                                                <Button outlined type='success' onClick={() => { dispatch(EvaluationFeedback({ qnaId: QAObj.qnaId, evalNumber: 2, feedback: "SATISFIED" })) }}>
                                                    <UilThumbsUp />
                                                </Button>
                                            </Tooltip>
                                            &nbsp;
                                            <Tooltip title='NOT SATISFIED'>
                                                <Button type='danger' onClick={() => { dispatch(EvaluationFeedback({ qnaId: QAObj.qnaId, evalNumber: 2, feedback: "NOT_SATISFIED" })) }}>
                                                    <UilThumbsDown />
                                                </Button>
                                            </Tooltip>
                                        </>
                                    ) : (
                                        QAObj.e2Flag === 'NO_FEEDBACK' && (
                                            <>
                                                <Tooltip title='SATISFIED'>
                                                    <Button outlined type='success' onClick={() => { dispatch(EvaluationFeedback({ qnaId: QAObj.qnaId, evalNumber: 2, feedback: "SATISFIED" })) }}>
                                                        <UilThumbsUp />
                                                    </Button>
                                                </Tooltip>
                                                &nbsp;
                                                <Tooltip title='NOT SATISFIED'>
                                                    <Button outlined type='danger' onClick={() => { dispatch(EvaluationFeedback({ qnaId: QAObj.qnaId, evalNumber: 2, feedback: "NOT_SATISFIED" })) }}>
                                                        <UilThumbsDown />
                                                    </Button>
                                                </Tooltip>
                                            </>
                                        )
                                    )
                                )}
                            </div>
                        )}
                    </div>
                    <p style={{ textAlign: 'justify', paddingBottom: '15px' }} className='m-0'>
                        {QAObj.description2 === null ? 'NA' : QAObj.description2}
                    </p>
                    <div className='mb-20'>{QAObj.description2 !== null && "Evaluation Score2: " + QAObj.evaluationscore2}</div>
                    <div style={{ display: 'flex' }}>
                        <h4>Evaluation Feedback3</h4>
                        {flag && (
                            <div style={{ marginLeft: '650px', marginBottom: '7px', display: 'flex' }}>
                                <div style={{ marginTop: '7px', marginRight: '7px', fontSize: '18px', color: 'red' }}>
                                    <h6 className='italic' style={{ color: 'black' }}>Are you satisfied with the evaluation?</h6>
                                </div>
                                {QAObj.e3Flag === 'SATISFIED' ? (
                                    <>
                                        <Tooltip title='SATISFIED'>
                                            <Button type='success' onClick={() => { dispatch(EvaluationFeedback({ qnaId: QAObj.qnaId, evalNumber: 3, feedback: "SATISFIED" })) }}>
                                                <UilThumbsUp />
                                            </Button>
                                        </Tooltip>
                                        &nbsp;
                                        <Tooltip title='NOT SATISFIED'>
                                            <Button outlined type='danger' onClick={() => { dispatch(EvaluationFeedback({ qnaId: QAObj.qnaId, evalNumber: 3, feedback: "NOT_SATISFIED" })) }}>
                                                <UilThumbsDown />
                                            </Button>
                                        </Tooltip>
                                    </>
                                ) : (
                                    QAObj.e3Flag === 'NOT_SATISFIED' ? (
                                        <>
                                            <Tooltip title='SATISFIED'>
                                                <Button outlined type='success' onClick={() => { dispatch(EvaluationFeedback({ qnaId: QAObj.qnaId, evalNumber: 3, feedback: "SATISFIED" })) }}>
                                                    <UilThumbsUp />
                                                </Button>
                                            </Tooltip>
                                            &nbsp;
                                            <Tooltip title='NOT SATISFIED'>
                                                <Button type='danger' onClick={() => { dispatch(EvaluationFeedback({ qnaId: QAObj.qnaId, evalNumber: 3, feedback: "NOT_SATISFIED" })) }}>
                                                    <UilThumbsDown />
                                                </Button>
                                            </Tooltip>
                                        </>
                                    ) : (
                                        QAObj.e3Flag === 'NO_FEEDBACK' && (
                                            <>
                                                <Tooltip title='SATISFIED'>
                                                    <Button outlined type='success' onClick={() => { dispatch(EvaluationFeedback({ qnaId: QAObj.qnaId, evalNumber: 3, feedback: "SATISFIED" })) }}>
                                                        <UilThumbsUp />
                                                    </Button>
                                                </Tooltip>
                                                &nbsp;
                                                <Tooltip title='NOT SATISFIED'>
                                                    <Button outlined type='danger' onClick={() => { dispatch(EvaluationFeedback({ qnaId: QAObj.qnaId, evalNumber: 3, feedback: "NOT_SATISFIED" })) }}>
                                                        <UilThumbsDown />
                                                    </Button>
                                                </Tooltip>
                                            </>
                                        )
                                    )
                                )}
                            </div>
                        )}
                    </div>
                    <p style={{ textAlign: 'justify', paddingBottom: '15px' }} className='m-0'>
                        {QAObj.description3 === null ? 'NA' : QAObj.description3}
                    </p>
                    <div className='mb-20'>{QAObj.description3 !== null && "Evaluation Score3: " + QAObj.evaluationscore3}</div>
                    <div className='mt-20 mb-20 fontsize-12'>Note: QAScore = (EvaluationScore1 + EvaluationScore2 + EvaluationScore3)/3</div>
                </Panel>
            </Collapse>
        </div>
    );
}