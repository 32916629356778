import React, { useState, useEffect } from 'react';
import { Row, Col, Table, Button, Input, Modal, Form, Space, Select, Tag } from 'antd';
import UilEdit from '@iconscout/react-unicons/icons/uil-edit';
import UilTrash from '@iconscout/react-unicons/icons/uil-trash-alt';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import {UserTableStyleWrapper} from './styles/tablestyle'
import { Cards } from '../../components/cards/frame/cards-frame';
import { TableWrapper, Main } from '../styled';
import { PageHeader } from '../../components/page-headers/page-headers';
import { yearsemesterDataRead, yearsemesterDataSubmit, yearsemesterDataUpdate } from '../../redux/yearsemester/actionCreator';
import { regulationDataRead } from '../../redux/regulation/actionCreator';
import { useDispatch, useSelector } from 'react-redux';
import { ProjectList, ProjectSorting } from '../project/style';
import { AutoComplete } from '../../components/autoComplete/autoComplete';

const { Option } = Select;

function YearSemester() {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [form] = Form.useForm();
  const [editForm] = Form.useForm();

  const [editRecord, setEditRecord] = useState(null);

  const dispatch = useDispatch();
  const { YearSemester,Regulations, isLoading } = useSelector((state) => {
    return {
      YearSemester: state.YearSemester.data,
      Regulations: state.Regulations.data,
      isLoading: state.YearSemester.loading,
    };
  });

  useEffect(() => {
    dispatch(yearsemesterDataRead());
    dispatch(regulationDataRead());
  }, [dispatch]);

  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (YearSemester) {
      setDataSource(YearSemester);
      setLoading(false);
    }
  }, [YearSemester]);


  const usersTableData = [];

  YearSemester.map((yearsem,index) => {
    const { id, code, name, regulationId, regulation  } = yearsem;

    return usersTableData.push({
      key: id,
      sno:index+1,
      code:code,
      name:name,
      regulation:regulation.code,
      action: (
        <div className="table-actions">          
          <Button className="btn-icon" type="info" to="#" shape="circle">
            <UilEdit />
          </Button>          
        </div>
      ),
    });
  });

  const columns = [
    {
      title: 'S.No.',
      dataIndex: 'sno',
      key: 'sno',
    },
    {
      title: 'Code',
      dataIndex: 'code',
      key: 'code',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Regulation',
      dataIndex: 'regulation',
      key: 'regulation',
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (text, record) => (
        <Space>
          <Button icon={<UilEdit />} onClick={() => handleEdit(record)}></Button>
          <Button icon={<UilTrash />} onClick={() => handleDelete(record)}></Button>
        </Space>
      ),
    },
  ];

  const handleButtonClick = () => {
    setIsModalVisible(true);
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
  };

  const handleModalAdd = () => {
    form.validateFields().then((values) => {
      const newRow = {
        // key: String(dataSource.length + 1),
        // id: String(dataSource.length + 1),
        code: values.code,
        name: values.name,
        regulationId: values.regulationId,
      };
      setDataSource([...dataSource, newRow]);
      form.resetFields();
      setIsModalVisible(false);
      dispatch(yearsemesterDataSubmit(newRow));
      console.log(newRow.regulationId);
    });
  };

  const handleModalUpdate = () => {
    editForm.validateFields().then((values) => {
      const updatedRecord = {
        ...editRecord,
        code: values.code,
        name: values.name,
        regulationId: values.regulationId,
      };

      const updatedDataSource = dataSource.map((item) => (item.id === updatedRecord.id ? updatedRecord : item));

      setDataSource(updatedDataSource);
      setIsEditModalVisible(false);
      dispatch(yearsemesterDataUpdate(updatedRecord));
    });
  };

  const handleEdit = (record) => {
    setEditRecord(record);
    setIsEditModalVisible(true);
    editForm.setFieldsValue({
      code: record.code,
      name: record.name,
      regulationId: record.regulationId,
    });
  };

  const handleDelete = (record) => {
    Modal.confirm({
      title: 'Are you sure you want to delete this record?',
      icon: <ExclamationCircleOutlined />,
      content: 'Once deleted, the action cannot be undone.',
      okText: 'Delete',
      okType: 'danger',
      cancelText: 'Cancel',
      onOk() {
        const updatedDataSource = dataSource.filter((item) => item.id !== record.id);
        const updatedDataSourceWithSNo = updatedDataSource.map((item, index) => ({
          ...item,
          id: String(index + 1),
          key: String(index + 1),
        }));
        setDataSource(updatedDataSourceWithSNo);
      },
    });
  };

  return (
    <>
    <PageHeader className="ninjadash-page-header-main" title="Year Semester" 
        buttons={[
            <Button onClick={handleButtonClick} key="1" type="primary" size="default">
              Add Year-Semester
            </Button>,
          ]}/>      
      <Main>
        <Row gutter={25}>
          <Col sm={24} xs={24}>
          <ProjectSorting>
              <div className="project-sort-bar">                
                <div className="project-sort-search">
                  <AutoComplete onSearch={''} dataSource={usersTableData} placeholder="Search yearsem" patterns />
                </div>
                <div className="project-sort-group">
                  <div className="sort-group">
                    <span>Regulation</span>
                    <Select onChange={''} defaultValue="-1" value={''}>
                      <Select.Option value='-1'>All</Select.Option>
                      {
                        Regulations.length !==0 &&  Regulations.map((obj)=>
                            <Select.Option value={obj.id}>{obj.code}</Select.Option>  
                        )
                      }                                          
                    </Select>
                  </div>
                </div>
              </div>
            </ProjectSorting>
            <Cards headless>
            <ProjectList>
            <div className="table-responsive">
                  <Table dataSource={usersTableData} columns={columns} />
                </div>
              </ProjectList>
            </Cards>
          </Col>
        </Row>
      </Main>
      <Modal
        title="Add Year-Semester"
        visible={isModalVisible}
        onCancel={handleModalCancel}
        footer={[
          <Button key="add" type="primary" onClick={handleModalAdd}>
            Add
          </Button>,
        ]}
      >
        <Form form={form} layout="vertical">
          <Form.Item name="code" label="Code" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item name="name" label="Name" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item name="regulationId" label="Regulation" rules={[{ required: true }]}>
            <Select placeholder="Select Regulation">
              {Regulations.map((regulation) => (
                <Option key={regulation.code} value={regulation.id}>
                  {regulation.code}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title="Edit Year-Semester"
        visible={isEditModalVisible}
        onCancel={() => setIsEditModalVisible(false)}
        footer={[
          <Button key="update" type="primary" onClick={handleModalUpdate}>
            Update
          </Button>,
        ]}
      >
        <Form form={editForm} layout="vertical">
          <Form.Item name="code" label="Code" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item name="name" label="Name" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item name="regulationId" label="Regulation" rules={[{ required: true }]}>
            <Select placeholder="Select Regulation">
              {Regulations.length !==0 && Regulations.map((regulation) => (
                <Option key={regulation.id} value={regulation.code}>
                  {regulation.code}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

export default YearSemester;
