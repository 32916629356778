import React, { useEffect, useState } from 'react';
import { PageHeader } from '../../components/page-headers/page-headers';
import { BasicFormWrapper, Main } from '../styled';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Badge, Checkbox, Col, Form, Input, Modal, Pagination, Radio, Result, Row, Select, Tag, Tooltip, message, notification } from 'antd';
import Heading from '../../components/heading/heading';
import TextArea from 'antd/lib/input/TextArea';
import { WizardWrapper, WizardFour } from './styles/wizardstyle';
import { Steps } from '../../components/steps/steps';
import { ProjectSorting } from '../project/style';
import { Cards } from '../../components/cards/frame/cards-frame';
import { SelectWrapperStyle } from '../ui-elements/ui-elements-styled';
import { UnitQADataUpdate, deleteQA, deleteUnitQA, getUnitwiseGeneratedQA } from '../../redux/generateQuestions/actionCreator';
import actions from '../../redux/generateQuestions/actions';
import { NoteCardWrap } from '../note/style';
import { EyeOutlined } from '@ant-design/icons';
import { updateTopicStatusById } from '../../redux/topics/actionCreator';
import { Button } from '../../components/buttons/buttons';
import CustomSpinner from '../ui-elements/spinner/CustomSpinner';
import { alertModal } from '../../components/modals/antd-modals';
import { theme } from '../../config/theme/themeVariables';
import UilTrashAlt from '@iconscout/react-unicons/icons/uil-trash-alt'

import TopicStatusActions from '../../redux/topics/actions';
import QAactions from '../../redux/generateQuestions/actions';
import { getUnitById } from '../../redux/teacherSubjectsUnits/actionCreator';
import UilArrowLeft from '@iconscout/react-unicons/icons/uil-arrow-left';

const { UnitQAUpdateSuccess, deleteUnitQASuccess } = QAactions

const { Option } = Select;

const { generateUnitWiseQASuccess } = actions;
const { topicStatusUpdateSuccess } = TopicStatusActions;

function UnitwiseQAReview({ unitId, getStatusIndex, UnitInfo, unitStatus }) {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [QAContent, setQAContent] = useState([]);
  // const [modalVisible, setModalVisible] = useState(false);  
  // const [selectedCategory, setCategory] = useState("Easy")
  const [selectedQuestionIndex, setSelectedQuestionIndex] = useState(0)
  const [changedQuestion, setChangedQuestion] = useState("")
  const [changedAnswer, setChangedAnswer] = useState("")
  const [type, setType] = useState("")
  const [questionId, setQuestionId] = useState(0)
  // const [questionCount, setQuestionsCount] = useState(0)
  const [reviewCompletedModal, setReviewCompletedModal] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [cancelModal, setCancelModal] = useState(false);
  // const [categoryReviewModal, setCategoryReviewModal] = useState(false);

  const { QaData, isLoading, QAUpdateRes, isQuestionUpdating, DeleteQARes, isDeleting } = useSelector((state) => {
    return {
      QaData: state.unitwiseQa.data,
      isLoading: state.unitwiseQa.loading,
      QAUpdateRes: state.UnitQAUpdateRes.data,
      isQuestionUpdating: state.UnitQAUpdateRes.loading,
      isDeleting: state.DeleteUnitQARes.loading,
      DeleteQARes: state.DeleteUnitQARes.data,
    };
  });

  useEffect(() => {
    dispatch(generateUnitWiseQASuccess(null))
    if (getStatusIndex(UnitInfo !== null && UnitInfo.qaStatus) === 2 || getStatusIndex(UnitInfo !== null && UnitInfo.qaStatus) === 3) {
      dispatch(getUnitwiseGeneratedQA(parseInt(unitId)));
    }
    setIsDirty(false)
  }, [unitId, QAUpdateRes, DeleteQARes]);

  useEffect(() => {
    if (DeleteQARes !== null && DeleteQARes.Error === false) {
      dispatch(deleteUnitQASuccess(null))
      setSelectedQuestionIndex(0)
    }
  }, [DeleteQARes]);

  useEffect(() => {
    if (QaData !== null && QaData.qaReviewflag === true && QaData.qaStatus === "QUESTIONS GENERATED" && unitStatus !== null && unitStatus === "QUESTIONS GENERATED") {
      dispatch(getUnitById(parseInt(unitId)));
    }
    else if (unitStatus !== null && unitStatus === "QUESTIONS REVIEWED" && QaData !== null && QaData.qaReviewflag === true && QaData.qaStatus === "QUESTIONS REVIEWED") {
      setReviewCompletedModal(true)
    }

  }, [QaData]);

  const handleReset = () => {
    if (QAContent[selectedQuestionIndex].question !== changedQuestion || QAContent[selectedQuestionIndex].answer !== changedAnswer) {
      setCancelModal(true);
    } else {
      setIsDirty(false);
    }
  };

  const handleDiscardChanges = () => {
    setChangedQuestion(QAContent[selectedQuestionIndex].question);
    setChangedAnswer(QAContent[selectedQuestionIndex].answer);
    setCancelModal(false);
    setIsDirty(false);
  };

  const handleModalCancel = () => {
    setCancelModal(false);
  };

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (isDirty) {
        event.preventDefault();
        event.returnValue = '';
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isDirty]);

  const [filterLoading, setFilterLoading] = useState(false)
  useEffect(() => {
    setFilterLoading(true)
    if (QaData !== null && QaData.unitqas.length !== 0) {
      let array = QaData.unitqas
      setQAContent(array)
      if (array.length !== 0 && selectedQuestionIndex !== array.length) {
        setQuestionId(array[selectedQuestionIndex].id)
        setChangedQuestion(array[selectedQuestionIndex].question)
        setChangedAnswer(array[selectedQuestionIndex].answer)
        setType(array[selectedQuestionIndex].type.replace("LEVEL_",""))
      }
      else {
        setQuestionId(0)
        setChangedQuestion("")
        setChangedAnswer("")
        setType("")
      }
    }
    else {
      setQAContent([])
      setQuestionId(0)
      setChangedQuestion("")
      setChangedAnswer("")
      setType("")
    }

    setFilterLoading(false)

  }, [QaData])

  const getSelectedQuestion = (object, index) => {
    setQuestionId(object.id)
    setSelectedQuestionIndex(index)
    setChangedQuestion(QAContent[index].question)
    setChangedAnswer(QAContent[index].answer)
    setType(QAContent[index].type.replace("LEVEL_",""))
  }

  // const moveToNextCategory = () => {
  //   setCategoryReviewModal(false)
  //   setTeacherAnswer('')
  //   if (topicStatus === "QUESTIONS GENERATED") {
  //     if (selectedCategory === "Easy") {
  //       setCategory('Medium');
  //       setSelectedQuestionIndex(0)
  //     }
  //     else if (selectedCategory === "Medium") {
  //       setCategory('Hard');
  //       setSelectedQuestionIndex(0)
  //     }
  //   }
  // }

  useEffect(() => {
    const updateFunction = async () => {
      if (QAUpdateRes !== null && QAUpdateRes.Error === false) {
        let array = QaData !== null && QaData.unitqas.length !== 0 && QaData.unitqas.length !== 0 && QaData.unitqas
        if ((selectedQuestionIndex < (array.length - 1)) && QAContent.length !== 0) {
          setSelectedQuestionIndex(parseInt(selectedQuestionIndex) + 1)
          setChangedQuestion(QAContent[parseInt(selectedQuestionIndex) + 1].question)
          setChangedAnswer(QAContent[parseInt(selectedQuestionIndex) + 1].answer)
        }
        // else if (QaData !== null && QaData.qastatus !== "QUESTIONS REVIEWED") {
        //   if (selectedCategory !== "Hard") {
        //     setCategoryReviewModal(true)
        //   }
        // }
        else if (QaData !== null && QaData.qastatus === "QUESTIONS REVIEWED") {
          setReviewCompletedModal(true)
        }
        setIsDirty(false)
      }
      dispatch(UnitQAUpdateSuccess(null));
    }
    updateFunction()

  }, [QAUpdateRes])

  const UpdateQA = () => {
    const data = {
      id: questionId,
      question: changedQuestion,
      type: type,
      answer: changedAnswer,
      questionList: QAContent
    }
    console.log("data.type",data.type);
    console.log("update Qa Called> ",data.questionList);
    const questionsWithType = data.questionList.filter((question) => question.type === `LEVEL_${data.type}`);
    console.log("questionsWithType length",questionsWithType.length)
    if(questionsWithType.length >= 4)
    {
      dispatch(UnitQADataUpdate(data))
    }
    else
    {
      message.error(`Less than 4 questions in Level ${data.type}, cannot proceed.`)
    }
  }

  const showDeleteConfirm = () => {
    alertModal.confirm({
      title: `Do you want to delete this question?`,
      content: 'Q & A once deleted cannot be undo.',
      okText: 'Yes',
      cancelText: 'No',
      onOk() {
        if (questionId !== 0) {
          dispatch(deleteUnitQA(questionId));
        }
      },
      onCancel() { },
    });
  };

  return (
    <>
      <Row gutter={25} justify="center">
        <Col lg={24} xs={24}>
          <NoteCardWrap>
            {
              isLoading ?
                <div className="custom-spin">
                  <CustomSpinner Message={'Loading Q & A... Please wait. '} />
                </div>
                :

                getStatusIndex(UnitInfo !== null && UnitInfo.qaStatus) === 5 ?
                  <Result
                    status="success"
                    title="Content Freezed"
                    subTitle={`on ${UnitInfo !== null && new Date(UnitInfo.updatedon).toLocaleString()}`}
                  />
                  :
                  <div>
                    {
                      < div >
                        <div className='d-flex justify-content-between mb-10'>
                          <ProjectSorting>
                            <div className="project-sort-bar">
                              <div className="project-sort-nav" style={{ boxShadow: '0px 0px 15px 0px #eee', borderRadius: '6px' }}>
                                {/* border:'1px solid #e3e6ef',padding:'0px' */}
                                {/* <nav>
                                              <ul>
                                                <li className={selectedCategory === 'Easy' ? 'active' : 'deactivate'}>
                                                  <Link id="reviewQA_easy-button" onClick={() => { setCategory('Easy'); setSelectedQuestionIndex(0); setTeacherAnswer('') }} to="#">
                                                    Easy ({QaData !== null && QaData.chunks.length !== 0 && QaData.chunks[selectedChunk].qas.length !== 0 ? QaData.chunks[selectedChunk].qas.filter(qObj => qObj.type.toLowerCase() === 'easy').length : 0})
                                                  </Link>
                                                </li>
                                                <li className={selectedCategory === 'Medium' ? 'active' : 'deactivate'}>
                                                  <Link id="reviewQA_medium-button" onClick={() => { setCategory('Medium'); setSelectedQuestionIndex(0); setTeacherAnswer('') }} to="#">
                                                    Medium ({QaData !== null && QaData.chunks.length !== 0 && QaData.chunks[selectedChunk].qas.length !== 0 ? QaData.chunks[selectedChunk].qas.filter(qObj => qObj.type.toLowerCase() === 'medium').length : 0})
                                                  </Link>
                                                </li>
                                                <li className={selectedCategory === 'Hard' ? 'active' : 'deactivate'}>
                                                  <Link id="reviewQA_hard-button" onClick={() => { setCategory('Hard'); setSelectedQuestionIndex(0); setTeacherAnswer('') }} to="#">
                                                    Hard ({QaData !== null && QaData.chunks.length !== 0 && QaData.chunks[selectedChunk].qas.length !== 0 ? QaData.chunks[selectedChunk].qas.filter(qObj => qObj.type.toLowerCase() === 'hard').length : 0})
                                                  </Link>
                                                </li>
                                              </ul>
                                            </nav> */}
                              </div>
                            </div>
                          </ProjectSorting>
                        </div>
                        <div>
                          <div className='d-flex justify-content-between'>
                            <h4>Questions and Answers</h4>
                            {
                              changedAnswer !== "" &&
                              <div className='taglist-wrap'>
                                <Tag color="orange">Last saved : {QAContent.length !== 0 && new Date(QAContent[selectedQuestionIndex].updatedon).toLocaleString()}</Tag>
                              </div>
                            }
                          </div>
                          <div className='mt-20 mb-20 d-flex justify-content-between'>
                            <div>
                              {
                                QAContent.length !== 0 && QAContent.map((filteredData, index) =>
                                  <Button id="reviewQA_select-qa" key={index} shape="circle" type={'white'} raised style={{ color: 'black', marginRight: 10, boxShadow: `${selectedQuestionIndex === index ? `0px 0px 8px 1px ${theme['primary-color']}` : ''}` }} onClick={() => getSelectedQuestion(filteredData, index)}>
                                    {filteredData.reviewflag === true && <Badge dot color={theme['success-color']} className='mr-3' />}{index + 1}
                                  </Button>
                                )
                              }
                            </div>
                            <div>
                              {
                                QAContent.length !== 0 &&
                                <Tooltip placement='left' title="Delete current Q & A">
                                  <Button id="reviewQA_delete-button" type="danger" transparented onClick={showDeleteConfirm} disabled={isDeleting ? true : false}>
                                    {isDeleting ? 'Deleting' : <UilTrashAlt size={20} />}
                                  </Button>
                                </Tooltip>
                              }
                            </div>
                          </div>
                        </div>
                        <div className="">
                          <Row justify="center">
                            <Col xs={24}>
                              <div className="create-account-form">
                                {
                                  filterLoading === true ? "loading" :
                                    <>
                                      {
                                        QAContent.length !== 0 ?
                                          <MyForm id="reviewQA_form" type={type} changedQuestion={changedQuestion} changedAnswer={changedAnswer} setChangedQuestion={setChangedQuestion} setChangedAnswer={setChangedAnswer} setIsDirty={setIsDirty} />
                                          :
                                          <Result
                                            status="warning"
                                            title="Q & A not found"
                                            extra={
                                              <Button id="reviewunitQa_generate-button" type="primary" onClick={() => { dispatch(getUnitById(parseInt(unitId))); navigate('/teacher/unit-wise/qa-generation') }}>
                                                <UilArrowLeft />
                                                Generate
                                              </Button>
                                            }
                                          />
                                      }
                                    </>
                                }
                              </div>
                            </Col>
                          </Row>
                          {
                            QAContent.length !== 0 &&
                            <div className='d-flex justify-content-between mb-20'>
                              <div className='taglist-wrap'>
                                {/* <Checkbox style={{ marginLeft: '5px' }} checked>
                                  Auto save
                                </Checkbox> */}
                                {/* <br /> */}
                                {/* <Tag color="orange">Last saved : {QAContent.length !== 0 && new Date(QAContent[selectedQuestionIndex].updatedon).toLocaleString()}</Tag> */}
                              </div>
                              <div className=''>
                                <Button id="reviewQA_reset-button" htmlType="submit" type="white" outlined style={{ color: 'black', marginRight: '10px' }} onClick={handleReset} >
                                  Reset
                                </Button>
                                <Button id="reviewQA_review-button" htmlType="submit" type="primary" onClick={UpdateQA} disabled={isQuestionUpdating ? true : false}>
                                  {isQuestionUpdating ? 'Please Wait...' : 'Review & Next'}
                                </Button>
                              </div>
                            </div>
                          }
                        </div>
                        {/* {
                                            changedQuestion !== "" &&
                                            <div>
                                              <div className='mt-20 mb-20'>
                                                <h4>Your Answer</h4>
                                                <TextArea id="reviewQA_teacher-answer" placeholder="Type your answer here..." rows={6} minLength={Math.ceil((QAContent.length !== 0 && QAContent[selectedQuestionIndex].answer.length / 100) * 60)} value={teacherAnswer} onChange={(e) => { setTeacherAnswer(e.target.value); }} style={{ textAlign: 'justify' }} />
                                                <span className='fontsize-13 d-flex justify-content-end'>Characters {teacherAnswer.trim().length} / {Math.ceil((QAContent.length !== 0 && QAContent[selectedQuestionIndex].answer.length / 100) * 60)}<i>(min)</i></span>
                                              </div>
                                              <div className='d-flex justify-content-between mb-20'>
                                                <div className='taglist-wrap'>
                                                </div>
                                                <div className=''>
                                                  <Button id="reviewQA_evaluate-answers" htmlType="submit" type="primary" onClick={EvaluateQA} disabled={!isAnsWritten || isAnsEvaluating}>
                                                    {isAnsEvaluating ? 'Please Wait...' : 'Evaluate'}
                                                  </Button>
                                                </div>
                                              </div>
                                            </div>
                                          } */}
                      </div>
                    }
                  </div>
            }
          </NoteCardWrap>
        </Col>
      </Row>
      <Modal open={cancelModal} title="Discard Changes" onCancel={handleModalCancel} onOk={handleDiscardChanges} okText="Yes" cancelText="No">
        <h3>Do you want to DISCARD the changes?</h3>
      </Modal>
      <Modal open={reviewCompletedModal} title="Verify Programs" onCancel={() => setReviewCompletedModal(false)} onOk={() => { navigate('/teacher/unit-wise/programs') }} okText="Yes" cancelText="No">
        <h4>All Questions and Answers are Reviewed</h4>
        <h4>Do you want to Verify Programs?</h4>
      </Modal>
      {/* <CategoryReviewCompleted title={selectedCategory} setCategoryReviewModal={setCategoryReviewModal} categoryReviewModal={categoryReviewModal} nextCategory={selectedCategory === "Easy" ? "Medium" : selectedCategory === "Medium" ? "Hard" : selectedCategory === "Hard" && ''} moveToNextCategory={moveToNextCategory} /> */}
    </>
  );
}

export default UnitwiseQAReview;


const MyForm = ({ type, changedQuestion, changedAnswer, setChangedQuestion, setChangedAnswer, setIsDirty }) => {

  return (
    <>
      <div>
        <h4>Question</h4>
        <Input id="reviewQA_question-input" placeholder="Question" value={changedQuestion} onChange={(e) => { setChangedQuestion(e.target.value); setIsDirty(true) }} />
      </div>
      <div style={{ marginTop: '20px' }}>
        <h4>Blooms Taxonomy Level: {type}</h4>
      </div>
      <div className='mt-20 mb-20'>
        {/* <h4>Answer</h4>
        <TextArea id="reviewQA_answer-input" placeholder="Answer" rows={8} value={changedAnswer} onChange={(e) => { setChangedAnswer(e.target.value); setIsDirty(true) }} style={{ textAlign: 'justify' }} /> */}
      </div>
    </>
  );
}


// const CategoryReviewCompleted = ({ title, moveToNextCategory, categoryReviewModal, setCategoryReviewModal, nextCategory }) => {
//   return (
//     <Modal open={categoryReviewModal} onCancel={() => setCategoryReviewModal(false)} onOk={() => moveToNextCategory()} okText="Yes" cancelText="No">
//       <h4>All {title} Q & A are Reviewed</h4>
//       <h4>Do you want to review {nextCategory} Q & A?</h4>
//     </Modal>
//   );
// }
