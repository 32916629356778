import actions from './actions';

const {
  SUBJECT_UNITS_READ_BEGIN,
  SUBJECT_UNITS_READ_SUCCESS,
  SUBJECT_UNITS_READ_ERR,

  SUBJECT_READ_BEGIN,
  SUBJECT_READ_SUCCESS,
  SUBJECT_READ_ERR,

  UNIT_READ_BEGIN,
  UNIT_READ_SUCCESS,
  UNIT_READ_ERR,

  TEACHER_SUBJECTS_BEGIN,
  TEACHER_SUBJECTS_SUCCESS,
  TEACHER_SUBJECTS_ERR,

  GET_QA_BEGIN,
  GET_QA_SUCCESS,
  GET_QA_ERR,

} = actions;

const initialState = {
  data: null,
  loading: false,
  error: null,
};

const initialStateSubject = {
  data: null,
  loading: false,
  error: null,
};

const initialStateUnit = {
  data: null,
  loading: false,
  error: null,
};

const initialStateTeacherReagulationSubjects = {
  data: [],
  loading: false,
  error: null,
};

const QAinitialState = {
  data: [],
  loading: false,
  error: null,
};

const SUBJECT_UNITSReducer = (state = initialState, action) => {
  const { type, data, err } = action;
  switch (type) {

    case SUBJECT_UNITS_READ_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case SUBJECT_UNITS_READ_SUCCESS:
      return {
        ...state,
        data,
        error: false,
        loading: false,
      };

    case SUBJECT_UNITS_READ_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

const SUBJECTReducer = (state = initialStateSubject, action) => {
  const { type, data, err } = action;
  switch (type) {

    case SUBJECT_READ_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case SUBJECT_READ_SUCCESS:
      return {
        ...state,
        data,
        error: false,
        loading: false,
      };

    case SUBJECT_READ_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

const UNITReducer = (state = initialStateUnit, action) => {
  const { type, data, err } = action;
  switch (type) {

    case UNIT_READ_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case UNIT_READ_SUCCESS:
      return {
        ...state,
        data,
        error: false,
        loading: false,
      };

    case UNIT_READ_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

const TeacherRegSubjectsReducer = (state = initialStateTeacherReagulationSubjects, action) => {
  const { type, data, err } = action;
  switch (type) {

    case TEACHER_SUBJECTS_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case TEACHER_SUBJECTS_SUCCESS:
      return {
        ...state,
        data,
        error: false,
        loading: false,
      };

    case TEACHER_SUBJECTS_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

const getUnitQAReducer = (state = QAinitialState, action) => {
  const { type, data, err } = action;
  switch (type) {

    case GET_QA_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case GET_QA_SUCCESS:
      return {
        ...state,
        data,
        error: false,
        loading: false,
      };

    case GET_QA_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    default:
      return state;
  }
};

export { SUBJECT_UNITSReducer, SUBJECTReducer, UNITReducer, TeacherRegSubjectsReducer, getUnitQAReducer };