const actions = {
 
    TOPIC_PROGRAMMING_BEGIN: 'TOPIC_SINGLE_DATA_BEGIN',
    TOPIC_PROGRAMMING_SUCCESS: 'TOPIC_SINGLE_DATA_SUCCESS',
    TOPIC_PROGRAMMING_ERR: 'TOPIC_SINGLE_DATA_ERR',

    PROGRAMMING_TSSRDETAILS_BEGIN:'PROGRAMMING_TSSRDETAILS_BEGIN',
    PROGRAMMING_TSSRDETAILS_SUCCESS:'PROGRAMMING_TSSRDETAILS_SUCCESS',
    PROGRAMMING_TSSRDETAILS_ERR:'PROGRAMMING_TSSRDETAILS_ERR',




    topicProgrammingBegin: () => {
        return {
          type: actions.TOPIC_PROGRAMMING_BEGIN,
        };
      },
    
      topicProgrammingSuccess: (data) => {
        return {
          type: actions.TOPIC_PROGRAMMING_SUCCESS,
          data,
        };
      },
    
      topicProgrammingErr: (err) => {
        return {
          type: actions.TOPIC_PROGRAMMING_ERR,
          err,
        };
      },


      
    ProgrammingTesselatorBegin: () => {
      return {
        type: actions.PROGRAMMING_TSSRDETAILS_BEGIN,
      };
    },
  
    ProgrammingTesselatorSuccess: (data) => {
      return {
        type: actions.PROGRAMMING_TSSRDETAILS_SUCCESS,
        data,
      };
    },
  
    ProgrammingTesselatorErr: (err) => {
      return {
        type: actions.PROGRAMMING_TSSRDETAILS_ERR,
        err,
      };
    },
};

export default actions;
