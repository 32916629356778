
import React, { lazy, Suspense, useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import withAdminLayout from '../../layout/withAdminLayout';
import Regulations from '../../container/admin/Regulations';
import RegulationSingle from '../../container/admin/RegulationSingle';
import YearSemester from '../../container/admin/YearSemester';
import Branch from '../../container/admin/Branch';
import Subjects from '../../container/admin/Subjects';
import SubjectByBranch from '../../container/admin/SubjectByBranch';
import Topics from '../../container/admin/Topics';
import CustomSpinner from '../../container/ui-elements/spinner/CustomSpinner';

const NotFound = lazy(() => import('../../container/pages/404'));

const Admin = React.memo(() => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <Suspense
      fallback={
        <div className="">
          <CustomSpinner />
        </div>
      }
    >
      <Routes>

        <Route index path="/regulations" element={<Regulations />} />
        <Route path="/regulation/:regulationId" element={<RegulationSingle />} />
        <Route path="/yearsemesters" element={<YearSemester />} />
        <Route path="/branch" element={<Branch />} />
        <Route path="/topics" element={<Topics />} />
        <Route path="/subjects" element={<Subjects />} />
        <Route path="/subject/:branchCode/:branchId" element={<SubjectByBranch />} />        
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Suspense>
  );
});

export default withAdminLayout(Admin);