import react from 'react'
import ExcalidrawApp from '../../Excalidraw/excalidraw-app';
function DrawingTool() {
    return (
        <div>            
            <div className='canvas-size'>
                <ExcalidrawApp />
            </div>
        </div>
    )
}

export default DrawingTool 