const actions = {
 
  UNIT_STATUS_UPDATE_BEGIN: 'UNIT_STATUS_UPDATE_BEGIN',
  UNIT_STATUS_UPDATE_SUCCESS: 'UNIT_STATUS_UPDATE_SUCCESS',
  UNIT_STATUS_UPDATE_ERR: 'UNIT_STATUS_UPDATE_ERR',  
 
  SEND_UNIT_TO_HOD_BEGIN: 'SEND_UNIT_TO_HOD_BEGIN',
  SEND_UNIT_TO_HOD_SUCCESS: 'SEND_UNIT_TO_HOD_SUCCESS',
  SEND_UNIT_TO_HOD_ERR: 'SEND_UNIT_TO_HOD_ERR',  

  unitStatusUpdateBegin: () => {
    return {
      type: actions.UNIT_STATUS_UPDATE_BEGIN,
    };
  },

  unitStatusUpdateSuccess: (data) => {
    return {
      type: actions.UNIT_STATUS_UPDATE_SUCCESS,
      data,
    };
  },

  unitStatusUpdateErr: (err) => {
    return {
      type: actions.UNIT_STATUS_UPDATE_ERR,
      err,
    };
  },

  sendUnitToHodBegin: () => {
    return {
      type: actions.SEND_UNIT_TO_HOD_BEGIN,
    };
  },

  sendUnitToHodSuccess: (data) => {
    return {
      type: actions.SEND_UNIT_TO_HOD_SUCCESS,
      data,
    };
  },

  sendUnitToHodErr: (err) => {
    return {
      type: actions.SEND_UNIT_TO_HOD_ERR,
      err,
    };
  },

};

export default actions;
