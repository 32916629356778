import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getProgrammingById, getTesslatorDetails } from '../../redux/programming/actionCreator';
import { useParams } from 'react-router-dom';
import { Main } from '../styled';
import axios from 'axios';
import CustomSpinner from '../ui-elements/spinner/CustomSpinner';

const LaunchingTessellator = () => {
  const dispatch = useDispatch();
  const { topicId, programId } = useParams();

  const { TesselatorDetails, isTesselatordeatialsloading, Programs, ProgramsLoading } = useSelector((state) => ({
    TesselatorDetails: state.Tesselator.data,
    isTesselatordeatialsloading: state.Tesselator.loading,
    Programs: state.Programming.data,
    ProgramsLoading: state.Programming.loading,
  }));
  console.log('Tess-details', TesselatorDetails);

  useEffect(() => {
    dispatch(getProgrammingById(parseInt(topicId)));
  }, [topicId]);

  useEffect(() => {
    if (Programs.length !== 0) {
      dispatch(getTesslatorDetails());
    }
  }, [Programs]);
  // console.log('ProgramData:',Programs)

  const [formSubmitted, setFormSubmitted] = useState(false);
  const tscpLoginFormRef = useRef(null); // Add a ref for the form

  const selectedProgram = Programs.length !== 0 && Programs.find(program => program.programId === parseInt(programId));

  // console.log('Selected:',selectedProgram)

  useEffect(() => {
    if (TesselatorDetails !== null && Programs.length !== 0 && !formSubmitted) {
      const form = tscpLoginFormRef.current; // Get the form element from the ref
      if (form) {
        // console.log("launching...");
        form.submit();
        setFormSubmitted(true);
      }
    }
  }, [TesselatorDetails, Programs, formSubmitted]);
  const url = selectedProgram ? selectedProgram.programurl : '';
  const pathToRemove = "/mod/vpl/forms/edit.php";
  const pathToAdd = "/login/index.php";

  const baseURL = url.split(pathToRemove)[0] + pathToAdd;

  // console.log(baseURL);
  return (
    <Main>
      {isTesselatordeatialsloading || ProgramsLoading ? (
        <div className="custom-loading-spin-with-text">
          <CustomSpinner />
          <p>Loading please wait..</p>
        </div>
      ) : (
        <div className="message">
          {TesselatorDetails !== null && (
            <form
              ref={tscpLoginFormRef} // Attach the ref to the form
              method="post"
              action={baseURL}
              name="tscplogin"
            >
              <input type="hidden" name="username" value={TesselatorDetails.tessusername} />
              <input type="hidden" name="reqfrom" value="tesseract" />
              <input type="hidden" name="programurl" value={selectedProgram.programurl} /> {/* Pass the selected program's URL */}
              <input type="hidden" name="password" value={TesselatorDetails.tesspassword} />
            </form>

          )}
          <div className="custom-loading-spin-with-text">
            <CustomSpinner />
            <p>
              Launching Tessellator...
            </p>
          </div>

        </div>
      )}
    </Main>
  );
};

export default LaunchingTessellator;
