import react, { Suspense, useEffect, useState } from 'react'
import { Main } from '../styled'
import { Cards } from '../../components/cards/frame/cards-frame'
import LockUnlockLearningTopics from './LockUnlockLearningTopics'
import LockUnlockLAssessmenTopics from './LockUnlockAssessmentTopics'
import { Col, PageHeader, Row, Spin } from 'antd'
import { AddUser } from '../pages/style';
import { NavLink, Navigate, Route, Routes } from 'react-router-dom'

function LockUnlockTopics() {

    return (
        <div>
            <PageHeader className="ninjadash-page-header-main" ghost title="Lock/Unlock Topics" />
            <Main>
                <Row gutter={15}>
                    <Col xs={24}>
                        <AddUser>
                            <Cards
                                title={
                                    <div className="card-nav">
                                        <ul>
                                            <li>
                                                <NavLink to="./learning">
                                                    Learning
                                                </NavLink>
                                            </li>
                                            <li>
                                                <NavLink to="./assessment">
                                                    Assessment
                                                </NavLink>
                                            </li>
                                        </ul>
                                    </div>
                                }
                            >
                                <Suspense
                                    fallback={
                                        <div className="spin">
                                            <Spin />
                                        </div>
                                    }
                                >
                                    <Routes>
                                        <Route path="/" element={<Navigate to={`learning`} replace={true} />} />
                                        <Route path="learning" element={<LockUnlockLearningTopics />} />
                                        <Route path="assessment" element={<LockUnlockLAssessmenTopics />} />
                                    </Routes>
                                </Suspense>
                            </Cards>
                        </AddUser>
                    </Col>
                </Row>
            </Main>
        </div>
    )
}

export default LockUnlockTopics