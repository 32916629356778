import actions from './actions';
import { DataService } from '../../config/dataService/dataService';
import { message } from 'antd';
import { logOut } from '../authentication/actionCreator';
import { getItem } from '../../utility/localStorageControl';

const {

  unitStatusUpdateBegin,
  unitStatusUpdateSuccess,
  unitStatusUpdateErr,

  sendUnitToHodBegin,
  sendUnitToHodSuccess,
  sendUnitToHodErr,

} = actions;

const updateUnitStatusById = (formData) => {
  return async (dispatch) => {
    try {
      await dispatch(unitStatusUpdateBegin());
      const query = await DataService.put(`/unitmaster/qastatus`, formData);
      const token = getItem('access_token');
      const data = query.data
      if (token && data !== null && data.Error === false) {
        await dispatch(unitStatusUpdateSuccess(data));
      }
      else if (data !== null && data.Error === true) {
        if (Object.keys(data).length >= 3 && data.statusCode == 401) {
          message.error(data.message)
          await dispatch(unitStatusUpdateSuccess(null));
          dispatch(logOut())
        }
        else {
          await dispatch(unitStatusUpdateSuccess(null));
        }
      }
      else {
        await dispatch(unitStatusUpdateSuccess(null));
      }
    } catch (err) {
      await dispatch(unitStatusUpdateErr(err));
    }
  };
};


const sendUnitToHod = (data, msg) => {
  return async (dispatch) => {
    try {
      await dispatch(sendUnitToHodBegin());
      const query = await DataService.put(`/unitmaster/comment`, data);
      const token = getItem('access_token');
      if (token && query.data !== null && query.data.Error === false) {
        await dispatch(sendUnitToHodSuccess(query.data));
        if (msg) {
          message.success("Sent for Review")
        }
      } else {
        if (query.data.Error === true && query.data.statusCode === 401) {
          message.error(query.data.message)
          dispatch(logOut())
        }
        throw query.data.message;
      }
    } catch (err) {
      await dispatch(sendUnitToHodErr(err));
    }
  };
};


export {
  updateUnitStatusById,
  sendUnitToHod
};
