import { message } from 'antd';
import { DataService } from '../../config/dataService/dataService';
import { getItem } from '../../utility/localStorageControl';
import actions from './actions'
import { logOut } from '../authentication/actionCreator';

const {
    studentProgramsReadBegin,
    studentProgramsReadSuccess,
    studentProgramsReadErr,
} = actions

const getProgramstoAttempt = (topicId) => {
    return async (dispatch) => {
        try {
            await dispatch(studentProgramsReadBegin());
            const query = await DataService.get(`/telescope/programs/${topicId}`);
            const token = getItem('access_token');
            const data = query.data
            if (token && query.data !== null && query.data.Error === false) {
                await dispatch(studentProgramsReadSuccess(query.data.payload));
            }
            else if (data !== null && data.Error === true) {
                if (Object.keys(data).length >= 3 && data.statusCode == 401) {
                    message.error(data.message)
                    await dispatch(studentProgramsReadSuccess([]));
                    dispatch(logOut())
                }
                else {
                    await dispatch(studentProgramsReadSuccess([]));
                }
            }
            else {
                await dispatch(studentProgramsReadSuccess([]));
                // await dispatch(regulationAddErr('Unauthorized access'));
            }
        } catch (err) {
            await dispatch(studentProgramsReadErr(err));
        }
    };
};

export {
    getProgramstoAttempt
}