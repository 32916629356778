import actions from './actions';

const {
  
  UNIT_STATUS_UPDATE_BEGIN,
  UNIT_STATUS_UPDATE_SUCCESS,
  UNIT_STATUS_UPDATE_ERR,
  
  SEND_UNIT_TO_HOD_BEGIN,
  SEND_UNIT_TO_HOD_SUCCESS,
  SEND_UNIT_TO_HOD_ERR,

} = actions;


const unitStatusInitial = {
  data: null,
  loading: false,
  error: null,
};

const unitHodInitial = {
  data: null,
  loading: false,
  error: null,
};

const UnitStatusReducer = (state = unitStatusInitial, action) => {
  const { type, data, err } = action;
  switch (type) {
    case UNIT_STATUS_UPDATE_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case UNIT_STATUS_UPDATE_SUCCESS:
      return {
        ...state,
        data,
        error: false,
        loading: false,
      };

    case UNIT_STATUS_UPDATE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

const SendUnitToHodReducer = (state = unitHodInitial, action) => {
  const { type, data, err } = action;
  switch (type) {
    case SEND_UNIT_TO_HOD_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case SEND_UNIT_TO_HOD_SUCCESS:
      return {
        ...state,
        data,
        error: false,
        loading: false,
      };

    case SEND_UNIT_TO_HOD_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

export { UnitStatusReducer, SendUnitToHodReducer };
