import react, { useEffect } from 'react'
import { Cards } from '../../components/cards/frame/cards-frame'
import Heading from '../../components/heading/heading'
import { NoteCardWrap } from '../note/style'
import { useDispatch, useSelector } from 'react-redux'
import { getProgramstoAttempt } from '../../redux/studentsPrograms/actionCreator'
import { useParams } from 'react-router-dom'
import UilCheck from '@iconscout/react-unicons/icons/uil-check';
import { Button } from '../../components/buttons/buttons'
import CustomSpinner from '../ui-elements/spinner/CustomSpinner'
import { Result, Table } from 'antd'

function Programs({ topicName, learningFlag }) {

    const dispatch = useDispatch()
    const { topicId } = useParams()

    const { studentPrograms, isProgramsLoading } = useSelector((state) => {
        return {
            studentPrograms: state.studentPrograms.data,
            isProgramsLoading: state.studentPrograms.loading,
        }
    })

    useEffect(() => {
        if (topicId) {
            dispatch(getProgramstoAttempt(topicId))
        }
    }, [])


    const dataSource = studentPrograms.length !== 0 ? studentPrograms.sort((a, b) => a.programId > b.programId ? 1 : -1).map((program, index) => ({
        id: parseInt(program.programId),
        key: index,
        name: program.programName,
        topicname: program.topicname,
        programurl: program.programurl,
        score: program.score !== null ? program.score : '-',
        badge: program.badge,
        lastUpdated: program.updatedon !== null ? new Date(program.updatedon).toLocaleString() : '-',
    })) : [];


    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Grade',
            dataIndex: 'score',
            key: 'score',
        },
        {
            title: 'Last Updated',
            dataIndex: 'lastUpdated',
            key: 'lastUpdated',
        },
        {
            title: '',
            key: '',
            render: (text, record) => (
                record.score === 100 || record.badge === 2 ? (
                    <UilCheck color={'green'} />
                ) : (
                    <Button
                        type="primary"
                        onClick={() => openUrlInNewWindow(`${window.location.href}/${record.id}`)}
                    >
                        Attempt
                    </Button>
                )
            ),
        }

    ];

    const openUrlInNewWindow = (url) => {
        window.open(url, '_blank');
    };

    return (
        <div>
            <Cards headless>
                <Heading as={'h3'} className='text-center mb-25'>{topicName}</Heading>
                <NoteCardWrap>
                    <div className='text-right pb-10'>
                        <Button
                            className='p-0 m-0'
                            type="link"
                            onClick={() => { dispatch(getProgramstoAttempt(parseInt(topicId))) }}
                        >
                            Refresh
                        </Button>
                        <span> the page to get latest results </span>
                    </div>
                    {
                        isProgramsLoading &&
                        <div className="custom-loading-spin-with-text">
                            <CustomSpinner Message={'Loading Programs...'} />
                        </div>
                    }
                    {
                        !learningFlag ?
                            <div className='custom-spin'>
                                <Result
                                    status={'warning'}
                                    title="Programs are locked by your teacher"
                                />
                            </div>
                            :
                            !isProgramsLoading && dataSource.length !== 0 ?
                                <div className='table-responsive ml-10'>
                                    <Table
                                        dataSource={dataSource}
                                        columns={columns}
                                        pagination={false}
                                    />
                                </div>
                                :
                                !isProgramsLoading &&
                                < Result
                                    status="warning"
                                    title="No Programs for this topic"
                                />
                    }
                </NoteCardWrap>
            </Cards>
        </div>
    )
}


export default Programs