import {
  UilCreateDashboard,
  UilArrowGrowth,
} from '@iconscout/react-unicons';
import { Menu } from 'antd';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

import UilEllipsisV from '@iconscout/react-unicons/icons/uil-ellipsis-v';
import propTypes from 'prop-types';
// import { NavTitle } from './Style';
// import { changeDirectionMode, changeLayoutMode, changeMenuMode } from '../redux/themeLayout/actionCreator';
import { regulationDataRead } from '../redux/regulation/actionCreator';
// import SubMenu from 'antd/lib/menu/SubMenu';
import { yearsemesterDataRead } from '../redux/yearsemester/actionCreator';
import { branchDataRead } from '../redux/branch/actionCreator';
import { subjectsDataRead } from '../redux/subjects/actionCreator';

function MenuItems({ toggleCollapsed }) {

  function getItem(label, key, icon, children, type) {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  }

  const { topMenu, Branches } = useSelector((state) => {
    return {
      topMenu: state.ChangeLayoutMode.topMenu,
      Regulations: state.Regulations.data,
      YearSemester: state.YearSemester.data,
      Branches: state.Branches.data,
      Subjects: state.Subjects.data,
      isLoading: state.Regulations.loading,
    };
  });

  const dispatch = useDispatch();



  useEffect(() => {
    if (dispatch) {
      dispatch(regulationDataRead());
    }
  }, [dispatch]);

  useEffect(() => {
    if (dispatch) {
      dispatch(yearsemesterDataRead());
    }
  }, [dispatch]);

  useEffect(() => {
    if (dispatch) {
      dispatch(branchDataRead());
    }
  }, [dispatch]);

  useEffect(() => {
    if (dispatch) {
      dispatch(subjectsDataRead());
    }
  }, [dispatch]);


  const path = '/admin';

  const pathName = window.location.pathname;
  const pathArray = pathName.split(path);
  const mainPath = pathArray[1];
  const mainPathSplit = mainPath.split('/');

  const [openKeys, setOpenKeys] = React.useState(
    !topMenu ? [`${mainPathSplit.length > 1 ? mainPathSplit[1] : 'regulation'}`] : [],
  );

  const onOpenChange = (keys) => {
    setOpenKeys(keys[keys.length - 1] !== 'recharts' ? [keys.length && keys[keys.length - 1]] : keys);
  };

  const onClick = (item) => {
    if (item.keyPath.length === 1) setOpenKeys([]);
  };

  // const changeLayout = (mode) => {
  //   dispatch(changeLayoutMode(mode));
  // };
  // const changeNavbar = (topMode) => {
  //   const html = document.querySelector('html');
  //   if (topMode) {
  //     html.classList.add('ninjadash-topmenu');
  //   } else {
  //     html.classList.remove('ninjadash-topmenu');
  //   }
  //   dispatch(changeMenuMode(topMode));
  // };
  // const changeLayoutDirection = (rtlMode) => {
  //   if (rtlMode) {
  //     const html = document.querySelector('html');
  //     html.setAttribute('dir', 'rtl');
  //   } else {
  //     const html = document.querySelector('html');
  //     html.setAttribute('dir', 'ltr');
  //   }
  //   dispatch(changeDirectionMode(rtlMode));
  // };

  // const darkmodeActivated = () => {
  //   document.body.classList.add('dark-mode');
  // };

  // const darkmodeDiactivated = () => {
  //   document.body.classList.remove('dark-mode');
  // };


  const items = [
    getItem(
      <NavLink onClick={toggleCollapsed} to={`${path}/regulations`}>
        Regulations
      </NavLink>,
      'regulations',
      !topMenu && <UilArrowGrowth />,
    ),
    getItem(
      <NavLink onClick={toggleCollapsed} to={`${path}/yearsemesters`}>
        Year Semesters
      </NavLink>,
      'yearsemesters',
      !topMenu && <UilArrowGrowth />,
    ),
    // getItem(<NavLink to={`${path}/yearsemester`}>Year Semester</NavLink>, 'yearsemester', !topMenu && <UilCreateDashboard />, 
    //   YearSemester.length !== 0 && YearSemester.map(Obj =>
    //     getItem(
    //         <NavLink onClick={toggleCollapsed} to={`${path}/yearsemester/${Obj.id}`}>
    //             {Obj.code}
    //         </NavLink>,
    //         Obj.id,
    //         null            
    //     )
    //   )          
    // ),
    getItem(<NavLink to={`${path}/branch`}>Branch</NavLink>, 'branch', !topMenu && <UilCreateDashboard />,
      Branches.length !== 0 && Branches.map(Obj =>
        getItem(
          <NavLink onClick={toggleCollapsed} to={`${path}/branch/${Obj.id}`}>
            {Obj.code}
          </NavLink>,
          Obj.id,
          null
        )
      )
    ),
    getItem(<NavLink to={`${path}/subjects`}>Subjects</NavLink>, 'subjects', !topMenu && <UilCreateDashboard />,
      Branches.length !== 0 && Branches.map(Obj =>
        getItem(
          <NavLink onClick={toggleCollapsed} to={`${path}/subject/${Obj.code}/${Obj.id}`}>
            {Obj.code}
          </NavLink>,
          Obj.id,
          null
        )
      )
    ),
    // getItem(<NavLink onClick={toggleCollapsed} to={`${path}/topics`}>{t('Topics')}</NavLink>, 'Topics', !topMenu && <UilCreateDashboard />, [
    //   getItem(
    //     <NavLink onClick={toggleCollapsed} to={`${path}/topics/cp`}>
    //       {t('CP')}
    //     </NavLink>,
    //     'CP',
    //     null,
    //   ),
    //   getItem(
    //     <NavLink onClick={toggleCollapsed} to={`${path}/topics/cc`}>
    //       {t('CC')}
    //     </NavLink>,
    //     'CC',
    //     null,
    //   ),
    // ]),

    // getItem(<NavLink onClick={toggleCollapsed} to={`${path}/subjects`}>{t('Subjects')}</NavLink>, 'Subjects', !topMenu && <UilCreateDashboard />, [
    //   getItem(
    //     <NavLink onClick={toggleCollapsed} to={`${path}/subjects/cse`}>
    //       {t('CSE')}
    //     </NavLink>,
    //     'CSE',
    //     null,
    //   ),
    //   getItem(
    //     <NavLink onClick={toggleCollapsed} to={`${path}/subjects/it`}>
    //       {t('IT')}
    //     </NavLink>,
    //     'IT',
    //     null,
    //   ),
    //   getItem(
    //     <NavLink onClick={toggleCollapsed} to={`${path}/subjects/csm`}>
    //       {t('CSM')}
    //     </NavLink>,
    //     'CSM',
    //     null,
    //   ),
    //   getItem(
    //     <NavLink onClick={toggleCollapsed} to={`${path}/subjects/csd`}>
    //       {t('CSD')}
    //     </NavLink>,
    //     'CSD',
    //     null,
    //   ),
    // ]),
    // getItem(<NavLink onClick={toggleCollapsed} to={`${path}/teachers`}>{t('Teachers')}</NavLink>, 'Teachers', !topMenu && <UilCreateDashboard />, [
    //   getItem(
    //     <NavLink onClick={toggleCollapsed} to={`${path}/techers/cse`}>
    //       {t('CSE')}
    //     </NavLink>,
    //     'CSE',
    //     null,
    //   ),
    //   getItem(
    //     <NavLink onClick={toggleCollapsed} to={`${path}/teachers/it`}>
    //       {t('IT')}
    //     </NavLink>,
    //     'IT',
    //     null,
    //   ),
    //   getItem(
    //     <NavLink onClick={toggleCollapsed} to={`${path}/teachers/csm`}>
    //       {t('CSM')}
    //     </NavLink>,
    //     'CSM',
    //     null,
    //   ),
    //   getItem(
    //     <NavLink onClick={toggleCollapsed} to={`${path}/teachers/csd`}>
    //       {t('CSD')}
    //     </NavLink>,
    //     'CSD',
    //     null,
    //   ),
    // ]),
    // getItem(
    //   <NavLink onClick={toggleCollapsed} to={`${path}/topics`}>
    //     {t('Topics')}
    //   </NavLink>,
    //   'Topics',
    //   null,
    // ),
  ];

  return (
    <Menu
      onOpenChange={onOpenChange}
      onClick={onClick}
      mode={!topMenu || window.innerWidth <= 991 ? 'inline' : 'horizontal'}
      // // eslint-disable-next-line no-nested-ternary
      defaultSelectedKeys={
        !topMenu
          ? [
            `${mainPathSplit.length === 1 ? 'regulations' : mainPathSplit.length === 2 ? mainPathSplit[1] : mainPathSplit.length > 2 ? mainPathSplit[2] : ''
            }`,
          ]
          : []
      }
      defaultOpenKeys={!topMenu ? [`${mainPathSplit.length === 2 ? mainPathSplit[1] : mainPathSplit.length > 2 ? mainPathSplit[2] : ''}`] : []}
      overflowedIndicator={<UilEllipsisV />}
      openKeys={openKeys}
      items={items}
    />
  );
}

MenuItems.propTypes = {
  toggleCollapsed: propTypes.func,
};

export default MenuItems;
