import React, { useState, useEffect } from 'react';
import { Row, Col, Table, Input, Modal, Form, Space, Select, Tag, message } from 'antd';
import UilEdit from '@iconscout/react-unicons/icons/uil-edit';
import UilTrash from '@iconscout/react-unicons/icons/uil-trash-alt';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import {UserTableStyleWrapper} from './styles/tablestyle'
import { Cards } from '../../components/cards/frame/cards-frame';
import { TableWrapper, Main } from '../styled';
import { PageHeader } from '../../components/page-headers/page-headers';
import { branchDataRead, branchDataSubmit } from '../../redux/branch/actionCreator';
import { regulationDataRead } from '../../redux/regulation/actionCreator';
import { yearsemesterDataRead } from '../../redux/yearsemester/actionCreator';
import { useDispatch, useSelector } from 'react-redux';
import { ProjectList } from '../project/style';
import { Button } from '../../components/buttons/buttons';

const { Option } = Select;

function Branch() {

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [form] = Form.useForm();
  const [editForm] = Form.useForm();

  const [editRecord, setEditRecord] = useState(null);

  const dispatch = useDispatch();
  const { YearSemester,Branches,Regulations, isLoading } = useSelector((state) => {
    return {
      YearSemester: state.YearSemester.data,
      Branches: state.Branches.data,
      Regulations: state.Regulations.data,
      isLoading: state.Branches.loading,
    };
  }); 

  useEffect(() => {
    if (dispatch) {
      dispatch(branchDataRead());
      dispatch(regulationDataRead());
      dispatch(yearsemesterDataRead());
    }
  }, [dispatch]);

  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(()=> {
    if(Branches){
      setDataSource(Branches)
      setLoading(false);
    }
  },[Branches])

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Code',
      dataIndex: 'code',
      key: 'code',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Created On',
      dataIndex: 'createdon',
      key: 'createdon',
    },
    {
      title: 'Updated On',
      dataIndex: 'updatedon',
      key: 'updatedon',
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (text, record) => (
        <div className='table-actions'>
          <Button onClick={() => handleEdit(record)} className="btn-icon" transparented type="primary" to="#" shape="circle">
            <UilEdit />
          </Button>
          <Button onClick={() => handleDelete(record)} className="btn-icon" transparented type="danger" to="#" shape="circle" style={{marginLeft:'10px'}}>
            <UilTrash />
          </Button>          
        </div>
      ),
    },
  ];

  const handleButtonClick = () => {
    setIsModalVisible(true);
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
  };

  const handleModalAdd = () => {
    form.validateFields().then((values) => {
        const newRow = {
          // key: String(dataSource.length + 1),
          code: values.code,
          name: values.name,
          yearsem: values.yearsemesterId,
        };
        setDataSource([...dataSource, newRow]);
        form.resetFields();
        setIsModalVisible(false);
        dispatch(branchDataSubmit(newRow));
    });
  };

  const handleModalUpdate = () => {
    editForm.validateFields().then((values) => {
      const updatedRecord = {
        ...editRecord,
        code: values.code,
        name: values.name,
      };

      const updatedDataSource = dataSource.map((item) =>
        item.id === updatedRecord.id ? updatedRecord : item
      );

      setDataSource(updatedDataSource);
      setIsEditModalVisible(false);
    });
  };

  const handleEdit = (record) => {
    setEditRecord(record);
    setIsEditModalVisible(true);
    editForm.setFieldsValue({
      code: record.code,
      name: record.name,
    });
  };

  const handleDelete = (record) => {
    Modal.confirm({
      title: 'Are you sure you want to delete this record?',
      icon: <ExclamationCircleOutlined />,
      content: 'Once deleted the action can be undone',
      okText: 'Delete',
      okType: 'danger',
      cancelText: 'Cancel',
      onOk() {
        const updatedDataSource = dataSource.filter((item) => item.key !== record.key);
        setDataSource(updatedDataSource);
      },
    });
  };

  const showEditModal = (record) => {
    handleEdit(record);
  };

  return (
    <>
    <PageHeader className="ninjadash-page-header-main" title="Branches" 
      buttons={[
        <Button onClick={handleButtonClick} key="1" type="primary" size="default">
          Add Branch
        </Button>,
      ]}/>      
      <Main>
        <Row gutter={25}>
          <Col sm={24} xs={24}>
            <Cards headless>
            <ProjectList>
            <div className="table-responsive">
                  <Table dataSource={dataSource} columns={columns} />
                </div>
              </ProjectList>
            </Cards>
          </Col>
        </Row>        
      </Main>
      <Modal
        title="Add Branch"
        open={isModalVisible}
        onCancel={handleModalCancel}
        footer={[
          <Button key="add" type="primary" onClick={handleModalAdd}>
            Add
          </Button>,
        ]}
      >
        <Form form={form} layout="vertical">
          <Form.Item name="code" label="Code" rules={[{ required: true, message: 'Please enter Subject Code' }]}>
            <Input/>
          </Form.Item>
          <Form.Item name="name" label="Name" rules={[{ required: true }]}>
            <Input/>
          </Form.Item>
          <Form.Item name="regulationId" label="Regulation" rules={[{ required: true }]}>
            <Select placeholder="Select Regulation">
              {Regulations.length !==0 && Regulations.map((regulation) => (
                <Option key={regulation.code} value={regulation.id}>
                  {regulation.code}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name="yearsemesterId" label="Year-Semester" rules={[{ required: true }]}>
            <Select placeholder="Select Year-Semester">
              {YearSemester.length !==0 && YearSemester.map((yearsemester) => (
                <Option key={yearsemester.code} value={yearsemester.id}>
                  {yearsemester.code}
                </Option>
              ))}
            </Select>
          </Form.Item>
          {/* <Form.Item name="status" label="Status" >
            <Select defaultValue="Active">
              <Option value="active">Active</Option>
              <Option value="inactive">Inactive</Option>
            </Select>
          </Form.Item>
          <Form.Item name="yearsemester" label="Year-Semester" rules={[{ required: true  }]}>
            <Select placeholder="Select Year-Semester">
                <Option value="I - I">I - I</Option>
                <Option value="I - II">I - II</Option>
            </Select>
          </Form.Item> */}
        </Form>
      </Modal>
      <Modal
        title="Edit Branch"
        visible={isEditModalVisible}
        onCancel={() => setIsEditModalVisible(false)}
        footer={[
          <Button key="update" type="primary" onClick={handleModalUpdate}>
            Update
          </Button>
        ]}
      >
        <Form form={editForm} layout="vertical">
        <Form.Item name="code" label="Code" rules={[{ required: true }]}>
            <Input/>
          </Form.Item>
          <Form.Item name="name" label="Name" rules={[{ required: true }]}>
            <Input/>
          </Form.Item>
          {/* <Form.Item name="status" label="Status" rules={[{ required: true }]}>
            <Select>
              <Option value="active">Active</Option>
              <Option value="inactive">Inactive</Option>
            </Select>
          </Form.Item>
          <Form.Item name="yearsemester" label="Year-Semester" rules={[{ required: true }]}>
            <Select>
                <Option value="I - I">I - I</Option>
                <Option value="I - II">I - II</Option>
            </Select>
          </Form.Item> */}
        </Form>
      </Modal>
    </>
  );
}

export default Branch;