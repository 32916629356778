const actions = {
  GENERATE_QUESTIONS_ADD_BEGIN: 'GENERATE_QUESTIONS_ADD_BEGIN',
  GENERATE_QUESTIONS_ADD_SUCCESS: 'GENERATE_QUESTIONS_ADD_SUCCESS',
  GENERATE_QUESTIONS_ADD_ERR: 'GENERATE_QUESTIONS_ADD_ERR',

  GENERATE_QUESTIONS_READ_BEGIN: 'GENERATE_QUESTIONS_READ_BEGIN',
  GENERATE_QUESTIONS_READ_SUCCESS: 'GENERATE_QUESTIONS_READ_SUCCESS',
  GENERATE_QUESTIONS_READ_ERR: 'GENERATE_QUESTIONS_READ_ERR',

  QA_UPDATE_BEGIN: 'QA_UPDATE_BEGIN',
  QA_UPDATE_SUCCESS: 'QA_UPDATE_SUCCESS',
  QA_UPDATE_ERR: 'QA_UPDATE_ERR',

  READ_QUESTIONS_BEGIN: 'READ_QUESTIONS_BEGIN',
  READ_QUESTIONS_SUCCESS: 'READ_QUESTIONS_SUCCESS',
  READ_QUESTIONS_ERR: 'READ_QUESTIONS_ERR',

  DELETE_QA_BEGIN: 'DELETE_QA_BEGIN',
  DELETE_QA_SUCCESS: 'DELETE_QA_SUCCESS',
  DELETE_QA_ERR: 'DELETE_QA_ERR',

  ANSWER_EVALUATE_BEGIN: 'ANSWER_EVALUATE_BEGIN',
  ANSWER_EVALUATE_SUCCESS: 'ANSWER_EVALUATE_SUCCESS',
  ANSWER_EVALUATE_ERR: 'ANSWER_EVALUATE_ERR',

  GET_ATTEMPTED_QA_BEGIN: 'GET_ATTEMPTED_QA_BEGIN',
  GET_ATTEMPTED_QA_SUCCESS: 'GET_ATTEMPTED_QA_SUCCESS',
  GET_ATTEMPTED_QA_ERR: 'GET_ATTEMPTED_QA_ERR',

  UNIT_WISE_QA_GENERATION_BEGIN: 'UNIT_WISE_QA_GENERATION_BEGIN',
  UNIT_WISE_QA_GENERATION_SUCCESS: 'UNIT_WISE_QA_GENERATION_SUCCESS',
  UNIT_WISE_QA_GENERATION_ERR: 'UNIT_WISE_QA_GENERATION_ERR',

  UNIT_WISE_QA_READ_BEGIN: 'UNIT_WISE_QA_READ_BEGIN',
  UNIT_WISE_QA_READ_SUCCESS: 'UNIT_WISE_QA_READ_SUCCESS',
  UNIT_WISE_QA_READ_ERR: 'UNIT_WISE_QA_READ_ERR',
  
  UNIT_QA_UPDATE_BEGIN: 'UNIT_QA_UPDATE_BEGIN',
  UNIT_QA_UPDATE_SUCCESS: 'UNIT_QA_UPDATE_SUCCESS',
  UNIT_QA_UPDATE_ERR: 'UNIT_QA_UPDATE_ERR',
  
  DELETE_UNIT_QA_BEGIN: 'DELETE_UNIT_QA_BEGIN',
  DELETE_UNIT_QA_SUCCESS: 'DELETE_UNIT_QA_SUCCESS',
  DELETE_UNIT_QA_ERR: 'DELETE_UNIT_QA_ERR',
  
  ADD_NEW_QUESTION_BEGIN: 'ADD_NEW_QUESTION_BEGIN',
  ADD_NEW_QUESTION_SUCCESS: 'ADD_NEW_QUESTION_SUCCESS',
  ADD_NEW_QUESTION_ERR: 'ADD_NEW_QUESTION_ERR',
  
  GET_UNIT_ATTEMPTED_QA_BEGIN: 'GET_UNIT_ATTEMPTED_QA_BEGIN',
  GET_UNIT_ATTEMPTED_QA_SUCCESS: 'GET_UNIT_ATTEMPTED_QA_SUCCESS',
  GET_UNIT_ATTEMPTED_QA_ERR: 'GET_UNIT_ATTEMPTED_QA_ERR',

  ADD_EVALUATION_FEEDBACK_BEGIN: 'ADD_EVALUATION_FEEDBACK_BEGIN',
  ADD_EVALUATION_FEEDBACK_SUCCESS: 'ADD_EVALUATION_FEEDBACK_SUCCESS',
  ADD_EVALUATION_FEEDBACK_ERR: 'ADD_EVALUATION_FEEDBACK_ERR',

  generateQuestionsPostBegin: () => {
    return {
      type: actions.GENERATE_QUESTIONS_ADD_BEGIN,
    };
  },

  generateQuestionsPostSuccess: (data) => {
    return {
      type: actions.GENERATE_QUESTIONS_ADD_SUCCESS,
      data,
    };
  },

  generateQuestionsPostErr: (err) => {
    return {
      type: actions.GENERATE_QUESTIONS_ADD_ERR,
      err,
    };
  },

  generateQuestionsReadBegin: () => {
    return {
      type: actions.GENERATE_QUESTIONS_READ_BEGIN,
    };
  },

  generateQuestionsReadSuccess: (data) => {
    return {
      type: actions.GENERATE_QUESTIONS_READ_SUCCESS,
      data,
    };
  },

  generateQuestionsReadErr: (err) => {
    return {
      type: actions.GENERATE_QUESTIONS_READ_ERR,
      err,
    };
  },

  QAUpdateBegin: () => {
    return {
      type: actions.QA_UPDATE_BEGIN,
    };
  },

  QAUpdateSuccess: (data) => {
    return {
      type: actions.QA_UPDATE_SUCCESS,
      data,
    };
  },

  QAUpdateErr: (err) => {
    return {
      type: actions.QA_UPDATE_ERR,
      err,
    };
  },

  getQuestionsBegin: () => {
    return {
      type: actions.READ_QUESTIONS_BEGIN,
    };
  },

  getQuestionsSuccess: (data) => {
    return {
      type: actions.READ_QUESTIONS_SUCCESS,
      data,
    };
  },

  getQuestionsErr: (err) => {
    return {
      type: actions.READ_QUESTIONS_ERR,
      err,
    };
  },



  deleteQABegin: () => {
    return {
      type: actions.DELETE_QA_BEGIN,
    };
  },

  deleteQASuccess: (data) => {
    return {
      type: actions.DELETE_QA_SUCCESS,
      data,
    };
  },

  deleteQAErr: (err) => {
    return {
      type: actions.DELETE_QA_ERR,
      err,
    };
  },



  answerEvaluateBegin: () => {
    return {
      type: actions.ANSWER_EVALUATE_BEGIN,
    };
  },

  answerEvaluateSuccess: (data) => {
    return {
      type: actions.ANSWER_EVALUATE_SUCCESS,
      data,
    };
  },

  answerEvaluateErr: (err) => {
    return {
      type: actions.ANSWER_EVALUATE_ERR,
      err,
    };
  },


  getAttemptedQABegin: () => {
    return {
      type: actions.GET_ATTEMPTED_QA_BEGIN,
    };
  },

  getAttemptedQASuccess: (data) => {
    return {
      type: actions.GET_ATTEMPTED_QA_SUCCESS,
      data,
    };
  },

  getAttemptedQAErr: (err) => {
    return {
      type: actions.GET_ATTEMPTED_QA_ERR,
      err,
    };
  },

  generateUnitWiseQABegin: () => {
    return {
      type: actions.UNIT_WISE_QA_GENERATION_BEGIN,
    };
  },

  generateUnitWiseQASuccess: (data) => {
    return {
      type: actions.UNIT_WISE_QA_GENERATION_SUCCESS,
      data,
    };
  },

  generateUnitWiseQAErr: (err) => {
    return {
      type: actions.UNIT_WISE_QA_GENERATION_ERR,
      err,
    };
  },

  UnitWiseQAReadBegin: () => {
    return {
      type: actions.UNIT_WISE_QA_READ_BEGIN,
    };
  },

  UnitWiseQAReadSuccess: (data) => {
    return {
      type: actions.UNIT_WISE_QA_READ_SUCCESS,
      data,
    };
  },

  UnitWiseQAReadErr: (err) => {
    return {
      type: actions.UNIT_WISE_QA_READ_ERR,
      err,
    };
  },

  UnitQAUpdateBegin: () => {
    return {
      type: actions.UNIT_QA_UPDATE_BEGIN,
    };
  },

  UnitQAUpdateSuccess: (data) => {
    return {
      type: actions.UNIT_QA_UPDATE_SUCCESS,
      data,
    };
  },

  UnitQAUpdateErr: (err) => {
    return {
      type: actions.UNIT_QA_UPDATE_ERR,
      err,
    };
  },

  deleteUnitQABegin: () => {
    return {
      type: actions.DELETE_UNIT_QA_BEGIN,
    };
  },

  deleteUnitQASuccess: (data) => {
    return {
      type: actions.DELETE_UNIT_QA_SUCCESS,
      data,
    };
  },

  deleteUnitQAErr: (err) => {
    return {
      type: actions.DELETE_UNIT_QA_ERR,
      err,
    };
  },

  addNewQuestionBegin: () => {
    return {
      type: actions.ADD_NEW_QUESTION_BEGIN,
    };
  },

  addNewQuestionSuccess: (data) => {
    return {
      type: actions.ADD_NEW_QUESTION_SUCCESS,
      data,
    };
  },

  addNewQuestionErr: (err) => {
    return {
      type: actions.ADD_NEW_QUESTION_ERR,
      err,
    };
  },

  getUnitAttemptedQABegin: () => {
    return {
      type: actions.GET_UNIT_ATTEMPTED_QA_BEGIN,
    };
  },

  getUnitAttemptedQASuccess: (data) => {
    return {
      type: actions.GET_UNIT_ATTEMPTED_QA_SUCCESS,
      data,
    };
  },

  getUnitAttemptedQAErr: (err) => {
    return {
      type: actions.GET_UNIT_ATTEMPTED_QA_ERR,
      err,
    };
  },

  addEvaluationFeedbackBegin: () => {
    return {
      type: actions.ADD_EVALUATION_FEEDBACK_BEGIN,
    };
  },

  addEvaluationFeedbackSuccess: (data) => {    
    return {
      type: actions.ADD_EVALUATION_FEEDBACK_SUCCESS,      
      data,
    };
  },

  addEvaluationFeedbackErr: (err) => {
    return {
      type: actions.ADD_EVALUATION_FEEDBACK_ERR,
      err,
    };
  },

};

export default actions;
