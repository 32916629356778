import actions from './actions';
import { DataService } from '../../config/dataService/dataService';
import { message } from 'antd';
import { logOut } from '../authentication/actionCreator';
import { getItem } from '../../utility/localStorageControl';

const {
  tessellatorTopicsReadBegin,
  tessellatorTopicsReadSuccess,
  tessellatorTopicsReadErr,

  tessellatorUnitReadBegin,
  tessellatorUnitReadSuccess,
  tessellatorUnitReadErr,

  tessellatorTopicProgramsReadBegin,
  tessellatorTopicProgramsReadSuccess,
  tessellatorTopicProgramsReadErr,

  tessellatorUnitProgramsReadBegin,
  tessellatorUnitProgramsReadSuccess,
  tessellatorUnitProgramsReadErr,

  mapTessellatorTopicUpdateBegin,
  mapTessellatorTopicUpdateSuccess,
  mapTessellatorTopicUpdateErr,

  mapTessellatorUnitUpdateBegin,
  mapTessellatorUnitUpdateSuccess,
  mapTessellatorUnitUpdateErr,

  mapTessellatorTopicToNullUpdateBegin,
  mapTessellatorTopicToNullUpdateSuccess,
  mapTessellatorTopicToNullUpdateErr,

  mapTessellatorUnitToNullUpdateBegin,
  mapTessellatorUnitToNullUpdateSuccess,
  mapTessellatorUnitToNullUpdateErr,

  telescopeTopicsReadBegin,
  telescopeTopicsReadSuccess,
  telescopeTopicsReadErr,
  
  telescopeTopicProgramsReadBegin,
  telescopeTopicProgramsReadSuccess,
  telescopeTopicProgramsReadErr,

  mapTelescopeTopicUpdateBegin,
  mapTelescopeTopicUpdateSuccess,
  mapTelescopeTopicUpdateErr,
  
  mapTelescopeTopicToNullUpdateBegin,
  mapTelescopeTopicToNullUpdateSuccess,
  mapTelescopeTopicToNullUpdateErr,

} = actions;

const TessellatorTopicsDataRead = (subjectId) => {
  return async (dispatch) => {
    try {
      await dispatch(tessellatorTopicsReadBegin());
      const query = await DataService.get(`/subjectmaster/topicstscp/${subjectId}`);
      const token = getItem('access_token');
      const data = query.data
      if (token && data !== null && data.Error === false) {
        await dispatch(tessellatorTopicsReadSuccess(data.payload));
      }
      else if (data !== null && data.Error === true) {
        if (Object.keys(data).length >= 3 && data.statusCode == 401) {
          message.error(data.message)
          await dispatch(tessellatorTopicsReadSuccess([]));
          dispatch(logOut())
        }
        else {
          await dispatch(tessellatorTopicsReadSuccess([]));
        }
      }
      else {
        await dispatch(tessellatorTopicsReadSuccess([]));
        // await dispatch(tessellatorTopicsAddErr('Unauthorized access'));
      }
    } catch (err) {
      await dispatch(tessellatorTopicsReadErr(err));
    }
  };
};

const TessellatorUnitDataRead = (subjectId) => {
  return async (dispatch) => {
    try {
      await dispatch(tessellatorUnitReadBegin());
      const query = await DataService.get(`/subjectmaster/topicstscp/${subjectId}`);
      const token = getItem('access_token');
      const data = query.data
      if (token && data !== null && data.Error === false) {
        await dispatch(tessellatorUnitReadSuccess(data.payload));
      }
      else if (data !== null && data.Error === true) {
        if (Object.keys(data).length >= 3 && data.statusCode == 401) {
          message.error(data.message)
          await dispatch(tessellatorUnitReadSuccess([]));
          dispatch(logOut())
        }
        else {
          await dispatch(tessellatorUnitReadSuccess([]));
        }
      }
      else {
        await dispatch(tessellatorUnitReadSuccess([]));
        // await dispatch(tessellatorTopicsAddErr('Unauthorized access'));
      }
    } catch (err) {
      await dispatch(tessellatorUnitReadErr(err));
    }
  };
};

const TessellatorTopicProgramsDataRead = (topicId) => {
  return async (dispatch) => {
    try {
      await dispatch(tessellatorTopicProgramsReadBegin());
      const query = await DataService.get(`/tssrprograms/${topicId}`);
      const token = getItem('access_token');
      const data = query.data
      if (token && data !== null && data.Error === false) {
        await dispatch(tessellatorTopicProgramsReadSuccess(data.payload));
      }
      else if (data !== null && data.Error === true) {
        if (Object.keys(data).length >= 3 && data.statusCode == 401) {
          message.error(data.message)
          await dispatch(tessellatorTopicProgramsReadSuccess([]));
          dispatch(logOut())
        }
        else {
          await dispatch(tessellatorTopicProgramsReadSuccess([]));
        }
      }
      else {
        await dispatch(tessellatorTopicProgramsReadSuccess([]));
      }
    } catch (err) {
      await dispatch(tessellatorTopicProgramsReadErr(err));
    }
  };
};

const TessellatorUnitProgramsDataRead = (tesunitId) => {
  return async (dispatch) => {
    try {
      await dispatch(tessellatorUnitProgramsReadBegin());
      const query = await DataService.get(`/unittssrprograms/${tesunitId}`);
      const token = getItem('access_token');
      const data = query.data
      if (token && data !== null && data.Error === false) {
        await dispatch(tessellatorUnitProgramsReadSuccess(data.payload));
      }
      else if (data !== null && data.Error === true) {
        if (Object.keys(data).length >= 3 && data.statusCode == 401) {
          message.error(data.message)
          await dispatch(tessellatorUnitProgramsReadSuccess([]));
          dispatch(logOut())
        }
        else {
          await dispatch(tessellatorUnitProgramsReadSuccess([]));
        }
      }
      else {
        await dispatch(tessellatorUnitProgramsReadSuccess([]));
      }
    } catch (err) {
      await dispatch(tessellatorUnitProgramsReadErr(err));
    }
  };
};

// update
const MapTessellatorTopic = (topicId, testTopicId) => {
  return async (dispatch) => {
    try {
      await dispatch(mapTessellatorTopicUpdateBegin());
      const query = await DataService.put(`/topicmaster/tesstopicupdate/${topicId}/${testTopicId}`);
      const token = getItem('access_token');
      const data = query.data
      if (token && data !== null && data.Error === false) {
        await dispatch(mapTessellatorTopicUpdateSuccess(data));
      }
      else if (data !== null && data.Error === true) {
        if (Object.keys(data).length >= 3 && data.statusCode == 401) {
          message.error(data.message)
          await dispatch(mapTessellatorTopicUpdateSuccess(null));
          dispatch(logOut())
        }
        else {
          await dispatch(mapTessellatorTopicUpdateSuccess(null));
        }
      }
      else {
        // unauthorize
        await dispatch(mapTessellatorTopicUpdateSuccess(null));
      }
    } catch (err) {
      await dispatch(mapTessellatorTopicUpdateErr(err));
    }
  };
};

// update
const MapTessellatorUnit = (unitId, testUnitId) => {
  return async (dispatch) => {
    try {
      await dispatch(mapTessellatorUnitUpdateBegin());
      const query = await DataService.put(`/unitmaster/tessunitupdate/${unitId}/${testUnitId}`);
      const token = getItem('access_token');
      const data = query.data
      if (token && data !== null && data.Error === false) {
        await dispatch(mapTessellatorUnitUpdateSuccess(data));
      }
      else if (data !== null && data.Error === true) {
        if (Object.keys(data).length >= 3 && data.statusCode == 401) {
          message.error(data.message)
          await dispatch(mapTessellatorUnitUpdateSuccess(null));
          dispatch(logOut())
        }
        else {
          await dispatch(mapTessellatorUnitUpdateSuccess(null));
        }
      }
      else {
        // unauthorize
        await dispatch(mapTessellatorUnitUpdateSuccess(null));
      }
    } catch (err) {
      await dispatch(mapTessellatorUnitUpdateErr(err));
    }
  };
};

const MapTessellatorTopicToNull = (topicId) => {
  return async (dispatch) => {
    try {
      await dispatch(mapTessellatorTopicToNullUpdateBegin());
      const query = await DataService.put(`/topicmaster/tesstopicupdatetonull/${topicId}`);
      const token = getItem('access_token');
      const data = query.data
      if (token && data !== null && data.Error === false) {
        await dispatch(mapTessellatorTopicToNullUpdateSuccess(data));
      }
      else if (data !== null && data.Error === true) {
        if (Object.keys(data).length >= 3 && data.statusCode == 401) {
          message.error(data.message)
          await dispatch(mapTessellatorTopicToNullUpdateSuccess(null));
          dispatch(logOut())
        }
        else {
          await dispatch(mapTessellatorTopicToNullUpdateSuccess(null));
        }
      }
      else {
        // unauthorize
        await dispatch(mapTessellatorTopicToNullUpdateSuccess(null));
      }
    } catch (err) {
      await dispatch(mapTessellatorTopicToNullUpdateErr(err));
    }
  };
};

const MapTessellatorUnitToNull = (unitId) => {
  return async (dispatch) => {
    try {
      await dispatch(mapTessellatorUnitToNullUpdateBegin());
      const query = await DataService.put(`/unitmaster/tessunitupdatetonull/${unitId}`);
      const token = getItem('access_token');
      const data = query.data
      if (token && data !== null && data.Error === false) {
        await dispatch(mapTessellatorUnitToNullUpdateSuccess(data));
      }
      else if (data !== null && data.Error === true) {
        if (Object.keys(data).length >= 3 && data.statusCode == 401) {
          message.error(data.message)
          await dispatch(mapTessellatorUnitToNullUpdateSuccess(null));
          dispatch(logOut())
        }
        else {
          await dispatch(mapTessellatorUnitToNullUpdateSuccess(null));
        }
      }
      else {
        // unauthorize
        await dispatch(mapTessellatorUnitToNullUpdateSuccess(null));
      }
    } catch (err) {
      await dispatch(mapTessellatorUnitToNullUpdateErr(err));
    }
  };
};

const TelescopeTopicsDataRead = (subjectId) => {
  return async (dispatch) => {
    try {
      await dispatch(telescopeTopicsReadBegin());
      const query = await DataService.get(`/subjectmaster/topicstelescope/${subjectId}`);
      const token = getItem('access_token');
      const data = query.data
      if (token && data !== null && data.Error === false) {
        await dispatch(telescopeTopicsReadSuccess(data.payload));
      }
      else if (data !== null && data.Error === true) {
        if (Object.keys(data).length >= 3 && data.statusCode == 401) {
          message.error(data.message)
          await dispatch(telescopeTopicsReadSuccess([]));
          dispatch(logOut())
        }
        else {
          await dispatch(telescopeTopicsReadSuccess([]));
        }
      }
      else {
        await dispatch(telescopeTopicsReadSuccess([]));
        // await dispatch(telescopeTopicsAddErr('Unauthorized access'));
      }
    } catch (err) {
      await dispatch(telescopeTopicsReadErr(err));
    }
  };
};

const TelescopeTopicProgramsDataRead = (topicId) => {
  return async (dispatch) => {
    try {
      await dispatch(telescopeTopicProgramsReadBegin());
      const query = await DataService.get(`/telescopeprograms/${topicId}`);
      const token = getItem('access_token');
      const data = query.data
      if (token && data !== null && data.Error === false) {
        await dispatch(telescopeTopicProgramsReadSuccess(data.payload));
      }
      else if (data !== null && data.Error === true) {
        if (Object.keys(data).length >= 3 && data.statusCode == 401) {
          message.error(data.message)
          await dispatch(telescopeTopicProgramsReadSuccess([]));
          dispatch(logOut())
        }
        else {
          await dispatch(telescopeTopicProgramsReadSuccess([]));
        }
      }
      else {
        await dispatch(telescopeTopicProgramsReadSuccess([]));
      }
    } catch (err) {
      await dispatch(telescopeTopicProgramsReadErr(err));
    }
  };
};

const MapTelescopeTopic = (topicId, testTopicId) => {
  return async (dispatch) => {
    try {
      await dispatch(mapTelescopeTopicUpdateBegin());
      const query = await DataService.put(`/topicmaster/teletopicupdate/${topicId}/${testTopicId}`);
      const token = getItem('access_token');
      const data = query.data
      if (token && data !== null && data.Error === false) {
        await dispatch(mapTelescopeTopicUpdateSuccess(data));
      }
      else if (data !== null && data.Error === true) {
        if (Object.keys(data).length >= 3 && data.statusCode == 401) {
          message.error(data.message)
          await dispatch(mapTelescopeTopicUpdateSuccess(null));
          dispatch(logOut())
        }
        else {
          await dispatch(mapTelescopeTopicUpdateSuccess(null));
        }
      }
      else {
        // unauthorize
        await dispatch(mapTelescopeTopicUpdateSuccess(null));
      }
    } catch (err) {
      await dispatch(mapTelescopeTopicUpdateErr(err));
    }
  };
};

const MapTelescopeTopicToNull = (topicId) => {
  return async (dispatch) => {
    try {
      await dispatch(mapTelescopeTopicToNullUpdateBegin());
      const query = await DataService.put(`/topicmaster/teletopicupdatetonull/${topicId}`);
      const token = getItem('access_token');
      const data = query.data
      if (token && data !== null && data.Error === false) {
        await dispatch(mapTelescopeTopicToNullUpdateSuccess(data));
      }
      else if (data !== null && data.Error === true) {
        if (Object.keys(data).length >= 3 && data.statusCode == 401) {
          message.error(data.message)
          await dispatch(mapTelescopeTopicToNullUpdateSuccess(null));
          dispatch(logOut())
        }
        else {
          await dispatch(mapTelescopeTopicToNullUpdateSuccess(null));
        }
      }
      else {
        // unauthorize
        await dispatch(mapTelescopeTopicToNullUpdateSuccess(null));
      }
    } catch (err) {
      await dispatch(mapTelescopeTopicToNullUpdateErr(err));
    }
  };
};

export {
  TessellatorTopicsDataRead,
  TessellatorTopicProgramsDataRead,
  MapTessellatorTopic,
  MapTessellatorTopicToNull,
  TessellatorUnitProgramsDataRead,
  TessellatorUnitDataRead,
  MapTessellatorUnit,
  MapTessellatorUnitToNull,
  TelescopeTopicsDataRead,
  TelescopeTopicProgramsDataRead,
  MapTelescopeTopic,
  MapTelescopeTopicToNull
};