import react, { useEffect, useState } from 'react'
import { Main } from '../styled'
import { Cards } from '../../components/cards/frame/cards-frame'
import { Button } from '../../components/buttons/buttons'
import { DataTableStyleWrap } from '../../components/table/Style'
import { Form, Modal, Result, Select, Steps, Table, message } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { getTopicContentByTopic } from '../../redux/teacherTopicsMenu/actionCreator'
import CustomSpinner from '../ui-elements/spinner/CustomSpinner'
import { GenerateUnitWiseQA } from '../../redux/generateQuestions/actionCreator'
import actions from '../../redux/generateQuestions/actions'
import { getUnitById } from '../../redux/teacherSubjectsUnits/actionCreator'
import unitActions from '../../redux/teacherSubjectsUnits/actions'
import { FormValidationWrap } from '../forms/overview/Style'
import { StepsStyle } from '../../components/steps/style'
import UnitwiseQAReview from './UnitwiseQAReview'
import UnitPrograms from './UnitPrograms'
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom'
import UilArrowRight from '@iconscout/react-unicons/icons/uil-arrow-right'
import { sendUnitToHod } from '../../redux/unit/actionCreator';
import UilArrowLeft from '@iconscout/react-unicons/icons/uil-arrow-left'


const { Step } = StepsStyle;
const { sendUnitToHodSuccess } = actions

const { generateUnitWiseQASuccess } = actions

function UnitwiseQaGeneration({ getStatusIndex, unitId }) {

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [tableData, setTableData] = useState([])
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [topicIds, setTopicIds] = useState([]);
    const [modalVisible, setModalVisible] = useState(false);
    const [contentModalVisible, setContentModalVisible] = useState(false);
    // const [qaType, setQAType] = useState('');
    const [qano, setQAno] = useState(0);
    const [fontSize, setFontSize] = useState("14px");

    const { SubjectsUnits, SubjectsUnitsLoading, Topics, isTopicsLoading, UnitWiseRes, isUnitWiseQAgenerating, UnitInfo, UnitLoading, topicContent, isTopicContentLoading } = useSelector((state) => {
        return {
            SubjectsUnits: state.SubjectsUnits.data,
            SubjectsUnitsLoading: state.SubjectsUnits.loading,
            Topics: state.Topics.data,
            isTopicsLoading: state.Topics.loading,
            UnitWiseRes: state.UnitWiseQaRes.data,
            isUnitWiseQAgenerating: state.UnitWiseQaRes.loading,
            UnitInfo: state.Unit.data,
            UnitLoading: state.Unit.loading,
            topicContent: state.TopicContent.data,
            isTopicContentLoading: state.TopicContent.loading,
        }
    })
    const { sendToHodRes, isSendingToHod } = useSelector((state) => {
        return {
            sendToHodRes: state.sendUnitToHodRes.data,
            isSendingToHod: state.sendUnitToHodRes.data,

        };
    });

    const columns = [
        {
            title: 'S.No.',
            dataIndex: 'sno',
        },
        {
            title: 'Topic',
            dataIndex: 'topicName',
        },
        {
            title: 'Status',
            dataIndex: 'status',
        },
        {
            title: 'Content',
            render: (text, record) => (
                <Button
                    type='primary'
                    onClick={() => { setContentModalVisible(true); dispatch(getTopicContentByTopic(record.topicId)); }}
                >
                    View
                </Button>
            ),
        },
    ];

    useEffect(() => {
        const data = []
        Topics !== null && Topics.length !== 0 && Topics.map((topicsObj, index) => {
            data.push(
                {
                    key: index,
                    "sno": index + 1,
                    topicId: topicsObj.id,
                    topicName: topicsObj.topic,
                    status: topicsObj.status !== "CONTENT FREEZED" ? <span className='text-danger fontsize-13'>{topicsObj.status}</span> : <span className='text-success fontsize-13'>{topicsObj.status}</span>,
                    topicstatus: topicsObj.status
                }
            )
        })
        setTableData(data)
    }, [Topics])

    const getIds = () => {
        let tIds = []
        tableData.length !== 0 && tableData.filter((obj) => obj.topicstatus === "CONTENT FREEZED").length !== 0 && tableData.filter((obj) => obj.topicstatus === "CONTENT FREEZED").map((tobj, index) => {
            tIds.push(tobj.topicId)
        })
        setTopicIds(tIds)
        setModalVisible(true);
    };

    const HodStatusSetToNothing = () => {
        const datatoPost = {
            unitId: parseInt(unitId),
            hodStatus: "NOTHING",
            hodComment: UnitInfo?.hodComment
        }
        dispatch(sendUnitToHod(datatoPost, false))
    }

    // const GenerateQA = () => {
    //     setModalVisible(false)
    //     if (qaType !== '' && qano !== 0 && unitId !== 0 && topicIds.length !== 0) {
    //         const dataToPost = {
    //             id: parseInt(unitId),
    //             type: qaType,
    //             qno: parseInt(qano),
    //             topicIds: topicIds
    //         }
    //         // console.log(dataToPost)
    //         dispatch(GenerateUnitWiseQA(dataToPost))
    //         setQAType('')
    //         setQAno(0)
    //     }
    //     else {
    //         message.error("Select QA Type and Number of QA")
    //     }
    // }

    const GenerateQA = () => {
        setModalVisible(false)
        if (qano !== 0 && unitId !== 0 && topicIds.length !== 0) {
            const dataToPost = {
                id: parseInt(unitId),
                qno: parseInt(qano),
                topicIds: topicIds
            }
            // console.log(dataToPost)
            dispatch(GenerateUnitWiseQA(dataToPost))
            // setQAType('')
            setQAno(0)
        }
        else {
            message.error("Select QA Type and Number of QA")
        }
    }

    useEffect(() => {
        if (UnitWiseRes !== null && UnitWiseRes.Error === false) {
            dispatch(getUnitById(unitId))
        }
        dispatch(generateUnitWiseQASuccess(null))
    }, [UnitWiseRes])


    useEffect(() => {
        if (UnitInfo !== null && UnitInfo.qaStatus === "QUESTIONS GENERATING") {
            const intervalCall = setInterval(() => {
                dispatch(getUnitById(unitId))
            }, 60000);
            return () => {
                // clean up
                clearInterval(intervalCall);
            };
        }
    }, [UnitInfo]);

    const DownloadContent = () => {
        if (topicContent !== null) {
            const element = document.createElement("a");
            const file = new Blob([topicContent.content], { type: 'text/plain' });
            element.href = URL.createObjectURL(file);
            var str = topicContent.name;
            var filename = str.replace(/\s+/g, "");
            element.download = `${filename}.txt`;
            document.body.appendChild(element); // Required for this to work in FireFox
            element.click();
        }
        else {
            message.error('Failed to download')
        }
    }

    return (
        <div>
            <DataTableStyleWrap>
                {
                    isTopicsLoading || UnitLoading ?
                        <div style={{ width: '100%', height: '400px' }}>
                            <div className="custom-spin">
                                <CustomSpinner Message={'Fetching data. Please wait...'} />
                            </div>
                        </div>
                        :
                        isUnitWiseQAgenerating || getStatusIndex(UnitInfo !== null && UnitInfo.qaStatus) === 1 ?
                            <div style={{ width: '100%', height: '400px' }}>
                                <div className="custom-spin">
                                    <CustomSpinner Message={'Q & A is being generated. Please wait'} />
                                </div>
                            </div>
                            :
                            <div>
                                {
                                    getStatusIndex(UnitInfo !== null && UnitInfo.qaStatus) === 0 &&
                                    < Table
                                        className="table-responsive"
                                        pagination={false}
                                        columns={columns}
                                        dataSource={tableData}
                                        tableLayout='auto'
                                        size='middle'
                                    // rowSelection={{ selectedRowKeys, onChange: rowSelection, hideSelectAll: true }}
                                    // rowClassName={record => record.topicstatus != "CONTENT FREEZED" && 'disabled-row'}
                                    />
                                }
                                {
                                    getStatusIndex(UnitInfo !== null && UnitInfo.qaStatus) === 2 &&
                                    <Result
                                        status="success"
                                        title="Questions Generated Successfully"
                                        extra={
                                            <Button id="generateQA_uploadcontent-button" type="primary" key="navigateToReviewContent" onClick={() => navigate(`/teacher/unit-wise/qa-review`)}>
                                                Review
                                                {/* <UilArrowRight /> */}
                                            </Button>
                                        }
                                    />
                                }
                                {
                                    getStatusIndex(UnitInfo !== null && UnitInfo.qaStatus) === 3 &&
                                    <Result
                                        status="success"
                                        title="Questions Reviewed Successfully"
                                        extra={
                                            <Button id="generateQA_uploadcontent-button" type="primary" key="navigateToReviewContent" onClick={() => navigate(`/teacher/unit-wise/programs`)}>
                                                Verify Programs
                                            </Button>
                                        }
                                    />
                                }

                                {UnitInfo !== null && UnitInfo.hodStatus === "REJECTED" ? (
                                    <div>
                                        <Result
                                            status="warning"
                                            title="This unit is rejected by HOD"
                                            subTitle={<div>Reason: {UnitInfo.hodComment}</div>}
                                            extra={
                                                <div>
                                                    <Button id="freeze_verifyprograms-button1" type="warning" outlined style={{ color: 'black' }} className="mt-20 ml-10" key="reviewQuiz" onClick={() => { HodStatusSetToNothing(); navigate(`/teacher/unit-wise/programs`) }} disabled={isSendingToHod}>
                                                        <UilArrowLeft />
                                                        Verify Programs
                                                    </Button>
                                                </div>
                                            }
                                        />
                                    </div>
                                ) : UnitInfo !== null && UnitInfo.hodStatus === "PENDING" ? (
                                    <div>
                                        <Result
                                            status="info"
                                            title="This unit is under review by HOD"
                                            subTitle="Please wait until review completed"
                                        />
                                    </div>
                                ) : (
                                    getStatusIndex(UnitInfo !== null && UnitInfo.qaStatus) === 4 && (
                                        <Result
                                            status="success"
                                            title="Programs verified successfully"
                                            extra={
                                                <Button id="generateQA_uploadcontent-button" type="primary" key="navigateToReviewContent" onClick={() => navigate(`/teacher/unit-wise/programs`)}>
                                                    Verify Programs
                                                </Button>
                                            }
                                        />
                                    )
                                )}
                                {
                                    getStatusIndex(UnitInfo !== null && UnitInfo.qaStatus) === 5 &&
                                    <Result
                                        status="success"
                                        title="Content Freezed"
                                        subTitle={`on ${UnitInfo !== null && new Date(UnitInfo.updatedon).toLocaleString()}`}
                                    />
                                }

                            </div>
                }
                {
                    tableData.length !== 0 && getStatusIndex(UnitInfo !== null && UnitInfo.qaStatus) === 0 &&
                    <div className="mt-10 d-flex justify-content-end">
                        {
                            tableData.every(item => item.topicstatus === "CONTENT FREEZED") ?
                                <Button id="unitwise_get-button" type="primary" size="small" onClick={() => { getIds() }} disabled={tableData.length === 0 ? true : false}>
                                    Generate QA
                                </Button>
                                :
                                <div className='mt-20'>
                                    <span className='fontsize-12'>Note: All topics must be freezed</span>
                                </div>
                        }
                    </div>
                }
            </DataTableStyleWrap>
            <Modal
                open={modalVisible}
                title="Generate Q & A"
                onCancel={() => setModalVisible(false)}
                onOk={GenerateQA}
                okText="Yes"
            >
                <FormValidationWrap>
                    <Form layout='vertical'>
                        <div style={{ marginBottom: '16px', fontSize: '14px', color: '#595959' }}>
                        Unit Questions are generated according to Blooms Taxonomy Levels - 2,3,4.
                        <br /><br />
                        <span style={{fontWeight:'bold'}}>Level 2</span> - Understanding
                        <br />
                        <span style={{fontWeight:'bold'}}>Level 3</span> - Applying
                        <br />
                        <span style={{fontWeight:'bold'}}>Level 4</span> - Analysing
                        <br /><br />
                        <span style={{fontWeight: 'bold'}}>
                        For Example, if 5 QA per level is chosen, a total of 15 questions are generated, with 5 questions in each level. 
                        </span>
                        <br /><br />
                        <span style={{color: 'red', fontWeight: 'bold'}}>
                        Each Level must have a minimum of 4 questions.
                        </span>
                        </div>
                        {/* <Form.Item name="qatype"
                            rules={[{ required: true, message: 'QA Type required' }]}
                            label="QA Type">
                            <div className="">
                                <Select id="unitwise_select-qa-type" value={qaType} className="ninjadash-data-status" placeholder="select" onChange={(e) => { setQAType(e) }}>
                                    <Select.Option value={''} key={'selectqatype'} >Select</Select.Option>
                                    <Select.Option value={'MEDIUM'} key={'selectqamedium'} >Medium</Select.Option>
                                    <Select.Option value={'HARD'} key={'selectqahard'} >Hard</Select.Option>
                                </Select>
                            </div>
                        </Form.Item> */}
                        <Form.Item name="qano"
                            rules={[{ required: true, message: 'QA Number required' }]}
                            label="Number of QA per Level">
                            <div className="">
                                <Select id="unitwise_select-no-qa" value={qano} className="ninjadash-data-status" placeholder="select" onChange={(e) => { setQAno(parseInt(e)) }}>
                                    <Select.Option value={0} key={'selectqatype'} >Select</Select.Option>
                                    <Select.Option value="5">5</Select.Option>
                                    <Select.Option value="6">6</Select.Option>
                                    <Select.Option value="7">7</Select.Option>
                                    <Select.Option value="8">8</Select.Option>
                                    {/* <Select.Option value="16">16</Select.Option>
                                    <Select.Option value="18">18</Select.Option>
                                    <Select.Option value="20">20</Select.Option>
                                    <Select.Option value="22">22</Select.Option>
                                    <Select.Option value="24">24</Select.Option>
                                    <Select.Option value="26">26</Select.Option>
                                    <Select.Option value="28">28</Select.Option>
                                    <Select.Option value="30">30</Select.Option> */}
                                </Select>
                            </div>
                        </Form.Item>
                    </Form>
                </FormValidationWrap>
            </Modal>

            {/* content modal */}
            <Modal
                open={contentModalVisible}
                title={topicContent !== null && topicContent.name + ' of ' + (topicContent.unit !== null && topicContent.unit.name) + ', ' + (topicContent.unit.subject !== null && topicContent.unit.subject.name)}
                onCancel={() => setContentModalVisible(false)}
                width={1000}
                footer={false}
            >
                {
                    isTopicContentLoading ?
                        <div className="custom-spin">
                            <CustomSpinner Message={'Loading content... Please wait.'} />
                        </div>
                        :
                        <div className='height-fixed'>
                            <div className='pb-10 d-flex justify-content-end'>
                                <div className='mr-10'>
                                    <Form.Item className='p-0 m-0' name="qano"
                                        rules={[{ required: false }]}
                                        label="Font Size">
                                        <Select id="unitwise_select-fontsize" style={{ width: 100 }} defaultValue={'14px'} value={fontSize} className="ninjadash-data-status" placeholder="select" onChange={(e) => { setFontSize(e) }}>
                                            <Select.Option value="12px">12px</Select.Option>
                                            <Select.Option value="14px">14px</Select.Option>
                                            <Select.Option value="16px">16px</Select.Option>
                                            <Select.Option value="18px">18px</Select.Option>
                                            <Select.Option value="20px">20px</Select.Option>
                                            <Select.Option value="22px">22px</Select.Option>
                                            <Select.Option value="24px">24px</Select.Option>
                                            <Select.Option value="26px">26px</Select.Option>
                                            <Select.Option value="28px">28px</Select.Option>
                                            <Select.Option value="30px">30px</Select.Option>
                                        </Select>
                                    </Form.Item>
                                </div>
                                <Button htmlType="submit" size={'small'} type="primary" onClick={() => DownloadContent()} >
                                    Download
                                </Button>
                            </div>
                            {
                                topicContent !== null && <p className='mr-10' style={{ fontSize: fontSize }}>{topicContent.content}</p>
                            }
                        </div>
                }
            </Modal>
        </div>
    )
}

export default UnitwiseQaGeneration