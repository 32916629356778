const actions = {

  STUDENT_PROGRAMS_READ_BEGIN: "STUDENT_PROGRAMS_READ_BEGIN",
  STUDENT_PROGRAMS_READ_SUCCESS: "STUDENT_PROGRAMS_READ_SUCCESS",
  STUDENT_PROGRAMS_READ_ERR: "STUDENT_PROGRAMS_READ_ERR",

  studentProgramsReadBegin: () => {
    return {
      type: actions.STUDENT_PROGRAMS_READ_BEGIN,
    };
  },

  studentProgramsReadSuccess: (data) => {
    return {
      type: actions.STUDENT_PROGRAMS_READ_SUCCESS,
      data,
    };
  },

  studentProgramsReadErr: (err) => {
    return {
      type: actions.STUDENT_PROGRAMS_READ_ERR,
      err,
    };
  },

}

export default actions