import React, { useState } from 'react';
import { Row, Col, Form, Input, Button, message } from 'antd';
import { ChangePasswordWrapper } from './style';
import { Cards } from '../../../../components/cards/frame/cards-frame';
import { BasicFormWrapper, Main } from '../../../styled';
import Heading from '../../../../components/heading/heading';
import { useDispatch, useSelector } from 'react-redux';
import { changePassword } from '../../../../redux/changepassword/actionCreator';
import { getItem } from '../../../../utility/localStorageControl';


function Password() {

  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const { changepasswordRes, isLoading } = useSelector((state) => {
    return {
      changepasswordRes: state.ChangePassword.data,
      isLoading: state.ChangePassword.loading
    }
  });

  const handleSubmit = (values) => {
    const username = getItem('username')
    if (values.passowrd === values.confirmpassword && (username !== null || username !== undefined) && values.oldpassword !== '' && values.oldpassword !== values.passowrd) {
      const data = { username: username + "", newpassword: values.passowrd, oldpassword: values.oldpassword }
      dispatch(changePassword(data));
    }
    else {
      if (values.oldpassword === values.passowrd) {
        message.error("New password and Old Password should not be the same");
      } else {
        message.error("New password and Confirm password should be the same");
      }
    }
  }

  const handleCancel = (e) => {
    e.preventDefault();
    form.resetFields();
  };

  return (
    <Main>
      <ChangePasswordWrapper>
        <Cards
          title={
            <div className="setting-card-title">
              <Heading as="h4">Password Settings</Heading>
              <span>Change or reset your account password</span>
            </div>
          }
        >
          <Row justify="center">
            <Col lg={12} sm={20} xs={24}>
              <BasicFormWrapper>
                <Form form={form} name="changePassword" onFinish={handleSubmit}>
                  <Form.Item name="oldpassword"
                    label="Old Password"
                    rules={[{ message: 'Please input old password!', required: true }]}
                  >
                    <Input.Password placeholder="Old password" />
                  </Form.Item>
                  <Form.Item name="passowrd"
                    label="New Password"
                    rules={[{ message: 'Please input new password!', required: true }]}
                  >
                    <Input.Password placeholder="New password" />
                  </Form.Item>
                  <Form.Item name="confirmpassword"
                    label="Confirm Password"
                    rules={[{ message: 'Please input confirm password!', required: true }]}
                  >
                    <Input.Password placeholder="Confirm password" />
                  </Form.Item>
                  <Form.Item>
                    <div className="setting-form-actions m-0 mt-20">
                      <Button htmlType="submit" type="primary">
                        {isLoading ? 'Loading...' : 'Change Password'}
                      </Button>
                      &nbsp; &nbsp;
                      <Button size="default" onClick={handleCancel} type="light">
                        Cancel
                      </Button>
                    </div>
                  </Form.Item>
                </Form>
              </BasicFormWrapper>
            </Col>
          </Row>
        </Cards>
      </ChangePasswordWrapper>
    </Main>
  );
}

export default Password;
