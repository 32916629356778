import actions from './actions';
import { DataService } from '../../config/dataService/dataService';
import jwtDecode from 'jwt-decode';
import { message } from 'antd';
import { getItem, removeItem, setItem } from '../../utility/localStorageControl';

const {
  loginBegin,
  loginSuccess,
  loginErr,
  logoutBegin,
  logoutSuccess,
  logoutErr,
  forgotPassBegin,
  forgotPassSuccess,
  forgotPassErr,
  verifyOTPBegin,
  verifyOTPSuccess,
  verifyOTPErr,
  resetPassBegin,
  resetPassSuccess,
  resetPassErr,
  getmsgBegin,
  getmsgSuccess,
  getmsgErr,
  getclgmsgBegin,
  getclgmsgSuccess,
  getclgmsgErr
} = actions;

const login = (values) => {
  return async (dispatch) => {
    dispatch(loginBegin());
    try {
      const response = await DataService.login('/auth/login', values);
      if (response.data.Error === true) {
        message.error(response.data.message)
        dispatch(loginErr(response.data.message));
      }
      else {
        const userInfo = await jwtDecode(response.data.access_token)
        if (userInfo.error === false && Object.keys(response.data).length !== 0 && 'access_token' in response.data && userInfo.role !== "ADMIN") {
          setItem('access_token', response.data.access_token);
          setItem('logedIn', true);
          setItem('role', userInfo.role);
          setItem('username', userInfo.username);
          setItem('userId', userInfo.sub);
          setItem('collegeName', userInfo.collegeName);
          setItem('collegeId', userInfo.collegeId);
          setItem('profilename', userInfo.name);
          setItem('regulationId', response.data.regulationId);
          setItem('regulationCode', response.data.regulationCode);
          setItem('branchId', response.data.branchId);
          setItem('branchCode', response.data.branchCode);
          setItem('currentyear', response.data.currentyear);
          setItem('yearsemesterId', response.data.yearsemesterId);
          setItem('yearsemesterCode', response.data.yearsemesterCode);
          setItem('sectionId', response.data.sectionId);
          setItem('sectionName', response.data.sectionName);
          setItem('isForcePasswordEnabled',response.data.isForcePasswordEnabled)
          dispatch(loginSuccess(true));
          // callback();
        }
        else {
          message.error("Invalid credentials")
          dispatch(loginErr("Invalid credentials"));
        }
      }
    } catch (err) {
      dispatch(loginErr(err));
    }
  };
};

const forgotPassword = (email) => {
  return async (dispatch) => {
    try {
      await dispatch(forgotPassBegin());
      const response = await DataService.put(`/usermaster/verify-send-otp/${email}`);
      if (response.data !== null && response.data.Error === false) {
        await dispatch(forgotPassSuccess(response.data));
        message.success(response.data.message)
      }
      else if (response.data !== null && response.data.Error === true) {
        message.error(response.data.message)
      }
    } catch (err) {
      await dispatch(forgotPassErr(err));
    }
  };
};

const VerifyOTP = (otpData) => {
  return async (dispatch) => {
    try {
      await dispatch(verifyOTPBegin());
      const response = await DataService.post(`/usermaster/verifyotp`, otpData);
      if (response.data !== null && response.data.Error === false) {
        await dispatch(verifyOTPSuccess(response.data));
        message.success(response.data.message)
      }
      else if (response.data !== null && response.data.Error === true) {
        message.error(response.data.message)
      }
    } catch (err) {
      await dispatch(verifyOTPErr(err));
    }
  };
};

const ResetPassword = (passData) => {
  return async (dispatch) => {
    try {
      await dispatch(resetPassBegin());
      const response = await DataService.put(`/usermaster/changepwd`, passData);
      if (response.data !== null && response.data.Error === false) {
        await dispatch(resetPassSuccess(response.data));
        message.success(response.data.message)
      }
      else if (response.data !== null && response.data.Error === true) {
        message.error(response.data.message)
      }
    } catch (err) {
      await dispatch(resetPassErr(err));
    }
  };
};

const logOut = (callback) => {
  return async (dispatch) => {
    dispatch(logoutBegin());
    try {
      removeItem('logedIn');
      removeItem('access_token');
      removeItem('role')
      removeItem('username')
      removeItem('profilename')
      removeItem('userId');
      removeItem('activeunit');
      removeItem('activetopic');
      removeItem('activestudenttopic')
      removeItem('collegeName')
      removeItem('collegeId')
      removeItem('currentweek')
      removeItem('regulationId')
      removeItem('regulationCode')
      removeItem('branchId')
      removeItem('branchCode')
      removeItem('currentyear')
      removeItem('yearsemesterId')
      removeItem('yearsemesterCode')
      removeItem('sectionId')
      removeItem('sectionName')
      removeItem('isForcePasswordEnabled')
      dispatch(logoutSuccess(false));
      callback();
    } catch (err) {
      dispatch(logoutErr(err));
    }
  };
};

const getMessage = () => {
  return async (dispatch) => {
    try {
      await dispatch(getmsgBegin());
      const response = await DataService.getMsg(`/notice/message`);
      if (response.data.Error == false && response.data !== null) {
        await dispatch(getmsgSuccess(response.data.htmlcode));
      }
      else {
        await dispatch(getmsgSuccess(null));
      }
    } catch (err) {
      await dispatch(getmsgErr(err));
    }
  };
}

const getCollegeWiseNotice = () => {
  return async (dispatch) => {
    try {
      const currentyear = getItem('currentyear')
      const obj = {
        role: getItem('role'),
        studentYear: currentyear ? currentyear : 0
      }
      await dispatch(getclgmsgBegin());
      const response = await DataService.post(`/notice/clgmsg`,obj);
      if (response.data.Error == false && response.data !== null) {
        await dispatch(getclgmsgSuccess(response.data.payload));
      }
      else {
        await dispatch(getclgmsgSuccess(null));
      }
    } catch (err) {
      await dispatch(getclgmsgErr(err));
    }
  };
}

export {
  login, logOut, forgotPassword, VerifyOTP, ResetPassword, getMessage, getCollegeWiseNotice
  //  register, fbLogin 
};