const actions = {
  TESSELLATOR_TOPICS_READ_BEGIN: 'TESSELLATOR_TOPICS_READ_BEGIN',
  TESSELLATOR_TOPICS_READ_SUCCESS: 'TESSELLATOR_TOPICS_READ_SUCCESS',
  TESSELLATOR_TOPICS_READ_ERR: 'TESSELLATOR_TOPICS_READ_ERR',

  TESSELLATOR_UNIT_READ_BEGIN: 'TESSELLATOR_UNIT_READ_BEGIN',
  TESSELLATOR_UNIT_READ_SUCCESS: 'TESSELLATOR_UNIT_READ_SUCCESS',
  TESSELLATOR_UNIT_READ_ERR: 'TESSELLATOR_UNIT_READ_ERR',

  TESSELLATOR_TOPIC_PROGRAMS_READ_BEGIN: 'TESSELLATOR_TOPIC_PROGRAMS_READ_BEGIN',
  TESSELLATOR_TOPIC_PROGRAMS_READ_SUCCESS: 'TESSELLATOR_TOPIC_PROGRAMS_READ_SUCCESS',
  TESSELLATOR_TOPIC_PROGRAMS_READ_ERR: 'TESSELLATOR_TOPIC_PROGRAMS_READ_ERR',

  TESSELLATOR_UNIT_PROGRAMS_READ_BEGIN: 'TESSELLATOR_UNIT_PROGRAMS_READ_BEGIN',
  TESSELLATOR_UNIT_PROGRAMS_READ_SUCCESS: 'TESSELLATOR_UNIT_PROGRAMS_READ_SUCCESS',
  TESSELLATOR_UNIT_PROGRAMS_READ_ERR: 'TESSELLATOR_UNIT_PROGRAMS_READ_ERR',

  MAP_TESSELLATOR_TOPIC_UPDATE_BEGIN: 'MAP_TESSELLATOR_TOPIC_UPDATE_BEGIN',
  MAP_TESSELLATOR_TOPIC_UPDATE_SUCCESS: 'MAP_TESSELLATOR_TOPIC_UPDATE_SUCCESS',
  MAP_TESSELLATOR_TOPIC_UPDATE_ERR: 'MAP_TESSELLATOR_TOPIC_UPDATE_ERR',

  MAP_TESSELLATOR_UNIT_UPDATE_BEGIN: 'MAP_TESSELLATOR_UNIT_UPDATE_BEGIN',
  MAP_TESSELLATOR_UNIT_UPDATE_SUCCESS: 'MAP_TESSELLATOR_UNIT_UPDATE_SUCCESS',
  MAP_TESSELLATOR_UNIT_UPDATE_ERR: 'MAP_TESSELLATOR_UNIT_UPDATE_ERR',

  MAP_TESSELLATOR_TOPIC_TO_NULL_UPDATE_BEGIN: 'MAP_TESSELLATOR_TOPIC_TO_NULL_UPDATE_BEGIN',
  MAP_TESSELLATOR_TOPIC_TO_NULL_UPDATE_SUCCESS: 'MAP_TESSELLATOR_TOPIC_TO_NULL_UPDATE_SUCCESS',
  MAP_TESSELLATOR_TOPIC_TO_NULL_UPDATE_ERR: 'MAP_TESSELLATOR_TOPIC_TO_NULL_UPDATE_ERR',

  MAP_TESSELLATOR_UNIT_TO_NULL_UPDATE_BEGIN: 'MAP_TESSELLATOR_UNIT_TO_NULL_UPDATE_BEGIN',
  MAP_TESSELLATOR_UNIT_TO_NULL_UPDATE_SUCCESS: 'MAP_TESSELLATOR_UNIT_TO_NULL_UPDATE_SUCCESS',
  MAP_TESSELLATOR_UNIT_TO_NULL_UPDATE_ERR: 'MAP_TESSELLATOR_UNIT_TO_NULL_UPDATE_ERR',

  TELESCOPE_TOPICS_READ_BEGIN: 'TELESCOPE_TOPICS_READ_BEGIN',
  TELESCOPE_TOPICS_READ_SUCCESS: 'TELESCOPE_TOPICS_READ_SUCCESS',
  TELESCOPE_TOPICS_READ_ERR: 'TELESCOPE_TOPICS_READ_ERR',

  TELESCOPE_TOPIC_PROGRAMS_READ_BEGIN: 'TELESCOPE_TOPIC_PROGRAMS_READ_BEGIN',
  TELESCOPE_TOPIC_PROGRAMS_READ_SUCCESS: 'TELESCOPE_TOPIC_PROGRAMS_READ_SUCCESS',
  TELESCOPE_TOPIC_PROGRAMS_READ_ERR: 'TELESCOPE_TOPIC_PROGRAMS_READ_ERR',

  MAP_TELESCOPE_TOPIC_UPDATE_BEGIN: 'MAP_TELESCOPE_TOPIC_UPDATE_BEGIN',
  MAP_TELESCOPE_TOPIC_UPDATE_SUCCESS: 'MAP_TELESCOPE_TOPIC_UPDATE_SUCCESS',
  MAP_TELESCOPE_TOPIC_UPDATE_ERR: 'MAP_TELESCOPE_TOPIC_UPDATE_ERR',
  
  MAP_TELESCOPE_TOPIC_TO_NULL_UPDATE_BEGIN: 'MAP_TELESCOPE_TOPIC_TO_NULL_UPDATE_BEGIN',
  MAP_TELESCOPE_TOPIC_TO_NULL_UPDATE_SUCCESS: 'MAP_TELESCOPE_TOPIC_TO_NULL_UPDATE_SUCCESS',
  MAP_TELESCOPE_TOPIC_TO_NULL_UPDATE_ERR: 'MAP_TELESCOPE_TOPIC_TO_NULL_UPDATE_ERR',


  tessellatorTopicsReadBegin: () => {
    return {
      type: actions.TESSELLATOR_TOPICS_READ_BEGIN,
    };
  },

  tessellatorTopicsReadSuccess: (data) => {
    return {
      type: actions.TESSELLATOR_TOPICS_READ_SUCCESS,
      data,
    };
  },

  tessellatorTopicsReadErr: (err) => {
    return {
      type: actions.TESSELLATOR_TOPICS_READ_ERR,
      err,
    };
  },

  tessellatorUnitReadBegin: () => {
    return {
      type: actions.TESSELLATOR_UNIT_READ_BEGIN,
    };
  },

  tessellatorUnitReadSuccess: (data) => {
    return {
      type: actions.TESSELLATOR_UNIT_READ_SUCCESS,
      data,
    };
  },

  tessellatorUnitReadErr: (err) => {
    return {
      type: actions.TESSELLATOR_UNIT_READ_ERR,
      err,
    };
  },

  tessellatorTopicProgramsReadBegin: () => {
    return {
      type: actions.TESSELLATOR_TOPIC_PROGRAMS_READ_BEGIN,
    };
  },

  tessellatorTopicProgramsReadSuccess: (data) => {
    return {
      type: actions.TESSELLATOR_TOPIC_PROGRAMS_READ_SUCCESS,
      data,
    };
  },

  tessellatorTopicProgramsReadErr: (err) => {
    return {
      type: actions.TESSELLATOR_TOPIC_PROGRAMS_READ_ERR,
      err,
    };
  },

  tessellatorUnitProgramsReadBegin: () => {
    return {
      type: actions.TESSELLATOR_UNIT_PROGRAMS_READ_BEGIN,
    };
  },

  tessellatorUnitProgramsReadSuccess: (data) => {
    return {
      type: actions.TESSELLATOR_UNIT_PROGRAMS_READ_SUCCESS,
      data,
    };
  },

  tessellatorUnitProgramsReadErr: (err) => {
    return {
      type: actions.TESSELLATOR_UNIT_PROGRAMS_READ_ERR,
      err,
    };
  },

  mapTessellatorTopicUpdateBegin: () => {
    return {
      type: actions.MAP_TESSELLATOR_TOPIC_UPDATE_BEGIN,
    };
  },

  mapTessellatorTopicUpdateSuccess: (data) => {
    return {
      type: actions.MAP_TESSELLATOR_TOPIC_UPDATE_SUCCESS,
      data,
    };
  },

  mapTessellatorTopicUpdateErr: (err) => {
    return {
      type: actions.MAP_TESSELLATOR_TOPIC_UPDATE_ERR,
      err,
    };
  },

  mapTessellatorUnitUpdateBegin: () => {
    return {
      type: actions.MAP_TESSELLATOR_UNIT_UPDATE_BEGIN,
    };
  },

  mapTessellatorUnitUpdateSuccess: (data) => {
    return {
      type: actions.MAP_TESSELLATOR_UNIT_UPDATE_SUCCESS,
      data,
    };
  },

  mapTessellatorUnitUpdateErr: (err) => {
    return {
      type: actions.MAP_TESSELLATOR_UNIT_UPDATE_ERR,
      err,
    };
  },

  mapTessellatorTopicToNullUpdateBegin: () => {
    return {
      type: actions.MAP_TESSELLATOR_TOPIC_TO_NULL_UPDATE_BEGIN,
    };
  },

  mapTessellatorTopicToNullUpdateSuccess: (data) => {
    return {
      type: actions.MAP_TESSELLATOR_TOPIC_TO_NULL_UPDATE_SUCCESS,
      data,
    };
  },

  mapTessellatorTopicToNullUpdateErr: (err) => {
    return {
      type: actions.MAP_TESSELLATOR_TOPIC_TO_NULL_UPDATE_ERR,
      err,
    };
  },

  mapTessellatorUnitToNullUpdateBegin: () => {
    return {
      type: actions.MAP_TESSELLATOR_UNIT_TO_NULL_UPDATE_BEGIN,
    };
  },

  mapTessellatorUnitToNullUpdateSuccess: (data) => {
    return {
      type: actions.MAP_TESSELLATOR_UNIT_TO_NULL_UPDATE_SUCCESS,
      data,
    };
  },

  mapTessellatorUnitToNullUpdateErr: (err) => {
    return {
      type: actions.MAP_TESSELLATOR_UNIT_TO_NULL_UPDATE_ERR,
      err,
    };
  },

  telescopeTopicsReadBegin: () => {
    return {
      type: actions.TELESCOPE_TOPICS_READ_BEGIN,
    };
  },

  telescopeTopicsReadSuccess: (data) => {
    return {
      type: actions.TELESCOPE_TOPICS_READ_SUCCESS,
      data,
    };
  },

  telescopeTopicsReadErr: (err) => {
    return {
      type: actions.TELESCOPE_TOPICS_READ_ERR,
      err,
    };
  },

  telescopeTopicProgramsReadBegin: () => {
    return {
      type: actions.TELESCOPE_TOPIC_PROGRAMS_READ_BEGIN,
    };
  },

  telescopeTopicProgramsReadSuccess: (data) => {
    return {
      type: actions.TELESCOPE_TOPIC_PROGRAMS_READ_SUCCESS,
      data,
    };
  },

  telescopeTopicProgramsReadErr: (err) => {
    return {
      type: actions.TELESCOPE_TOPIC_PROGRAMS_READ_ERR,
      err,
    };
  },


  mapTelescopeTopicUpdateBegin: () => {
    return {
      type: actions.MAP_TELESCOPE_TOPIC_UPDATE_BEGIN,
    };
  },

  mapTelescopeTopicUpdateSuccess: (data) => {
    return {
      type: actions.MAP_TELESCOPE_TOPIC_UPDATE_SUCCESS,
      data,
    };
  },

  mapTelescopeTopicUpdateErr: (err) => {
    return {
      type: actions.MAP_TELESCOPE_TOPIC_UPDATE_ERR,
      err,
    };
  },

  
  mapTelescopeTopicToNullUpdateBegin: () => {
    return {
      type: actions.MAP_TELESCOPE_TOPIC_TO_NULL_UPDATE_BEGIN,
    };
  },

  mapTelescopeTopicToNullUpdateSuccess: (data) => {
    return {
      type: actions.MAP_TELESCOPE_TOPIC_TO_NULL_UPDATE_SUCCESS,
      data,
    };
  },

  mapTelescopeTopicToNullUpdateErr: (err) => {
    return {
      type: actions.MAP_TELESCOPE_TOPIC_TO_NULL_UPDATE_ERR,
      err,
    };
  },



}


export default actions;