import { ConfigProvider, Spin } from 'antd';
import 'antd/dist/antd.less';
import React, { useEffect, useState, lazy } from 'react';
import { Provider, useDispatch, useSelector } from 'react-redux';
// import { isLoaded, ReactReduxFirebaseProvider } from 'react-redux-firebase';
import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom';

import SimpleReactLightbox from 'simple-react-lightbox';
import { ThemeProvider } from 'styled-components';
import ProtectedRoute from './components/utilities/protectedRoute';
import config from './config/config';
import store, { rrfProps } from './redux/store';

import Admin from './routes/admin';
import Teacher from './routes/teacher';
import Student from './routes/student';
import Auth from './routes/auth';
import './static/css/style.css';
import CustomSpinner from './container/ui-elements/spinner/CustomSpinner';
import { getCurrentWeek } from './redux/schedule/actionCreator';
import { getItem } from './utility/localStorageControl';
import { getMessage } from './redux/authentication/actionCreator';
import DrawingTool from './container/teacher/DrawingTool';

const NotFound = lazy(() => import('./container/pages/404'));

const { themeColor } = config;

function ProviderConfig() {
  const { rtl, isLoggedIn, topMenu, mainContent, auth, isLoading } = useSelector((state) => {
    return {
      rtl: state.ChangeLayoutMode.rtlData,
      topMenu: state.ChangeLayoutMode.topMenu,
      mainContent: state.ChangeLayoutMode.mode,
      isLoggedIn: state.auth.login,
      isLoading: state.auth.loading,
      auth: state.auth,
    };
  });

  const dispatch = useDispatch()
  const [role, setRole] = useState("");

  useEffect(() => {
    const role = getItem('role')
    setRole(role)
  })

  useEffect(() => {
    if (isLoggedIn && role === 'STUDENT') {
      dispatch(getCurrentWeek())
    }
  }, [isLoggedIn, role])


  const { msg, messageLoading } = useSelector((state) => {
    return {
      msg: state.msg.data,
      messageLoading: state.msg.loading
    };
  })

  useEffect(() => {
    dispatch(getMessage())
  }, [])

  // const [path, setPath] = useState(window.location.pathname);

  // useEffect(() => {
  //   let unmounted = false;
  //   if (!unmounted) {
  //     setPath(window.location.pathname);
  //   }
  //   // eslint-disable-next-line no-return-assign
  //   return () => (unmounted = true);
  // }, [setPath]);

  return (
    <ConfigProvider direction={rtl ? 'rtl' : 'ltr'}>
      <ThemeProvider theme={{ ...themeColor, rtl, topMenu, mainContent }}>
        {isLoading ? (
          <div className="">
            <CustomSpinner Message={'Logging in... Please wait.'} />
          </div>
        ) : (
          <SimpleReactLightbox>
            <Router basename={''}>
              {!isLoggedIn ? (
                <div>
                  <div className='text-center' dangerouslySetInnerHTML={{ __html: msg }} />
                  <Routes>
                    <Route path="/*" element={<Auth />} />
                  </Routes>
                </div>
              ) : (
                // isLoggedIn && role === 'ADMIN' ?
                //   <Routes>
                //     <Route path="/admin/*" element={<ProtectedRoute path="/*" Component={Admin} />} />
                //     <Route path="*" element={<NotFound />} />
                //   </Routes>
                //   :
                isLoggedIn && role === 'TEACHER' ?
                  <Routes>
                    <Route path="/teacher/*" element={<ProtectedRoute path="/*" Component={Teacher} />} />
                    <Route path="*" element={<NotFound />} />
                  </Routes>
                  :
                  isLoggedIn && role === 'STUDENT' &&
                  <Routes>
                    <Route path="/student/*" element={<ProtectedRoute path="/*" Component={Student} />} />
                    <Route path="*" element={<NotFound />} />
                  </Routes>
              )
              }
              {/* {isLoggedIn && role === 'ADMIN' && (
                <Routes>
                  <Route path="/" element={<Navigate to="/admin" />} />
                </Routes>
              )} */}
              {isLoggedIn && role === 'TEACHER' && (
                <Routes>
                  <Route path="/" element={<Navigate to="/teacher" />} />
                </Routes>
              )}
              {isLoggedIn && role === 'STUDENT' && (
                <Routes>
                  <Route path="/" element={<Navigate to="/student" />} />
                </Routes>
              )}
            </Router>
          </SimpleReactLightbox>
        )}
      </ThemeProvider>
    </ConfigProvider>
  );
}

function App() {
  return (
    <Provider store={store}>
      <ProviderConfig />
    </Provider>
  );
}

export default App;
