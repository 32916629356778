import react, { useEffect, useState } from 'react'
import { Main } from '../styled'
import { Cards } from '../../components/cards/frame/cards-frame'
import { Button } from '../../components/buttons/buttons'
import { DataTableStyleWrap } from '../../components/table/Style'
import { Form, Modal, Select, Steps, Table, message } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { getTopicContentByTopic, topicsDataRead } from '../../redux/teacherTopicsMenu/actionCreator'
import topicActions from '../../redux/teacherTopicsMenu/actions'
import CustomSpinner from '../ui-elements/spinner/CustomSpinner'
import { GenerateUnitWiseQA } from '../../redux/generateQuestions/actionCreator'
import actions from '../../redux/generateQuestions/actions'
import { getUnitById, getQAbyUnitId } from '../../redux/teacherSubjectsUnits/actionCreator'
import { StepsStyle } from '../../components/steps/style'
import UnitwiseQAReview from './UnitwiseQAReview'
import UnitPrograms from './UnitPrograms'
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom'
import UnitwiseQaGeneration from './UnitwiseQaGeneration'
import FreezeUnit from './FreezeUnit'
import ViewQA from './ViewQA'

const { Step } = StepsStyle;

const { generateUnitWiseQASuccess } = actions
const { ReadTopicsSuccess } = topicActions

function UnitwiseQAPagesView() {

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [subjectId, setSubjectId] = useState(0)
    const [unitId, setUnitId] = useState(0)
    const [tableData, setTableData] = useState([])
    const [isModalVisible, setModalVisible] = useState(false)

    const { SubjectsUnits, SubjectsUnitsLoading, Topics, isTopicsLoading, UnitWiseRes, isUnitWiseQAgenerating, UnitInfo, UnitLoading, topicContent, isTopicContentLoading, unitQA, isunitQALoading } = useSelector((state) => {
        return {
            SubjectsUnits: state.SubjectsUnits.data,
            SubjectsUnitsLoading: state.SubjectsUnits.loading,
            Topics: state.Topics.data,
            isTopicsLoading: state.Topics.loading,
            UnitWiseRes: state.UnitWiseQaRes.data,
            isUnitWiseQAgenerating: state.UnitWiseQaRes.loading,
            UnitInfo: state.Unit.data,
            UnitLoading: state.Unit.loading,
            topicContent: state.TopicContent.data,
            isTopicContentLoading: state.TopicContent.loading,
            unitQA: state.unitQA.data,
            isunitQALoading: state.unitQA.loading,
        }
    })
    const LoadTopics = () => {
        navigate('/teacher/unit-wise/qa-generation')
        if (subjectId !== 0 && unitId !== 0) {
            dispatch(getUnitById(unitId))
            dispatch(topicsDataRead({ subjectId, unitId }))
        }
        else {
            message.warn("Select subject and unit")
        }
    }

    const handleunitQA = () => {
        if (unitId !== 0) {
            dispatch(getQAbyUnitId(unitId))
            setModalVisible(true)
        }
    }

    useEffect(() => {
        dispatch(generateUnitWiseQASuccess(null))
        setTableData([])
        dispatch(ReadTopicsSuccess([]));
    }, [])


    useEffect(() => {
        const data = []
        Topics !== null && Topics.length !== 0 && Topics.map((topicsObj, index) => {
            data.push(
                {
                    key: index,
                    "sno": index + 1,
                    topicId: topicsObj.id,
                    topicName: topicsObj.topic,
                    status: topicsObj.status !== "CONTENT FREEZED" ? <span className='text-danger fontsize-13'>{topicsObj.status}</span> : <span className='text-success fontsize-13'>{topicsObj.status}</span>,
                    topicstatus: topicsObj.status
                }
            )
        })
        setTableData(data)
    }, [Topics])


    useEffect(() => {
        if (UnitWiseRes !== null && UnitWiseRes.Error === false) {
            dispatch(getUnitById(unitId))
        }
        dispatch(generateUnitWiseQASuccess(null))
    }, [UnitWiseRes])


    const statusArray = [
        'NOTHING',              //0                
        'QUESTIONS GENERATING', //1
        'QUESTIONS GENERATED',  //2
        'QUESTIONS REVIEWED',   //3    
        'PROGRAMS VERIFY',      //4
        'CONTENT FREEZED'       //5
    ]

    const getStatusIndex = (unitStatus) => {
        const index = statusArray.indexOf(unitStatus)
        return index
    }

    useEffect(() => {
        if (UnitInfo !== null && UnitInfo.qaStatus === "QUESTIONS GENERATING") {
            const intervalCall = setInterval(() => {
                dispatch(getUnitById(unitId))
            }, 60000);
            return () => {
                // clean up
                clearInterval(intervalCall);
            };
        }
    }, [UnitInfo]);

    return (
        <div>
            <Main className='pt-10'>
                <Cards className='' title={'Unit Wise Q&A Generation'}>
                    <DataTableStyleWrap>
                        <div className="ninjadash-datatable-filter">
                            <div className="ninjadash-datatable-filter__left">
                                <div className="ninjadash-datatable-filter__input">
                                    <span className="label">Subject</span>
                                    <Select id="unitwise_select-subject" style={{ width: 200 }} className="ninjadash-data-status" placeholder="select" onChange={(e) => { setSubjectId(e); setUnitId(0) }} loading={SubjectsUnitsLoading}>
                                        {
                                            SubjectsUnits !== null && SubjectsUnits.subjects.length !== 0 && SubjectsUnits.subjects.sort((a, b) => a.name > b.name ? 1 : -1).map((subjectObj, subjectIndex) =>
                                                <Select.Option value={subjectObj.id} key={subjectIndex}>{subjectObj.name}</Select.Option>
                                            )
                                        }
                                    </Select>
                                </div>
                                <div className="ninjadash-datatable-filter__input">
                                    <span className="label">Unit</span>
                                    <Select id="unitwise_select-unit" style={{ width: 200 }} value={unitId} className="ninjadash-data-status" placeholder="select" onChange={(e) => { setUnitId(e); }} loading={SubjectsUnitsLoading}>
                                        <Select.Option value={0} key={'selectreg'} >Select</Select.Option>
                                        {
                                            subjectId !== 0 && SubjectsUnits !== null && SubjectsUnits.subjects.length !== 0 && SubjectsUnits.subjects.filter((obj) => obj.id == subjectId)[0].units?.map((unitObj, subjectIndex) =>
                                                <Select.Option value={unitObj.id} key={subjectIndex}>{unitObj.name}</Select.Option>
                                            )
                                        }
                                    </Select>
                                </div>
                                <div className="ninjadash-datatable-filter__action">
                                    <Button id="unitwise_get-button" type="primary" size="small" onClick={() => { LoadTopics() }} 
                                    disabled={subjectId === 0 || unitId === 0}
                                    >
                                        Get Topics
                                    </Button>
                                </div>
                                {
                                    isTopicsLoading || UnitLoading ?
                                        <div style={{ width: '100%', height: '400px' }}>
                                            <div className="custom-spin">
                                                <CustomSpinner Message={'Fetching data. Please wait...'} />
                                            </div>
                                        </div>
                                        :
                                        <div className="ninjadash-datatable-filter__action" style={{ marginLeft: '100px' }}>
                                            {UnitInfo?.qaStatus === "CONTENT FREEZED" && (
                                                <Button
                                                    id="unitwise_get-button"
                                                    type="primary"
                                                    size="small"
                                                    // onClick={() => setModalVisible(true)}
                                                    onClick={handleunitQA}
                                                >
                                                    View QA
                                                </Button>
                                            )}
                                        </div>
                                }
                            </div>
                        </div>
                        {
                            isTopicsLoading || UnitLoading || isunitQALoading ?
                                <div style={{ width: '100%', height: '400px' }}>
                                    <div className="custom-spin">
                                        <CustomSpinner Message={'Fetching data. Please wait...'} />
                                    </div>
                                </div>
                                :
                                isUnitWiseQAgenerating || getStatusIndex(UnitInfo !== null && UnitInfo.qaStatus) === 1 ?
                                    <div style={{ width: '100%', height: '400px' }}>
                                        <div className="custom-spin">
                                            <CustomSpinner Message={'Q & A is being generated. Please wait'} />
                                        </div>
                                    </div>
                                    :
                                    <div>
                                        {
                                            getStatusIndex(UnitInfo !== null && UnitInfo.qaStatus) <= 5 && subjectId !== 0 && tableData.length !== 0 &&
                                            <div className='ml-20 mr-20 mb-20'>
                                                <Steps current={5} size="small" >
                                                    <Step title="Q & A Generate" status={`${(getStatusIndex(UnitInfo !== null && UnitInfo.qaStatus) == 0 || getStatusIndex(UnitInfo !== null && UnitInfo.qaStatus) == 1) ? 'process' : getStatusIndex(UnitInfo !== null && UnitInfo.qaStatus) >= 2 ? 'finish' : 'wait'}`} />
                                                    <Step title="Q & A Review" status={`${getStatusIndex(UnitInfo !== null && UnitInfo.qaStatus) == 2 ? 'process' : getStatusIndex(UnitInfo !== null && UnitInfo.qaStatus) >= 3 ? 'finish' : 'wait'}`} />
                                                    <Step title="Verify Programs" status={`${getStatusIndex(UnitInfo !== null && UnitInfo.qaStatus) == 3 ? 'process' : getStatusIndex(UnitInfo !== null && UnitInfo.qaStatus) >= 4 ? 'finish' : 'wait'}`} />
                                                    <Step title="Freeze" status={`${getStatusIndex(UnitInfo !== null && UnitInfo.qaStatus) == 4 ? 'process' : getStatusIndex(UnitInfo !== null && UnitInfo.qaStatus) >= 5 ? 'finish' : 'wait'}`} />
                                                </Steps>
                                                <div className='mt-20'>
                                                    <Routes>
                                                        <Route path="/" element={<Navigate to="qa-generation" />} />
                                                        <Route path="qa-generation/*" element={<UnitwiseQaGeneration unitId={unitId} getStatusIndex={getStatusIndex} UnitInfo={UnitInfo} unitStatus={UnitInfo !== null ? UnitInfo.qaStatus : null} />} />
                                                        <Route path="qa-review/*" element={<UnitwiseQAReview unitId={unitId} getStatusIndex={getStatusIndex} UnitInfo={UnitInfo} unitStatus={UnitInfo !== null ? UnitInfo.qaStatus : null} />} />
                                                        <Route path="programs/*" element={<UnitPrograms unitId={unitId} getStatusIndex={getStatusIndex} UnitInfo={UnitInfo} unitStatus={UnitInfo !== null ? UnitInfo.qaStatus : null} subjectId={subjectId} />} />
                                                        <Route path="unit-freeze/*" element={<FreezeUnit unitId={unitId} getStatusIndex={getStatusIndex} UnitInfo={UnitInfo} unitStatus={UnitInfo !== null ? UnitInfo.qaStatus : null} subjectId={subjectId} />} />
                                                    </Routes >
                                                </div>
                                            </div>
                                        }
                                    </div>
                        }
                    </DataTableStyleWrap>
                </Cards>
                <Modal
                    title={
                        <div>
                            <h4>{unitQA && unitQA[0]?.unit?.name}</h4>
                        </div>
                    }
                    open={isModalVisible}
                    onCancel={() => { setModalVisible(false) }
                    }
                    maskClosable={false}
                    footer={false}
                    width={1200}
                    centered
                >
                    {
                        isunitQALoading ?
                            <div className="custom-loading-spin-with-text">
                                <CustomSpinner Message={"Loading please wait..."} />
                            </div>
                            :
                            <div>
                                <ViewQA QA={unitQA} />
                            </div>
                    }
                </Modal>
            </Main>
        </div>
    )
}

export default UnitwiseQAPagesView