const actions = {
  YEARSEMESTER_ADD_BEGIN: 'YEARSEMESTER_ADD_BEGIN',
  YEARSEMESTER_ADD_SUCCESS: 'YEARSEMESTER_ADD_SUCCESS',
  YEARSEMESTER_ADD_ERR: 'YEARSEMESTER_ADD_ERR',

  YEARSEMESTER_READ_BEGIN: 'YEARSEMESTER_READ_BEGIN',
  YEARSEMESTER_READ_SUCCESS: 'YEARSEMESTER_READ_SUCCESS',
  YEARSEMESTER_READ_ERR: 'YEARSEMESTER_READ_ERR',

  YEARSEMESTER_UPDATE_BEGIN: 'YEARSEMESTER_UPDATE_BEGIN',
  YEARSEMESTER_UPDATE_SUCCESS: 'YEARSEMESTER_UPDATE_SUCCESS',
  YEARSEMESTER_UPDATE_ERR: 'YEARSEMESTER_UPDATE_ERR',

  YEARSEMESTER_DELETE_BEGIN: 'YEARSEMESTER_DELETE_BEGIN',
  YEARSEMESTER_DELETE_SUCCESS: 'YEARSEMESTER_DELETE_SUCCESS',
  YEARSEMESTER_DELETE_ERR: 'YEARSEMESTER_DELETE_ERR',

  YEARSEMESTER_SINGLE_DATA_BEGIN: 'YEARSEMESTER_SINGLE_DATA_BEGIN',
  YEARSEMESTER_SINGLE_DATA_SUCCESS: 'YEARSEMESTER_SINGLE_DATA_SUCCESS',
  YEARSEMESTER_SINGLE_DATA_ERR: 'YEARSEMESTER_SINGLE_DATA_ERR',

  YEARSEMESTER_UPLOAD_BEGIN: 'YEARSEMESTER_UPLOAD_BEGIN',
  YEARSEMESTER_UPLOAD_SUCCESS: 'YEARSEMESTER_UPLOAD_SUCCESS',
  YEARSEMESTER_UPLOAD_ERR: 'YEARSEMESTER_UPLOAD_ERR',

  yearsemesterUploadBegin: () => {
    return {
      type: actions.YEARSEMESTER_UPLOAD_BEGIN,
    };
  },

  yearsemesterUploadSuccess: (data) => {
    return {
      type: actions.YEARSEMESTER_UPLOAD_SUCCESS,
      data,
    };
  },

  yearsemesterUploadErr: (err) => {
    return {
      type: actions.YEARSEMESTER_UPLOAD_ERR,
      err,
    };
  },

  yearsemesterAddBegin: () => {
    return {
      type: actions.YEARSEMESTER_ADD_BEGIN,
    };
  },

  yearsemesterAddSuccess: (data) => {
    return {
      type: actions.YEARSEMESTER_ADD_SUCCESS,
      data,
    };
  },

  yearsemesterAddErr: (err) => {
    return {
      type: actions.YEARSEMESTER_ADD_ERR,
      err,
    };
  },

  yearsemesterReadBegin: () => {
    return {
      type: actions.YEARSEMESTER_READ_BEGIN,
    };
  },

  yearsemesterReadSuccess: (data) => {
    return {
      type: actions.YEARSEMESTER_READ_SUCCESS,
      data,
    };
  },

  yearsemesterReadErr: (err) => {
    return {
      type: actions.YEARSEMESTER_READ_ERR,
      err,
    };
  },

  yearsemesterUpdateBegin: () => {
    return {
      type: actions.YEARSEMESTER_UPDATE_BEGIN,
    };
  },

  yearsemesterUpdateSuccess: (data) => {
    return {
      type: actions.YEARSEMESTER_UPDATE_SUCCESS,
      data,
    };
  },

  yearsemesterUpdateErr: (err) => {
    return {
      type: actions.YEARSEMESTER_UPDATE_ERR,
      err,
    };
  },

  yearsemesterDeleteBegin: () => {
    return {
      type: actions.YEARSEMESTER_DELETE_BEGIN,
    };
  },

  yearsemesterDeleteSuccess: (data) => {
    return {
      type: actions.YEARSEMESTER_DELETE_SUCCESS,
      data,
    };
  },

  yearsemesterDeleteErr: (err) => {
    return {
      type: actions.YEARSEMESTER_DELETE_ERR,
      err,
    };
  },

  yearsemesterSingleDataBegin: () => {
    return {
      type: actions.YEARSEMESTER_SINGLE_DATA_BEGIN,
    };
  },

  yearsemesterSingleDataSuccess: (data) => {
    return {
      type: actions.YEARSEMESTER_SINGLE_DATA_SUCCESS,
      data,
    };
  },

  yearsemesterSingleDataErr: (err) => {
    return {
      type: actions.YEARSEMESTER_SINGLE_DATA_ERR,
      err,
    };
  },
};

export default actions;
