import "./ToolIcon.scss";
import { getShortcutFromShortcutName } from "../actions/shortcuts";
import clsx from "clsx";
import { ToolButton } from "./ToolButton";
import { ExportIcon, ExportImageIcon, LoadIcon, TrashIcon, handIcon, save } from "./icons";
import { KEYS } from "../keys";
// import { getShortcutKey } from "../utils";

type LockIconProps = {
  title?: string;
  name?: string;
  checked: boolean;
  onChange?(): void;
  isMobile?: boolean;
};

export const HandButton = (props: LockIconProps) => {
  return (
    <ToolButton
      className={clsx("Shape", { fillable: false })}
      type="radio"
      icon={handIcon}
      name="editor-current-shape"
      checked={props.checked}
      title={`${props.title} — H`}
      keyBindingLabel={!props.isMobile ? KEYS.H.toLocaleUpperCase() : undefined}
      aria-label={`${props.title} — H`}
      aria-keyshortcuts={KEYS.H}
      data-testid={`toolbar-hand`}
      onChange={() => props.onChange?.()}
    />
  );
};

export const OpenButton = (props: LockIconProps) => {
  return (
    <ToolButton
      className={clsx("Shape", { fillable: false })}
      type="radio"
      icon={LoadIcon}
      name="editor-current-shape"
      checked={props.checked}
      title={`${props.title}`}
      // keyBindingLabel={!props.isMobile ? KEYS.H.toLocaleUpperCase() : undefined}
      aria-label={`${props.title}`}
      aria-keyshortcuts={getShortcutFromShortcutName("loadScene")}
      data-testid={`toolbar-hand`}
      onChange={() => props.onChange?.()}
    />
  );
};

export const SavetoCurrentButton = (props: LockIconProps) => {
  return (
    <ToolButton
      className={clsx("Shape", { fillable: false })}
      type="radio"
      icon={save}
      name="editor-current-shape"
      checked={props.checked}
      title={`${props.title}`}
      // keyBindingLabel={!props.isMobile ? KEYS.H.toLocaleUpperCase() : undefined}
      aria-label={`${props.title}`}
      aria-keyshortcuts={getShortcutFromShortcutName("saveScene")}
      data-testid={`toolbar-hand`}
      onChange={() => props.onChange?.()}
    />
  );
};

export const ExportImageButton = (props: LockIconProps) => {
  return (
    <ToolButton
      className={clsx("Shape", { fillable: false })}
      type="radio"
      icon={ExportImageIcon}
      name="editor-current-shape"
      checked={props.checked}
      title={`${props.title}`}
      // keyBindingLabel={!props.isMobile ? KEYS.H.toLocaleUpperCase() : undefined}
      aria-label={`${props.title}`}
      aria-keyshortcuts={getShortcutFromShortcutName("imageExport")}
      data-testid={`toolbar-hand`}
      onChange={() => props.onChange?.()}
    />
  );
};

export const ResetCanvasButton = (props: LockIconProps) => {
  return (
    <ToolButton
      className={clsx("Shape", { fillable: false })}
      type="radio"
      icon={TrashIcon}
      name="editor-current-shape"
      checked={props.checked}
      title={`${props.title}`}
      // keyBindingLabel={!props.isMobile ? KEYS.H.toLocaleUpperCase() : undefined}
      aria-label={`${props.title}`}
      // aria-keyshortcuts={getShortcutFromShortcutName("imageExport")}
      data-testid={`toolbar-hand`}
      onChange={() => props.onChange?.()}
    />
  );
};

export const ExportButton = (props: LockIconProps) => {
  return (
    <ToolButton
      className={clsx("Shape", { fillable: false })}
      type="radio"
      icon={ExportIcon}
      name="editor-current-shape"
      checked={props.checked}
      title={`${props.title}`}
      // keyBindingLabel={!props.isMobile ? KEYS.H.toLocaleUpperCase() : undefined}
      aria-label={`${props.title}`}
      // aria-keyshortcuts={getShortcutFromShortcutName("imageExport")}
      data-testid={`toolbar-hand`}
      onChange={() => props.onChange?.()}
    />
  );
};

