import react, { useEffect } from 'react'
import { Cards } from '../../../components/cards/frame/cards-frame';
import { PerfomanceOverviewStyle } from '../styles/dashboardStyle';
import Chart from 'react-apexcharts';
import { progressDataRead } from '../../../redux/teacherDashboard/actionCreator';
import { useDispatch, useSelector } from 'react-redux';

function RadialChart({ ProgressData}) {

    const getRadialChartData = () => {
        const data = ProgressData.map((subject) => {
            const percentage = (subject.freezedtopics / subject.totaltopics) * 100;
            return {
                name: subject.subjectName,
                FreezedTopics: subject.freezedtopics,
                totaltopics: subject.totaltopics,
                unfreezedtopics: subject.unfreezedtopics,
                percentage: percentage.toFixed(0),
            };
        });
        return data;
    };

    const radialChartData = getRadialChartData();
    // const avg =
    // radialChartData.length !== 0
    //     ? radialChartData
    //           .filter((data) => data.totaltopics > 0)
    //           .reduce((value, data) => value + parseFloat(data.percentage), 0) /
    //       radialChartData.length
    //     : 0;
    const avg = radialChartData.length !== 0 ? radialChartData.reduce((value, data) => value + parseFloat(data.percentage), 0) / radialChartData.length : 0;
    const labels = radialChartData.map((course) => course.name);
    const dataSets = {
        series: radialChartData.map((data) => data.percentage),
        options: {
            chart: {
                width: '100%',
                height: '100%',
                type: 'radialBar',
                sparkline: {
                    enabled: false,
                },

            },

            tooltip: {
                enabled: true,
                custom: function ({ seriesIndex }) {
                    const courseData = radialChartData[seriesIndex];
                    if (!courseData) {
                        return '';
                    }
                    const { totaltopics, FreezedTopics, unfreezedtopics } = courseData;
                    return `
              <div style="width: 200px; height: 100px; padding: 10px; background-color: #fff;">
                  <div><b>${courseData.name}</b></div>
                  <div>Total Topics: ${totaltopics}</div>
                  <div>Freezed Topics: ${FreezedTopics}</div>
                  <div>UnFreezed Topics: ${unfreezedtopics}</div>
                </div>
              `;
                },
            },
            colors: ['#ff4f00', '#00ff4f', '#4f00ff', '#ff0031'],
            plotOptions: {
                radialBar: {
                    hollow: {
                        size: '30%',
                    },
                    track: {
                        show: true,
                        margin: 15,
                    },
                    dataLabels: {
                        show: true,
                        name: {
                            offsetY: 15,
                            fontSize: '10px',
                            formatter: function () {
                                return 'Topics Freezed';
                            },
                        },
                        value: {
                            fontSize: '20px',
                            fontFamily: '"Jost", sans-serif',
                            fontWeight: 600,
                            offsetY: -21,
                        },
                        total: {
                            show: true,
                            fontSize: '12px',
                            fontFamily: '"Jost", sans-serif',
                            color: '#404040',
                            formatter: function () {
                                return `${avg.toFixed(0)}% avg`;
                            },
                        },
                    },
                },
                stroke: {
                    lineCap: 'round',
                },
            },
        }
    };

    return (
        <div>
            <Cards title="My Subjects" >
                <PerfomanceOverviewStyle className="ninjadash-chartpoint-vertical">
                    <Chart
                        options={dataSets.options}
                        series={dataSets.series}
                        type="radialBar"
                        width={360}
                    />
                    <div className="ninjadash-chartpoint">
                        {dataSets.series.map((value, index) => {
                            return (
                                <div className="ninjadash-chartpoint__item" key={index}>
                                    <span
                                        className="ninjadash-chartpoint__tika"
                                        style={{
                                            backgroundColor: dataSets.options.colors[index],
                                        }}
                                    />
                                    <span className="ninjadash-chartpoint__label">{labels[index]}</span>
                                </div>
                            );
                        })}
                    </div>
                </PerfomanceOverviewStyle>
            </Cards>
        </div>
    );
}

export default RadialChart