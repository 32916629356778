import { message, notification } from 'antd';
import actions from './actions';
import { DataService } from '../../config/dataService/dataService';
import { logOut } from '../authentication/actionCreator';
import { getItem } from '../../utility/localStorageControl';
const updateNotificationError = (err) => {
  notification.error({
    message: err,
  });
};

const updateNotificationSuccess = () => {
  notification.success({
    message: 'Topic Scheduled Successfully',
  });
};

const {
  updateScheduleBegin,
  updateScheduleSuccess,
  updateScheduleErr,

  getScheduleBegin,
  getScheduleSuccess,
  getScheduleErr,

  getScheduledDataBegin,
  getScheduledDataSuccess,
  getScheduledDataErr,

  getCureentWeekBegin,
  getCureentWeekSuccess,
  getCureentWeekErr,

  getregyrsemBegin,
  getregyrsemSuccess,
  getregyrsemErr,
  
  getNgrokURLBegin,
  getNgrokURLSuccess,
  getNgrokURLErr

} = actions;

const updateSchedule = (formData) => {
  return async (dispatch) => {
    try {
      await dispatch(updateScheduleBegin());
      const response = await DataService.put('/topicmaster/schedule', formData);
      const token = getItem('access_token');
      const data = response.data
      if (token && data !== null && data.Error === false) {
        await dispatch(updateScheduleSuccess(data));
        updateNotificationSuccess();
      }
      else if (data !== null && data.Error === true) {
        if (Object.keys(data).length >= 3 && data.statusCode == 401) {
          message.error(data.message)
          await dispatch(updateScheduleSuccess(null));
          dispatch(logOut())
        }
        else {
          await dispatch(updateScheduleSuccess(null));
        }
      }
      else {
        await dispatch(updateScheduleSuccess(null));
      }
    } catch (err) {
      await dispatch(updateScheduleErr(err));
      updateNotificationError(err);
    }
  };
};

const getScheduleWeeks = (regulationId,yearsemesterId) => {
  return async (dispatch) => {    
    try {
      await dispatch(getScheduleBegin());
      const query = await DataService.get(`/schedule/getbyregyrs/${regulationId}/${yearsemesterId}`);
      const token = getItem('access_token');
      if (token && query.data !== null && query.data.Error === false) {
        await dispatch(getScheduleSuccess(query.data.payload));
      }
      else {
        await dispatch(getScheduleSuccess([]));
      }
    } catch (err) {
      await dispatch(getScheduleErr(err));
    }
  };
};

const getScheduledData = (topicId) => {
  return async (dispatch) => {
    try {
      await dispatch(getScheduledDataBegin());
      const query = await DataService.get(`/topicmaster/schedule/${topicId}`);
      const token = getItem('access_token');
      const data = query.data
      if (token && data !== null && data.Error === false) {
        await dispatch(getScheduledDataSuccess(data.payload));
      }
      else if (data !== null && data.Error === true) {
        if (Object.keys(data).length >= 3 && data.statusCode == 401) {
          message.error(data.message)
          await dispatch(getScheduledDataSuccess(null));
          dispatch(logOut())
        }
        else {
          await dispatch(getScheduledDataSuccess(null));
        }
      }
      else {
        await dispatch(getScheduledDataSuccess(null));
      }
    } catch (err) {
      await dispatch(getScheduledDataErr(err));
    }
  };
};

const getCurrentWeek = () => {
  return async (dispatch) => {
    try {
      const regulationId = getItem('regulationId')
      const yearsemesterId = getItem('yearsemesterId')
      await dispatch(getCureentWeekBegin());
      const query = await DataService.get(`/schedule/today/${regulationId}/${yearsemesterId}`);
      const token = getItem('access_token');
      if (token && query.data !== null && query.data.Error === false) {
        await dispatch(getCureentWeekSuccess(query.data.payload));
      } else {
        await dispatch(getCureentWeekSuccess(null));
      }
    } catch (err) {
      await dispatch(getCureentWeekErr(err));
    }
  };
};

const getRegulationYearsems = (subjectId) => {
  return async (dispatch) => {
    try {         
      const userId = getItem('userId')   
      await dispatch(getregyrsemBegin());
      const query = await DataService.get(`/teachermaster/regsyrs/${subjectId}/${userId}`);
      const token = getItem('access_token');      
      if (token && query.data !== null && query.data.Error === false) {
        await dispatch(getregyrsemSuccess(query.data.payload));
      } else {
        await dispatch(getregyrsemSuccess(null));
      }
    } catch (err) {
      await dispatch(getregyrsemErr(err));
    }
  };
};

const getNgrokURL = () => {
  return async (dispatch) => {
    try {            
      await dispatch(getNgrokURLBegin());
      const query = await DataService.get(`/tesselator/ngrokurl`);
      const token = getItem('access_token');            
      if (token && query.data !== null && query.data.Error === false) {
        await dispatch(getNgrokURLSuccess(query.data.payload));
      } else {
        await dispatch(getNgrokURLSuccess(null));
      }
    } catch (err) {
      await dispatch(getNgrokURLErr(err));
    }
  };
};

export {
  updateSchedule,
  getScheduleWeeks,
  getScheduledData,
  getCurrentWeek,
  getRegulationYearsems,
  getNgrokURL
};
