import { notification , message } from 'antd';
import Cookies from 'js-cookie';
import actions from './actions';
import { DataService } from '../../config/dataService/dataService';
import { logOut } from '../authentication/actionCreator';
import { getItem } from '../../utility/localStorageControl';


  const {

    topicProgrammingBegin,
    topicProgrammingSuccess,
    topicProgrammingErr,

    ProgrammingTesselatorBegin,
    ProgrammingTesselatorSuccess,
    ProgrammingTesselatorErr,

} = actions;


const getProgrammingById = (topicId) => {
    return async (dispatch) => {
      try {
        await dispatch(topicProgrammingBegin());
        const query = await DataService.get(`/qa-program-attempts/programs/${topicId}`);      
        const token = getItem('access_token');
        const prog = query.data
        // console.log('Programming',prog);
        if (token && prog.Error === false) {          
          await dispatch(topicProgrammingSuccess(prog.payload));
        } else {
        if (query.data.Error === true && query.data.statusCode === 401){
          message.error(query.data.message)
          dispatch(logOut())
        }
        throw query.data.message;
      }
      } catch (err) {
        await dispatch(topicProgrammingErr(err));
      }
    };
  };

  const getTesslatorDetails = () => {    
    return async (dispatch) => {
      try {
        await dispatch(ProgrammingTesselatorBegin());
        const userId= getItem('userId');        
        const query = await DataService.get(`/studentmaster/telescopedetails/${userId}`);      
        const token = getItem('access_token');
        if (token && query.data.Error === false) {
          // console.log(query.data.payload);
          await dispatch(ProgrammingTesselatorSuccess(query.data.payload));          
        }
        else {
          if (query.data.Error === true && query.data.statusCode === 401){
            // message.error(query.data.message)
            dispatch(logOut())
          }
          throw query.data.message;
        }
      } catch (err) {
        await dispatch(ProgrammingTesselatorErr(err));
      }
    };
  };


  export{
    getProgrammingById,
    getTesslatorDetails,
  };