import react, { useEffect, useState } from 'react'
import { Main } from '../styled'
import { Cards } from '../../components/cards/frame/cards-frame'
import { DataTableStyleWrap } from '../../components/table/Style'
import { Input, Select, Table, message } from 'antd'
import { Button } from '../../components/buttons/buttons'
import CustomSpinner from '../ui-elements/spinner/CustomSpinner'
import { useDispatch, useSelector } from 'react-redux'
import UilSearch from '@iconscout/react-unicons/icons/uil-search';
import { UpdateTopicAssessmentFlag, topicsDataRead } from '../../redux/teacherTopicsMenu/actionCreator'
import { alertModal } from '../../components/modals/antd-modals'
import actions from '../../redux/teacherTopicsMenu/actions'

const { ReadTopicsSuccess } = actions

function LockUnlockLAssessmenTopics() {

    const dispatch = useDispatch()
    const [subjectId, setSubjectId] = useState(0)
    const [unitId, setUnitId] = useState(0);

    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);

    const { SubjectsUnits, SubjectsUnitsLoading, Topics, isTopicsLoading, UpdateRes, isUpdating } = useSelector((state) => {
        return {
            SubjectsUnits: state.SubjectsUnits.data,
            SubjectsUnitsLoading: state.SubjectsUnits.loading,
            Topics: state.Topics.data,
            isTopicsLoading: state.Topics.loading,
            UpdateRes: state.AssessmentFlagRes.data,
            isUpdating: state.AssessmentFlagRes.loading,
        };
    });

    useEffect(() => {
        dispatch(ReadTopicsSuccess([]))
    }, [])

    const rowSelection = (selectedRowKeys, selectedRows) => {
        setSelectedRows(selectedRows)
        setSelectedRowKeys(selectedRowKeys)
    };

    const loadData = () => {
        if (subjectId !== 0 && unitId !== 0) {
            dispatch(topicsDataRead({ subjectId, unitId }));
        } else {
            message.warn("Select all filters!!");
        }
    };

    useEffect(() => {
        if (UpdateRes !== null && UpdateRes.Error === false) {
            setSelectedRowKeys([])
            setSelectedRows([])
            if (subjectId !== 0 && unitId !== 0) {
                dispatch(topicsDataRead({ subjectId, unitId }));
            }
        }
        else if (UpdateRes !== null && UpdateRes.Error === true) {
            message.error(UpdateRes !== null && UpdateRes.message)
        }
    }, [UpdateRes])

    const columns = [
        {
            title: 'S.No.',
            dataIndex: 'sno',
        },
        {
            title: 'Topic',
            dataIndex: 'topic',
        },
        {
            title: 'Status',
            dataIndex: 'status',
        },
        {
            title: 'State',
            dataIndex: 'assessmentFlag',
        },
    ];


    const [tableData, setTableData] = useState([])
    const [filteredData, setFilteredData] = useState([])

    useEffect(() => {
        let data = []
        Topics.length !== 0 && Topics.map((obj, index) => {
            const { id, topic, status, assessmentFlag } = obj
            data.push({
                key: index,
                "sno": index + 1,
                id,
                topic,
                status,
                assessmentFlag: assessmentFlag === true ? <span className='text-success'>Unlocked</span> : <span className='text-danger'>Locked</span>,
            })
        })
        setTableData(data)
        setFilteredData(data)
    }, [Topics])

    const UpdateAssessmentFlag = (type, state) => {
        alertModal.confirm({
            title: `${type} the following topics to students?`,
            content: <>
                {
                    selectedRows.length !== 0 && selectedRows.map((row) =>
                        <p key={row.sno}>{row.sno}. {row.topic}</p>
                    )
                }
            </>,
            okText: 'Yes',
            cancelText: 'No',
            onOk() {
                if (selectedRows.length !== 0) {
                    const ids = []
                    selectedRows.length !== 0 && selectedRows.map((item) => ids.push(parseInt(item.id)))
                    let postdata = { flag: state, ids: ids }
                    dispatch(UpdateTopicAssessmentFlag(postdata))
                }
            },
            onCancel() { },
            width: 650
        });
    }

    return (
        <div>
            <DataTableStyleWrap>
                <div className="ninjadash-datatable-filter">
                    <div className="ninjadash-datatable-filter__left">

                        <div className="ninjadash-datatable-filter__input">
                            <span className="label">Subject:</span>
                            <Select style={{ width: 400 }} className="ninjadash-data-status" placeholder="Select Subject" onChange={(e) => { setSubjectId(e); setUnitId(0) }} loading={SubjectsUnitsLoading}>
                                {
                                    SubjectsUnits !== null && SubjectsUnits.subjects.length !== 0 && SubjectsUnits.subjects.sort((a, b) => a.name > b.name ? 1 : -1).map((subjectObj, subjectIndex) =>
                                        <Select.Option value={subjectObj.id} key={subjectIndex}>{subjectObj.name}</Select.Option>
                                    )
                                }
                            </Select>
                        </div>
                        <div className="ninjadash-datatable-filter__input">
                            <span className="label">Unit:</span>
                            <Select style={{ width: 200 }} className="ninjadash-data-status" placeholder="Select Unit" value={unitId} onChange={(e) => { setUnitId(e) }} loading={SubjectsUnitsLoading}>
                                <Select.Option value={0} key={'selectUnit'} >Select</Select.Option>
                                {subjectId !== 0 && SubjectsUnits !== null && SubjectsUnits.subjects.length !== 0 && SubjectsUnits.subjects.filter((a) => a.id === subjectId)[0].units.sort((a, b) => a.name > b.name ? 1 : -1).map((unit) => (
                                    <Select.Option key={unit.id} value={unit.id}>
                                        {unit.name}
                                    </Select.Option>
                                ))}
                            </Select>
                        </div>
                        <div className="ninjadash-datatable-filter__action">
                            <Button type="primary" size="small" onClick={() => { loadData() }}>
                                Get Topics
                            </Button>
                        </div>
                    </div>
                </div>
                {/* <div className="mb-20" style={{ maxWidth: '350px', marginLeft: 'auto' }}>
                            <Input className='' onChange={searchStudent} size="default" placeholder="Search by Rollno. or Name" prefix={<UilSearch />} />
                        </div> */}
                <div className="text-right mb-20">
                    <Button
                        id="assessmenttopics_unlock-bulk-button"
                        type='primary'
                        onClick={() => {
                            UpdateAssessmentFlag('Unlock', true)
                        }}
                        disabled={selectedRows.length === 0}
                        className="mr-10"
                    >
                        {/* {FlagUpdateResLoading ? 'Loading...' : 'Enable'} */}
                        Unlock
                    </Button>
                    <Button
                        id="assessmenttopics_lock-bulk-button"
                        type='primary'
                        onClick={() => {
                            UpdateAssessmentFlag('Lock', false)
                        }}
                        disabled={selectedRows.length === 0}
                        className="mr-10"
                    >
                        {/* {FlagUpdateResLoading ? 'Loading...' : 'Enable'} */}
                        Lock
                    </Button>
                </div>
                {
                    isTopicsLoading ?
                        <div style={{ width: '100%', height: '400px' }}>
                            <div className="custom-spin">
                                <CustomSpinner Message={'Fetching data. Please wait...'} />
                            </div>
                        </div>
                        :
                        <div>
                            <Table
                                className="table-responsive"
                                pagination={{
                                    defaultPageSize: 15,
                                    total: filteredData.length,
                                    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                                }}
                                columns={columns}
                                dataSource={filteredData}
                                tableLayout='auto'
                                size='middle'
                                rowSelection={{ selectedRowKeys, onChange: rowSelection }}
                                rowClassName={record => record.status == "NOTHING" && 'disabled-row'}
                            />
                        </div>
                }
            </DataTableStyleWrap>
        </div>
    )
}

export default LockUnlockLAssessmenTopics