import { notification } from 'antd';
import actions from './actions';
import { DataService } from '../../config/dataService/dataService';
import { getItem } from '../../utility/localStorageControl';

const addNotificationSuccess = () => {
  notification.success({
    message: 'Your Record has been Submited',
  });
};

const addNotificationError = (err) => {
  notification.error({
    message: err,
  });
};

const deleteNotificationSuccess = () => {
  notification.success({
    message: 'Your Record has been Deleted',
  });
};

const deleteNotificationError = (err) => {
  notification.error({
    message: err,
  });
};

const updateNotificationSuccess = () => {
  notification.success({
    message: 'Your Record has been updated',
  });
};

const updateNotificationError = (err) => {
  notification.error({
    message: err,
  });
};

const {
  subjectsAddBegin,
  subjectsAddSuccess,
  subjectsAddErr,

  subjectsReadBegin,
  subjectsReadSuccess,
  subjectsReadErr,

  subjectsByBranchReadBegin,
  subjectsByBranchReadSuccess,
  subjectsByBranchReadErr,

  subjectsUpdateBegin,
  subjectsUpdateSuccess,
  subjectsUpdateErr,

  subjectsDeleteBegin,
  subjectsDeleteSuccess,
  subjectsDeleteErr,

  subjectsSingleDataBegin,
  subjectsSingleDataSuccess,
  subjectsSingleDataErr,

  subjectsUploadBegin,
  subjectsUploadSuccess,
  subjectsUploadErr,
} = actions;

const subjectsDataSubmit = (data) => {
  return async (dispatch) => {
    try {
      await dispatch(subjectsAddBegin());
      const response = await DataService.post('/subjectmaster/create', data);
      const token = getItem('access_token');
      await dispatch(subjectsAddSuccess(response.data));
      if (token) {
        addNotificationSuccess();
      } else {
        await dispatch(subjectsAddErr('Unauthorized access'));
        addNotificationError('Unauthorized access');
      }
    } catch (err) {
      await dispatch(subjectsAddErr(err));
      addNotificationError(err);
    }
  };
};

const subjectsDataRead = () => {
  return async (dispatch) => {
    try {
      await dispatch(subjectsReadBegin());
      const query = await DataService.get('/regulation/all');
      const token = getItem('access_token');
      await dispatch(subjectsReadSuccess(query.data));
      if (token) {
      } else {
        await dispatch(subjectsReadSuccess([]));
        // await dispatch(subjectsAddErr('Unauthorized access'));
      }
    } catch (err) {
      await dispatch(subjectsReadErr(err));
    }
  };
};

const readSubjectsByBRanch = (branchId) => {
  return async (dispatch) => {
    try {
      await dispatch(subjectsByBranchReadBegin());
      const query = await DataService.get(`/regulation/branch/${branchId}`);
      const token = getItem('access_token');
      await dispatch(subjectsByBranchReadSuccess(query.data[0]));      
      if (token) {
      } else {
        await dispatch(subjectsByBranchReadSuccess([]));
        // await dispatch(subjectsAddErr('Unauthorized access'));
      }
    } catch (err) {
      await dispatch(subjectsByBranchReadErr(err));
    }
  };
};

const subjectsDataSearch = (searchItem) => {
  return async (dispatch) => {
    try {
      await dispatch(subjectsReadBegin());
      if (searchItem !== '') {
        const query = await DataService.get(`/data/search/${searchItem}`);
        await dispatch(subjectsReadSuccess(query.data.data));
      } else {
        try {
          const query = await DataService.get('/data/all');
          await dispatch(subjectsReadSuccess(query.data.data));
        } catch (err) {
          await dispatch(subjectsReadErr(err));
        }
      }
    } catch (err) {
      await dispatch(subjectsReadErr(err));
    }
  };
};

const subjectsDataUpdate = (data) => {
  return async (dispatch) => {
    try {
      await dispatch(subjectsUpdateBegin());
      await DataService.put(`/subjects/update`, data);
      await dispatch(subjectsUpdateSuccess());
      updateNotificationSuccess();
    } catch (err) {
      await dispatch(subjectsUpdateErr(err));
      updateNotificationError(err);
    }
  };
};

const subjectsDataDelete = ({ id, getData }) => {
  return async (dispatch) => {
    try {
      await dispatch(subjectsDeleteBegin());
      const data = await DataService.post(`/${id}/delete`, {});

      await dispatch(subjectsDeleteSuccess(data.data));
      await getData();
      deleteNotificationSuccess();
    } catch (err) {
      await dispatch(subjectsDeleteErr(err));
      deleteNotificationError(err);
    }
  };
};

const subjectsDataSingle = (id) => {
  return async (dispatch) => {
    try {
      await dispatch(subjectsSingleDataBegin());
      const query = await DataService.get(`/data/${id}/show`);
      await dispatch(subjectsSingleDataSuccess(query.data.data[0]));
    } catch (err) {
      await dispatch(subjectsSingleDataErr(err));
    }
  };
};

const subjectsFileUploder = (imageAsFile) => {
  const data = new FormData();
  data.append('image', imageAsFile);

  return async (dispatch) => {
    try {
      await dispatch(subjectsUploadBegin());
      const query = await DataService.post('/data/image/upload', data, { 'Content-Type': 'multipart/form-data' });

      dispatch(subjectsUploadSuccess(`${query.data}`));
    } catch (err) {
      await dispatch(subjectsUploadErr(err));
    }
  };
};

const subjectsFileClear = () => {
  return async (dispatch) => {
    try {
      await dispatch(subjectsUploadBegin());
      dispatch(subjectsUploadSuccess(null));
    } catch (err) {
      await dispatch(subjectsUploadErr(err));
    }
  };
};

export {
  subjectsDataRead,
  readSubjectsByBRanch,
  subjectsDataSearch,
  subjectsDataSubmit,
  subjectsFileUploder,
  subjectsDataDelete,
  subjectsDataSingle,
  subjectsDataUpdate,
  subjectsFileClear,
};
