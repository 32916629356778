import React, { useEffect, useState } from 'react'
import { CSVLink } from 'react-csv'
import { Button } from '../../components/buttons/buttons'
import { ProjectSorting } from '../project/style'
import { Link } from 'react-router-dom'
import { FaqWrapper } from '../pages/style'
import { Collapse, Result } from 'antd'
import UilPlus from '@iconscout/react-unicons/icons/uil-plus';
import UilMinus from '@iconscout/react-unicons/icons/uil-minus';

const { Panel } = Collapse;

function ViewQA({ QA }) {

    //export
    const headers = [
        { label: "Question", key: "question" },
        { label: "Answer", key: "answer" },
        // { label: "Type", key: "type" },
    ];

    const [exportData, setExportData] = useState([])

    useEffect(() => {
        if (QA.length !== 0) {
            const data = []
            QA.map((obj) => data.push({ question: obj.question, answer: obj.answer }))
            setExportData(data)
        }
    }, [QA])

    const csvReport = {
        filename: "QA_List.csv",
        headers: headers,
        data: exportData
    }

    return (
        <div>
            <div className='tabs-flixed-height'>
                <div className='align-center-v d-flex justify-content-between mb-10'>
                    <div className='align-center-v'>
                        <h3 className="mb-0 mr-10">Q & A</h3>
                        <CSVLink {...csvReport}>
                            <Button id="monitor_export-button" type="primary" size="small" disabled={QA.length !== 0 ? false : true}>
                                Export
                            </Button>
                        </CSVLink>
                    </div>
                    {/* <ProjectSorting>
                        <div className="project-sort-bar">
                            <div className="project-sort-nav" style={{ boxShadow: '0px 0px 15px 0px #eee', borderRadius: '6px' }}>
                                <nav>
                                    <ul>
                                        <li className={selectedCategory === 'Easy' ? 'active' : 'deactivate'}>
                                            <Link id="viewmcq_easy-button" onClick={() => { setCategory('Easy') }} to="#">
                                                Easy ({topic !== null && topic.quizes[`easy`].length})
                                            </Link>
                                        </li>
                                        <li className={selectedCategory === 'Medium' ? 'active' : 'deactivate'}>
                                            <Link id="viewmcq_medium-button" onClick={() => { setCategory('Medium') }} to="#">
                                                Medium ({topic !== null && topic.quizes[`medium`].length})
                                            </Link>
                                        </li>
                                        <li className={selectedCategory === 'Hard' ? 'active' : 'deactivate'}>
                                            <Link id="viewmcq_hard-button" onClick={() => { setCategory('Hard') }} to="#">
                                                Hard ({topic !== null && topic.quizes[`hard`].length})
                                            </Link>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </ProjectSorting> */}
                </div>
                <FaqWrapper>
                    {
                        QA.length !== 0 ? QA.map((Object, index) =>
                            <MyForm key={index} index={index + 1} currentQA={Object} />
                        )
                            :
                            <div className='custom-spin'>
                                <Result
                                    status={'warning'}
                                    title="MCQs not found"
                                />
                            </div>
                    }
                </FaqWrapper>
            </div>
        </div>
    )
}

export default ViewQA


const MyForm = ({ currentQA, index }) => {
    return (
        <div>
            <Collapse defaultActiveKey={['1']} bordered={false} expandIcon={({ isActive }) => (isActive ? <UilMinus /> : <UilPlus />)} className='m-0 mb-5' >
                <Panel header={index + '. ' + currentQA.question} key={index}>
                    <h4>Answer</h4>
                    <p style={{ textAlign: 'justify' }} className='m-0'>
                        {currentQA.answer}
                    </p>
                </Panel>
            </Collapse>
        </div>
    );
}