
import React, { useState, useEffect } from 'react';
import { Row, Col, Table, Input, Modal, Form, Space, Select, Tag } from 'antd';
import UilEdit from '@iconscout/react-unicons/icons/uil-edit';
import UilTrash from '@iconscout/react-unicons/icons/uil-trash-alt';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Cards } from '../../components/cards/frame/cards-frame';
import { TableWrapper, Main } from '../styled';
import { PageHeader } from '../../components/page-headers/page-headers';
import { useDispatch, useSelector } from 'react-redux';
import { regulationDataRead, regulationDataSubmit, regulationDataUpdate } from '../../redux/regulation/actionCreator';
import {UserTableStyleWrapper} from './styles/tablestyle'
import { ProjectList, ProjectSorting } from '../project/style';
import { Link } from 'react-router-dom';
import { AutoComplete } from '../../components/autoComplete/autoComplete';
import { Button } from '../../components/buttons/buttons';

const { Option } = Select;

function Regulations() {

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [form] = Form.useForm();
  const [editForm] = Form.useForm();

  
  const [editRecord, setEditRecord] = useState(null);
  
  const dispatch = useDispatch();
  const { regulations, isLoading } = useSelector((state) => {
    return {
      regulations: state.Regulations.data,
      isLoading: state.Regulations.loading,
    };
  });
  
  useEffect(() => {
    if (dispatch) {
      dispatch(regulationDataRead());
    }
  }, [dispatch]);
  
  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(()=> {
    if(regulations){
      setDataSource(regulations)
      setLoading(false);
    }
  },[regulations])
  
  const [statusFilter,setStatusFilter] = useState("All")

  const onChangeCategory = (value) => {
    if(value === 'All')
    {
      setDataSource(regulations)
      return;
    }
    setStatusFilter(value);    
    let filteredData = regulations.filter(element => element.status.toLowerCase() === value.toLowerCase());
    setDataSource(filteredData)
  };

  const handleSearch = (searchText) => {
    const data = regulations.filter(item=>item.code.toLowerCase().includes(searchText.toLowerCase()));
    setDataSource(data)
  };

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Code',
      dataIndex: 'code',
      key: 'code',      
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'Created On',
      dataIndex: 'createdon',
      key: 'createdon',
    },
    {
      title: 'Updated On',
      dataIndex: 'updatedon',
      key: 'updatedon',
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (text, record) => (
        <div className='table-actions'>
          <Button onClick={() => handleEdit(record)} className="btn-icon " type="primary" to="#" shape="circle">
            <UilEdit />
          </Button>      
          {/* <Button icon={<UilTrash />} onClick={() => handleDelete(record)}>
          </Button> */}
        </div>
      ),
    },
  ];

  const handleButtonClick = () => {
    setIsModalVisible(true);
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
  };

  const handleModalAdd = () => {
    form.validateFields().then((values) => {
      const newRow = {
        key: String(dataSource.length + 1),
        id: String(dataSource.length + 1),
        code: values.code,
        status: values.status || 'ACTIVE',
      
      };
      setDataSource([...dataSource, newRow]);
      form.resetFields();
      setIsModalVisible(false);
      dispatch(regulationDataSubmit(newRow));
    });
  };

  const handleModalUpdate = () => {
    editForm.validateFields().then((values) => {
      const updatedRecord = {
        ...editRecord,
        code: values.code,
        status: values.status,
      };

      // const updatedDataSource = dataSource.map((item) =>
      //   item.id === updatedRecord.id ? updatedRecord : item
      // );

      // setDataSource(updatedDataSource);
      setIsEditModalVisible(false);
      // delete updatedRecord['createdon']
      // delete updatedRecord['updatedon']
      dispatch(regulationDataUpdate(updatedRecord));
    });
  };

  const handleEdit = (record) => {
    setEditRecord(record);
    setIsEditModalVisible(true);
    editForm.setFieldsValue({
      code: record.code,
      status: record.status,
    });
  };

  const handleDelete = (record) => {
    Modal.confirm({
      title: 'Are you sure you want to delete this record?',
      icon: <ExclamationCircleOutlined />,
      content: 'Once deleted the action can be undone',
      okText: 'Delete',
      okType: 'danger',
      cancelText: 'Cancel',
      onOk() {
        const updatedDataSource = dataSource.filter((item) => item.id !== record.id);
        const updatedDataSourceWithSNo = updatedDataSource.map((item, index) => ({
          ...item,
          id: String(index + 1),
          key: String(index + 1),
        }));
        setDataSource(updatedDataSourceWithSNo);
      },
    });
  };

  const showEditModal = (record) => {
    handleEdit(record);
  };

  return (
    <>        
      <PageHeader className="ninjadash-page-header-main" title="Regulations" 
        buttons={[
            <Button onClick={handleButtonClick} key="1" type="primary" size="default">
              Add Regulation
            </Button>,
          ]}/>    
      <Main>
        <Row gutter={25}>
          <Col sm={24} xs={24}>
          <ProjectSorting>
              <div className="project-sort-bar">
                <div className="project-sort-nav">
                  <nav>
                    <ul>
                      <li className={statusFilter === 'All' ? 'active' : 'deactivate'}>
                        <Link onClick={() => onChangeCategory('All')} to="#">
                          All
                        </Link>
                      </li>
                      <li className={statusFilter === 'Active' ? 'active' : 'deactivate'}>
                        <Link onClick={() => onChangeCategory('Active')} to="#">
                          Active
                        </Link>
                      </li>
                      <li className={statusFilter === 'Inactive' ? 'active' : 'deactivate'}>
                        <Link onClick={() => onChangeCategory('Inactive')} to="#">
                          Inactive
                        </Link>
                      </li>                      
                    </ul>
                  </nav>
                </div>
                <div className="project-sort-search">
                  <AutoComplete onSearch={handleSearch} dataSource={dataSource} placeholder="Search code" patterns />
                </div>
              </div>
            </ProjectSorting>
            <Cards headless>
              <TableWrapper>
                <ProjectList>
                  <div className="table-responsive">
                    <Table dataSource={dataSource} columns={columns} />
                  </div>
                </ProjectList>
              </TableWrapper>
            </Cards>
          </Col>
        </Row>        
      </Main>
      <Modal
        title="Add Regulation"
        open={isModalVisible}
        onCancel={handleModalCancel}
        footer={[
          <Button key="add" type="primary" onClick={handleModalAdd}>
            Add
          </Button>,
        ]}
      >
        <Form form={form} layout="vertical">
          <Form.Item name="code" label="Code" rules={[{ required: true }]}>
            <Input/>
          </Form.Item>
          <Form.Item name="status" label="Status">
            <Select defaultValue="ACTIVE">
              <Option value="ACTIVE">ACTIVE</Option>
              <Option value="INACTIVE">INACTIVE</Option>
            </Select>
          </Form.Item>
          {/* <Form.Item name="year" label="Year" rules={[{ required: true }]}>
          <Select placeholder="Select Year">
            <Option value="1st">1st</Option>
            <Option value="2nd">2nd</Option>
            <Option value="3rd">3rd</Option>
            <Option value="4th">4th</Option>
          </Select>
          </Form.Item> */}
        </Form>
      </Modal>
      <Modal
        title="Edit Regulation"
        open={isEditModalVisible}
        onCancel={() => setIsEditModalVisible(false)}
        footer={[
          <Button key="update" type="primary" onClick={handleModalUpdate}>
            Update
          </Button>
        ]}
      >
        <Form form={editForm} layout="vertical">
        <Form.Item name="code" label="Code" rules={[{ required: true }]}>
            <Input/>
          </Form.Item>
          <Form.Item name="status" label="Status" rules={[{ required: true }]}>
            <Select>
              <Option value="ACTIVE">ACTIVE</Option>
              <Option value="INACTIVE">INACTIVE</Option>
            </Select>
          </Form.Item>
          {/* <Form.Item name="year" label="Year" rules={[{ required: true }]}>
          <Select>
            <Option value="1st">1st</Option>
            <Option value="2nd">2nd</Option>
            <Option value="3rd">3rd</Option>
            <Option value="4th">4th</Option>
          </Select>
          </Form.Item> */}
        </Form>
      </Modal>
    </>
  );
}

export default Regulations;