const actions = {
  SUBJECTS_ADD_BEGIN: 'SUBJECTS_ADD_BEGIN',
  SUBJECTS_ADD_SUCCESS: 'SUBJECTS_ADD_SUCCESS',
  SUBJECTS_ADD_ERR: 'SUBJECTS_ADD_ERR',

  SUBJECTS_READ_BEGIN: 'SUBJECTS_READ_BEGIN',
  SUBJECTS_READ_SUCCESS: 'SUBJECTS_READ_SUCCESS',
  SUBJECTS_READ_ERR: 'SUBJECTS_READ_ERR',

  SUBJECTS_BY_BRANCH_READ_BEGIN: 'SUBJECTS_BY_BRANCH_READ_BEGIN',
  SUBJECTS_BY_BRANCH_READ_SUCCESS: 'SUBJECTS_BY_BRANCH_READ_SUCCESS',
  SUBJECTS_BY_BRANCH_READ_ERR: 'SUBJECTS_BY_BRANCH_READ_ERR',

  SUBJECTS_UPDATE_BEGIN: 'SUBJECTS_UPDATE_BEGIN',
  SUBJECTS_UPDATE_SUCCESS: 'SUBJECTS_UPDATE_SUCCESS',
  SUBJECTS_UPDATE_ERR: 'SUBJECTS_UPDATE_ERR',

  SUBJECTS_DELETE_BEGIN: 'SUBJECTS_DELETE_BEGIN',
  SUBJECTS_DELETE_SUCCESS: 'SUBJECTS_DELETE_SUCCESS',
  SUBJECTS_DELETE_ERR: 'SUBJECTS_DELETE_ERR',

  SUBJECTS_SINGLE_DATA_BEGIN: 'SUBJECTS_SINGLE_DATA_BEGIN',
  SUBJECTS_SINGLE_DATA_SUCCESS: 'SUBJECTS_SINGLE_DATA_SUCCESS',
  SUBJECTS_SINGLE_DATA_ERR: 'SUBJECTS_SINGLE_DATA_ERR',

  SUBJECTS_UPLOAD_BEGIN: 'SUBJECTS_UPLOAD_BEGIN',
  SUBJECTS_UPLOAD_SUCCESS: 'SUBJECTS_UPLOAD_SUCCESS',
  SUBJECTS_UPLOAD_ERR: 'SUBJECTS_UPLOAD_ERR',

  subjectsUploadBegin: () => {
    return {
      type: actions.SUBJECTS_UPLOAD_BEGIN,
    };
  },

  subjectsUploadSuccess: (data) => {
    return {
      type: actions.SUBJECTS_UPLOAD_SUCCESS,
      data,
    };
  },

  subjectsUploadErr: (err) => {
    return {
      type: actions.SUBJECTS_UPLOAD_ERR,
      err,
    };
  },

  subjectsAddBegin: () => {
    return {
      type: actions.SUBJECTS_ADD_BEGIN,
    };
  },

  subjectsAddSuccess: (data) => {
    return {
      type: actions.SUBJECTS_ADD_SUCCESS,
      data,
    };
  },

  subjectsAddErr: (err) => {
    return {
      type: actions.SUBJECTS_ADD_ERR,
      err,
    };
  },

  subjectsByBranchReadBegin: () => {
    return {
      type: actions.SUBJECTS_BY_BRANCH_READ_BEGIN,
    };
  },

  subjectsByBranchReadSuccess: (data) => {
    return {
      type: actions.SUBJECTS_BY_BRANCH_READ_SUCCESS,
      data,
    };
  },

  subjectsByBranchReadErr: (err) => {
    return {
      type: actions.SUBJECTS_BY_BRANCH_READ_ERR,
      err,
    };
  },

  subjectsReadBegin: () => {
    return {
      type: actions.SUBJECTS_READ_BEGIN,
    };
  },

  subjectsReadSuccess: (data) => {
    return {
      type: actions.SUBJECTS_READ_SUCCESS,
      data,
    };
  },

  subjectsReadErr: (err) => {
    return {
      type: actions.SUBJECTS_READ_ERR,
      err,
    };
  },

  subjectsUpdateBegin: () => {
    return {
      type: actions.SUBJECTS_UPDATE_BEGIN,
    };
  },

  subjectsUpdateSuccess: (data) => {
    return {
      type: actions.SUBJECTS_UPDATE_SUCCESS,
      data,
    };
  },

  subjectsUpdateErr: (err) => {
    return {
      type: actions.SUBJECTS_UPDATE_ERR,
      err,
    };
  },

  subjectsDeleteBegin: () => {
    return {
      type: actions.SUBJECTS_DELETE_BEGIN,
    };
  },

  subjectsDeleteSuccess: (data) => {
    return {
      type: actions.SUBJECTS_DELETE_SUCCESS,
      data,
    };
  },

  subjectsDeleteErr: (err) => {
    return {
      type: actions.SUBJECTS_DELETE_ERR,
      err,
    };
  },

  subjectsSingleDataBegin: () => {
    return {
      type: actions.SUBJECTS_SINGLE_DATA_BEGIN,
    };
  },

  subjectsSingleDataSuccess: (data) => {
    return {
      type: actions.SUBJECTS_SINGLE_DATA_SUCCESS,
      data,
    };
  },

  subjectsSingleDataErr: (err) => {
    return {
      type: actions.SUBJECTS_SINGLE_DATA_ERR,
      err,
    };
  },
};

export default actions;
