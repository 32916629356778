import React, { useEffect, useState } from 'react';
import { Cards } from '../../components/cards/frame/cards-frame';
import { NoteCardWrap } from '../note/style';
import Heading from '../../components/heading/heading';
import { Col, Modal, Radio, Result, Row, Space } from 'antd';
import { Button } from '../../components/buttons/buttons';
import { useDispatch, useSelector } from 'react-redux';
// import { getFeedbackQuestions, postFeedback, verifyUserFeedback } from '../../redux/Assessment/actionCreator';
import CustomSpinner from '../ui-elements/spinner/CustomSpinner';
import UilArrowLeft from '@iconscout/react-unicons/icons/uil-arrow-left';
import UilArrowRight from '@iconscout/react-unicons/icons/uil-arrow-right';
import UilCheckCircle from '@iconscout/react-unicons/icons/uil-check-circle';
import UilTimesCircle from '@iconscout/react-unicons/icons/uil-times-circle';
import UilAward from '@iconscout/react-unicons/icons/uil-award';
import UilSmile from '@iconscout/react-unicons/icons/uil-smile';
import { ActionWrapper, StepsStyle } from './styles/stepsStyle';
import { SaveQuizAnswer, SubmitAllQuiz, getQuizByTopicToAttempt, getQuizResultByTopic } from '../../redux/getQuiztoAttempt/actionCreator';
import { ReadBadgeById } from '../../redux/studentBadgesEarned/actionCreator';
import Confetti from 'react-confetti'
import actions from '../../redux/getQuiztoAttempt/actions';
import { theme } from '../../config/theme/themeVariables';
import { getItem } from '../../utility/localStorageControl';
import { useParams } from 'react-router-dom';

const { submitQuizSuccess } = actions

function Quiz({ topicName, topicId, setCurrentQuestionIndex, currentQuestionIndex, setShowQuiz, showQuiz, subjectName, learningFlag }) {

  const { subjectId } = useParams()

  const dispatch = useDispatch();

  const [isModalVisible, setIsModalVisible] = useState(false);
  //const [timeRemaining, setTimeRemaining] = useState(300); // Time limit in seconds (300 seconds = 5 minutes)    
  const [changedOption, setChangedOption] = useState("")

  const { mcqs, isMcqsLoading, QuizSaveRes, AnswerSaving, SubmitAllQuizRes, isSubmitting, QuizResult, isQuizResultLoading, SingleBadgeImg, isBadgeLoading } = useSelector((state) => {
    return {
      mcqs: state.QuiztoAttempt.data,
      isMcqsLoading: state.QuiztoAttempt.loading,
      QuizSaveRes: state.QuizSaveRes.data,
      AnswerSaving: state.QuizSaveRes.loading,
      SubmitAllQuizRes: state.SubmitAllQuizRes.data,
      isSubmitting: state.SubmitAllQuizRes.loading,
      QuizResult: state.QuizResult.data,
      isQuizResultLoading: state.QuizResult.loading,
      SingleBadgeImg: state.SingleBadgeImg.data,
      isBadgeLoading: state.SingleBadgeImg.loading,
    };
  });

  // console.log(currentQuestionIndex)

  useEffect(() => {
    if (topicId) {
      dispatch(getQuizResultByTopic(parseInt(topicId)))
    }
  }, [topicId, SubmitAllQuizRes])

  // useEffect(() => {
  //   if (QuizResult !== null && QuizResult.badge !== 0) {
  //     const badgeId = QuizResult.badge
  //     dispatch(ReadBadgeById(parseInt(badgeId)))
  //   }
  // }, [QuizResult])

  // console.log(QuizResult) //score : -1 , badge : 0

  const getNewQuiz = () => {
    dispatch(submitQuizSuccess(null))
    if (topicId) {
      dispatch(getQuizByTopicToAttempt(parseInt(topicId)))
      setShowQuiz(true)
    }
  }

  const handleOptionChange = (event) => {
    const { value } = event.target;
    setChangedOption(value)
  };

  useEffect(() => {
    // if (mcqs !== null) {
    //   dispatch(SubmitAllQuiz(parseInt(mcqs.quizId)))
    // }
    if (mcqs !== null && Object.keys(mcqs).length !== 0 && mcqs.questions.length !== 0) {
      let testArray = []
      mcqs.questions.forEach(obj => {
        if (obj.userAns.trim() !== '') {
          testArray.push(obj)
        }
      })

      if (mcqs.questions.length === testArray.length) {
        // dispatch(SubmitAllQuiz(parseInt(mcqs.quizId)))
      }
    }
  }, [mcqs])

  const handleNextQuestion = () => {
    if (changedOption !== '') {
      const datatopost = {
        'quizId': mcqs.quizId,
        "questionId": mcqs.questions.length !== 0 && mcqs.questions[currentQuestionIndex].questionId,
        "userAnswer": changedOption
      }
      dispatch(SaveQuizAnswer(datatopost))
    }
    else if (mcqs.questions.length !== 0 && mcqs.questions[currentQuestionIndex].userAns !== '') {
      if (currentQuestionIndex < (mcqs !== null && mcqs.questions.length - 1)) {
        setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
      }
    }
  };

  useEffect(() => {
    if (QuizSaveRes !== null && QuizSaveRes !== null & QuizSaveRes.save === "successful" && showQuiz) {
      getNewQuiz()
      setChangedOption('')
      if (currentQuestionIndex < (mcqs !== null && mcqs.questions.length - 1)) {
        setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
      }
      else if (currentQuestionIndex === (mcqs !== null && mcqs.questions.length - 1)) {
        setIsModalVisible(true);
      }
    }
  }, [QuizSaveRes])

  const handlePrevQuestion = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex((prevIndex) => prevIndex - 1);
    }
  };

  const handleSubmit = () => {
    if (changedOption !== '') {
      const datatopost = {
        'quizId': mcqs.quizId,
        "questionId": mcqs.questions.length !== 0 && mcqs.questions[currentQuestionIndex].questionId,
        "userAnswer": changedOption
      }
      dispatch(SaveQuizAnswer(datatopost))
    }
    else if (mcqs.questions.length !== 0 && mcqs.questions[currentQuestionIndex].userAns !== '') {
      setIsModalVisible(true);
    }
  };

  const handleModalOk = () => {
    setIsModalVisible(false);
    const regulationId = getItem('regulationId')
    const regulationCode = getItem('regulationCode')
    const yearsemesterId = getItem('yearsemesterId')
    const yearsemesterCode = getItem('yearsemesterCode')
    const branchId = getItem('branchId')
    const branchCode = getItem('branchCode')
    const sectionId = getItem('sectionId')
    const sectionName = getItem('sectionName')

    const obj = {
      regulationId,
      regulationCode,
      yearsemesterId,
      yearsemesterCode,
      branchId,
      branchCode,
      sectionId,
      sectionName,
      subjectId,
      subjectName,
      topicId,
      topicName,
      quizId: parseInt(mcqs.quizId)
    }
    setShowQuiz(false)
    setCurrentQuestionIndex(0)
    dispatch(SubmitAllQuiz(obj))
  };


  //useEffect(() => {
  // After the quiz is completed, navigate to the NewPage with the "qualifiesForAward" prop.
  //navigate('/admin/pages/quizpage', { state: { qualifiesForAward } });
  //}, [qualifiesForAward, navigate]);

  const handleModalCancel = () => {
    setIsModalVisible(false);
  };

  /*useEffect(() => {
    const countdown = setInterval(() => {
      setTimeRemaining((prevTime) => prevTime - 1);
    }, 1000);
  
    if (timeRemaining === 0) {
      clearInterval(countdown);
      calculateMarks();
      setIsModalVisible(true);
    }
  
    return () => {
      clearInterval(countdown);
    };
  }, [timeRemaining]);
  
  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes}:${seconds.toString().padStart(2, '0')}`;
  };*/

  const currentQuestion = mcqs !== null && Object.keys(mcqs).length !== 0 && mcqs.questions.length !== 0 && mcqs.questions[currentQuestionIndex];
  const isOptionSelected = currentQuestion && currentQuestion.userAns !== '' ? true : changedOption !== '';

  return (
    <div>
      <Cards headless>
        <Heading as={'h3'} className='text-center mb-25'>{topicName}</Heading>
        <NoteCardWrap>
          {
            isMcqsLoading || isSubmitting || isQuizResultLoading ?
              <div className="custom-spin">
                <CustomSpinner />
              </div>
              :
              <div>
                {
                  // qualified
                  (QuizResult !== null && QuizResult.badge !== 0) && !showQuiz ?
                    <div className="" style={{ textAlign: 'center' }}>
                      <Confetti
                        className='mx-auto w-100'
                        numberOfPieces={150}
                      />
                      <span className="icon-success" >
                        <div style={{ padding: 60 }}>
                          <h2>Read Badge</h2>
                          {/* {
                            isBadgeLoading &&
                            <div style={{ position: 'relative', height: '60px', marginTop: '60px' }}>
                              <div className='m-auto' style={{ position: 'absolute', left: '50%' }}>
                                <CustomSpinner />
                              </div>
                            </div>
                          } */}
                          {/* {
                            (SingleBadgeImg !== null && SingleBadgeImg.Error === true) ?
                              <div className="badge-icon-container mt-10">
                                <UilAward color={'gold'} className="badge-icon" size={100} />
                              </div>
                              :
                              !isBadgeLoading &&
                              < div className='mt-10'>
                                <img src={SingleBadgeImg !== null ? process.env.REACT_APP_API_ENDPOINT + SingleBadgeImg.url : ''} width={80} />
                              </div>
                          } */}
                          < div className='mt-10'>
                            <img src={process.env.REACT_APP_API_ENDPOINT + QuizResult.topicReadBadge} width={80} />
                          </div>

                          <p className='fontsize-20 mt-20 italic'>Congratulations!!</p>
                          <p className='fontsize-18 italic'>{QuizResult.topicname} of {QuizResult.unitname}, {QuizResult.subjectname}</p>
                          <p className='fontsize-18 italic'>MCQ Grade: <span style={{ color: `${theme['primary-color']}` }}>100% ({`${QuizResult.score !== -1 && (QuizResult.score + '/' + QuizResult.score)}`})</span></p>
                          <p className='m-0 fontsize-16'>on {new Date(QuizResult.lastsubmitted).toLocaleString()}</p>
                          <h2 className='mt-20' style={{ color: `${theme['assesment-badge-color']}`, fontWeight: 500 }} >Good Luck for the Topic Assessment Test <img style={{ marginBottom: '2px' }} src={process.env.REACT_APP_API_ENDPOINT + QuizResult.topicCompleteBadge} width={18} /></h2>
                        </div>
                      </span>
                    </div>
                    :
                    !learningFlag ?
                      <div className='custom-spin'>
                        <Result
                          status={'warning'}
                          title="MCQs are locked by your teacher"
                        />
                      </div>
                      :
                      <div>
                        {
                          // After Quiz attempted
                          (QuizResult !== null && QuizResult.badge === 0 && QuizResult.score !== -1) && showQuiz === false ?
                            <div className="">
                              <Result status={'error'}
                                title="Not Qualified"
                                subTitle={
                                  <div>
                                    <p className='m-0'>Marks obtained: {QuizResult.score <= 0 ? 0 : QuizResult.score}</p>
                                    <span className='fontsize-13'>Submitted On: {QuizResult.lastsubmitted !== null ? new Date(QuizResult.lastsubmitted).toLocaleString() : 'N/A'}</span>
                                  </div>
                                }
                                extra={
                                  <Button type="primary" onClick={() => getNewQuiz()}>Attempt Again</Button>
                                }
                              />
                              {/* <UilSmile color={'green'} style={{ fontSize: '80px' }} /> */}
                              {/* <Heading as="h5" style={{ color: 'green' }}>
                              Great effort! Keep practicing to improve your score.
                            </Heading> */}
                            </div>
                            :
                            // first attempt
                            (QuizResult !== null && QuizResult.badge === 0 && QuizResult.score === -1) && !showQuiz &&
                            <Row justify="center" align="middle" style={{ height: '100%' }}>
                              <Col xl={12} xs={24}>
                                <div className="checkout-successful" style={{ textAlign: 'center' }}>
                                  <Button className="mt-20" type="primary" onClick={() => getNewQuiz()}>Start Quiz</Button>
                                </div>
                              </Col>
                            </Row>
                        }
                      </div>
                }

                <div>
                  {
                    showQuiz && mcqs !== null && Object.keys(mcqs).length !== 0 && mcqs.questions.length !== 0 ?
                      <Row gutter={25}>
                        <Col xxl={{ span: 22, offset: 1 }} xl={{ span: 22, offset: 1 }} lg={{ span: 22, offset: 1 }} md={{ span: 22, offset: 1 }} xs={{ span: 22, offset: 1 }}>
                          <div>
                            <StepsStyle current={currentQuestionIndex} size="small">
                              {mcqs !== null && Object.keys(mcqs).length !== 0 && mcqs.questions.length !== 0 && mcqs.questions.map((mcq, index) => (
                                <StepsStyle.Step key={index} />
                              ))}
                            </StepsStyle>

                            <div className={'mt-30'}>
                              <h3>{currentQuestion.question}</h3>
                              <Space direction="vertical">
                                {currentQuestion && Object.keys(currentQuestion.options).length !== 0 && Object.keys(currentQuestion.options).map((option, optionIndex) => (
                                  <Radio
                                    key={optionIndex}
                                    value={option}
                                    checked={changedOption === "" ? currentQuestion.userAns === option : changedOption === option}
                                    onChange={handleOptionChange}
                                  >
                                    {currentQuestion.options[option]}
                                  </Radio>
                                ))}
                              </Space>
                            </div>
                            <ActionWrapper>
                              <div className="step-action-wrap">
                                <div className="step-action-inner">
                                  <div className="steps-action">
                                    {currentQuestionIndex === 0 && (
                                      <Button className="btn-prev" type="light" outlined onClick={() => { setShowQuiz(false); dispatch(getQuizResultByTopic(parseInt(topicId))) }}>
                                        Cancel
                                      </Button>
                                    )}

                                    {currentQuestionIndex > 0 && (
                                      <Button className="btn-prev" type="light" outlined onClick={handlePrevQuestion}>
                                        <UilArrowLeft />
                                        Previous
                                      </Button>
                                    )}

                                    {currentQuestionIndex < (mcqs !== null && Object.keys(mcqs).length !== 0 && mcqs.questions.length !== 0 && mcqs.questions.length - 1) && (
                                      <Button
                                        className="btn-next"
                                        type="primary"
                                        onClick={handleNextQuestion}
                                        disabled={!isOptionSelected}
                                      >
                                        {AnswerSaving ? 'Saving...' : 'Save & Next'}
                                        <UilArrowRight />
                                      </Button>
                                    )}

                                    {currentQuestionIndex === (mcqs !== null && Object.keys(mcqs).length !== 0 && mcqs.questions.length !== 0 && mcqs.questions.length - 1) && (
                                      <Button type="primary" onClick={handleSubmit} disabled={!isOptionSelected}>
                                        Submit
                                      </Button>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </ActionWrapper>
                          </div>
                          <Modal title="Test Completed" open={isModalVisible} onOk={handleModalOk} okText='Yes' cancelText="No" onCancel={handleModalCancel}>
                            <p>Are you sure you want to submit the quiz?</p>
                          </Modal>
                        </Col>
                      </Row>
                      :
                      (mcqs !== null && Object.keys(mcqs).length === 0) || (mcqs !== null && Object.keys(mcqs).length !== 0 && mcqs.questions.length === 0) && showQuiz &&
                      <div className='text-center'>
                        <div style={{ fontSize: '20px' }} className='mb-25'>Quiz not found</div>
                        {/* <Button type="primary" onClick={()=>getNewQuiz()}>
                          Refresh
                        </Button> */}
                      </div>
                  }
                </div>
              </div>
          }
        </NoteCardWrap >
      </Cards >
    </div >
  );
}

export default Quiz;