import React from 'react'
import { Main } from './styled';
import { Button, Col, Form, Input, Row, message } from 'antd';
import { Cards } from '../components/cards/frame/cards-frame';
import { NoteCardWrap } from './note/style';
import { AuthFormWrap } from './profile/authentication/overview/style';
import { useDispatch, useSelector } from 'react-redux';
import { changePassword } from '../redux/changepassword/actionCreator'
import { getItem } from '../utility/localStorageControl';

function ChangePassword() {

    const [form] = Form.useForm();
    const dispatch = useDispatch();

    const { changepasswordRes, isLoading } = useSelector((state) => {
        return {
            changepasswordRes: state.ChangePassword.data,
            isLoading: state.ChangePassword.loading
        }
    });

    const handleSubmit = (values) => {
        const username = getItem('username')
        if (values.passowrd === values.confirmpassword && (username !== null || username !== undefined)) {
            const data = { username: username + "", password: values.passowrd }
            dispatch(changePassword(data));
        }
        else {
            message.error("New password and confirm password should be same")
        }
    }

    return (
        <div>
            <Main>
                <Row gutter={25} justify="center" className='mt-25'>
                    <Col xxl={7} xl={8} md={12} sm={18} xs={24}>
                        <AuthFormWrap>
                            <div className="ninjadash-authentication-top">
                                <h2 className="ninjadash-authentication-top__title">Change Password</h2>
                            </div>
                            <div className="ninjadash-authentication-content">
                                <Form id="changepassword_form" name="changepassword" form={form} onFinish={handleSubmit} layout="vertical">
                                    <Form.Item name="passowrd"
                                        label="New Password"
                                        rules={[{ message: 'Please input new password!', required: true }]}
                                    >
                                        <Input.Password id="changepassword_newpassword-input" placeholder="New password" />
                                    </Form.Item>
                                    <Form.Item name="confirmpassword"
                                        label="Confirm Password"
                                        rules={[{ message: 'Please input confirm password!', required: true }]}
                                    >
                                        <Input.Password id="changepassword_confirmpassword-input" placeholder="Confirm password" />
                                    </Form.Item>
                                    <Form.Item>
                                        <Button id="changepassword_change-button" className="btn-signin" htmlType="submit" type="primary" size="large">
                                            {isLoading ? 'Loading...' : 'Change'}
                                        </Button>
                                    </Form.Item>
                                </Form>
                            </div>
                        </AuthFormWrap>
                    </Col>
                </Row>
            </Main>
        </div>
    )
}


export default ChangePassword