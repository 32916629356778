import actions from './actions';

const {
    TOPIC_PROGRAMMING_BEGIN,
    TOPIC_PROGRAMMING_SUCCESS,
    TOPIC_PROGRAMMING_ERR,
    
    PROGRAMMING_TSSRDETAILS_BEGIN,
    PROGRAMMING_TSSRDETAILS_SUCCESS,
    PROGRAMMING_TSSRDETAILS_ERR,

} = actions;

const initialProgramming = {
  data: [],
  loading: false,
  error: null,
};

const initialTesselator = {
  data: null,
  loading: false,
  error: null,
};


const ProgrammingReducer = (state = initialProgramming, action) => {
    const { type, data, err } = action;
    switch (type) {
      case TOPIC_PROGRAMMING_BEGIN:
        return {
          ...state,
          loading: true,
        };
  
      case TOPIC_PROGRAMMING_SUCCESS:
        return {
          ...state,
          data,
          error: false,
          loading: false,
        };
  
      case TOPIC_PROGRAMMING_ERR:
        return {
          ...state,
          error: err,
          loading: false,
        };
  
      default:
        return state;
    }
  };

  const TesselatorReducer = (state = initialTesselator, action) => {
    const { type, data, err } = action;
    switch (type) {
      case PROGRAMMING_TSSRDETAILS_BEGIN:
        return {
          ...state,
          loading: true,
        };
  
      case PROGRAMMING_TSSRDETAILS_SUCCESS:
        return {
          ...state,
          data,
          error: false,
          loading: false,
        };
  
      case PROGRAMMING_TSSRDETAILS_ERR:
        return {
          ...state,
          error: err,
          loading: false,
        };
  
      default:
        return state;
    }
  };

  export { ProgrammingReducer ,TesselatorReducer };