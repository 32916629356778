import actions from './actions';

const {
  REGULATION_ADD_BEGIN,
  REGULATION_ADD_SUCCESS,
  REGULATION_ADD_ERR,

  REGULATION_UPLOAD_BEGIN,
  REGULATION_UPLOAD_SUCCESS,
  REGULATION_UPLOAD_ERR,

  REGULATION_READ_BEGIN,
  REGULATION_READ_SUCCESS,
  REGULATION_READ_ERR,

  REGULATION_UPDATE_BEGIN,
  REGULATION_UPDATE_SUCCESS,
  REGULATION_UPDATE_ERR,

  REGULATION_DELETE_BEGIN,
  REGULATION_DELETE_SUCCESS,
  REGULATION_DELETE_ERR,

  REGULATION_SINGLE_DATA_BEGIN,
  REGULATION_SINGLE_DATA_SUCCESS,
  REGULATION_SINGLE_DATA_ERR,
} = actions;

const initialState = {
  data: [],
  url: null,
  fileLoading: false,
  loading: false,
  error: null,
};

const initialStateSingle = {
  data: null,
  loading: false,
  error: null,
};

const REGULATIONReducer = (state = initialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case REGULATION_UPLOAD_BEGIN:
      return {
        ...state,
        fileLoading: true,
      };

    case REGULATION_UPLOAD_SUCCESS:
      return {
        ...state,
        url: data,
        error: false,
        fileLoading: false,
      };

    case REGULATION_UPLOAD_ERR:
      return {
        ...state,
        error: err,
        fileLoading: false,
      };

    case REGULATION_ADD_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case REGULATION_ADD_SUCCESS:
      return {
        ...state,
        data,
        error: false,
        loading: false,
      };

    case REGULATION_ADD_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case REGULATION_READ_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case REGULATION_READ_SUCCESS:
      return {
        ...state,
        data,
        error: false,
        loading: false,
      };

    case REGULATION_READ_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case REGULATION_DELETE_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case REGULATION_DELETE_SUCCESS:
      return {
        ...state,
        error: false,
        data,
        loading: false,
      };

    case REGULATION_DELETE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case REGULATION_UPDATE_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case REGULATION_UPDATE_SUCCESS:
      return {
        ...state,        
        error: false,
        loading: false,
      };

    case REGULATION_UPDATE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

const REGULATIONSingleReducer = (state = initialStateSingle, action) => {
  const { type, data, err } = action;
  switch (type) {
    case REGULATION_SINGLE_DATA_BEGIN:
      return {
        ...initialStateSingle,
        loading: true,
      };

    case REGULATION_SINGLE_DATA_SUCCESS:
      return {
        ...initialStateSingle,
        data,
        error: false,
        loading: false,
      };

    case REGULATION_SINGLE_DATA_ERR:
      return {
        ...initialStateSingle,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

export { REGULATIONReducer, REGULATIONSingleReducer };
