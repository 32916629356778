import React, { useState, useEffect } from 'react';
import { Row, Col, Table, Button, Input, Modal, Form, Space, Select, Tag, message } from 'antd';
import UilEdit from '@iconscout/react-unicons/icons/uil-edit';
import UilTrash from '@iconscout/react-unicons/icons/uil-trash-alt';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import {UserTableStyleWrapper} from './styles/tablestyle'
import { Cards } from '../../components/cards/frame/cards-frame';
import { TableWrapper, Main } from '../styled';
import { PageHeader } from '../../components/page-headers/page-headers';
import { useDispatch, useSelector } from 'react-redux';
import { readSubjectsByBRanch, subjectsDataSubmit} from '../../redux/subjects/actionCreator';
import { ProjectList, ProjectSorting } from '../project/style';
import { AutoComplete } from '../../components/autoComplete/autoComplete';
import { useParams } from 'react-router-dom';

const { Option } = Select;

function SubjectByBranch() {

    const {branchId} = useParams()

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [form] = Form.useForm();
  const [editForm] = Form.useForm();

  const [editRecord, setEditRecord] = useState(null);

  const dispatch = useDispatch();
  const { Subjects, isLoading } = useSelector((state) => {
    return {
      Subjects: state.SubjectsBranchwise.data,
      isLoading: state.SubjectsBranchwise.loading,
    };
  });
  
  
  useEffect(() => {
    if (dispatch) {
      dispatch(readSubjectsByBRanch(branchId));
    }
  }, [dispatch]);

  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(()=> {
    if(Subjects){
      setDataSource(Subjects.subjects)
      setLoading(false);      
    }
  },[Subjects])


  const [branches,setBranches] = useState([])
  const [yearSemId,setYearSemId] = useState(-1)

  const onYearSorting = (yearsemId) => {      
      if(yearsemId === '-1')
      {
        setYearSemId(-1)
        setBranches([])
          setDataSource(Subjects.subjects)
    }    
    else{
        setYearSemId(yearsemId)
        let yearFilter = Subjects.yearsem.filter(element => element.id === yearsemId);
        setBranches(yearFilter[0].branches)
        let subFilter = Subjects.subjects.filter(element => element.yearsemsterId === yearsemId);
        setDataSource(subFilter)
    }
  };

  const onBranchSorting = (branchId) => {    
    if(branchId === '-1')
    {        
        let subFilter = Subjects.subjects.filter(element => element.yearsemsterId === yearSemId);
        setDataSource(subFilter)
    }    
    else{                
        let subFilter = Subjects.subjects.filter(element => (element.branchId === branchId) && (element.yearsemsterId === yearSemId) );        
        setDataSource(subFilter)
    }
  };

  const { notData, visible } = useState([]);
  const handleSearch = (searchText) => {
    // const data = searchData.filter((item) => item.title.toUpperCase().startsWith(searchText.toUpperCase()));
    // setState({
    //   ...state,
    //   notData: data,
    // });
  };

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Code',
      dataIndex: 'code',
      key: 'code',
    },
    {
      title: 'Name',
      dataIndex: 'subname',
      key: 'subname',
    },
    {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
    },
    {
        title: 'Regulation',
        dataIndex: 'regCode',
        key: 'regCodes',
    },
    {
        title: 'Year Semester',
        dataIndex: 'yearsemester',
        key: 'yearsemester',
    },
    {
        title: 'Topics',
        dataIndex: 'topics',
        key: 'topics',
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (text, record) => (
        <Space>
          <Button icon={<UilEdit />} onClick={() => handleEdit(record)}>
          </Button>
          <Button icon={<UilTrash />} onClick={() => handleDelete(record)}>
          </Button>
        </Space>
      ),
    },
  ];

  const handleButtonClick = () => {
    setIsModalVisible(true);
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
  };

  const handleModalAdd = () => {
    form.validateFields().then((values) => {
        const newRow = {
          // key: String(dataSource.length + 1),
          // id: String(dataSource.length + 1),
          code: values.code,
          name: values.name,
          regId: values.regulationId,
        };
        setDataSource([...dataSource, newRow]);
        form.resetFields();
        setIsModalVisible(false);
        const newRRow = {
          code: newRow.code,
          name: newRow.name,
        }
        dispatch(subjectsDataSubmit(newRRow));        
    });
  };

  const handleModalUpdate = () => {
    editForm.validateFields().then((values) => {
      const updatedRecord = {
        ...editRecord,
        code: values.code,
        name: values.name,
      };

      const updatedDataSource = dataSource.map((item) =>
        item.key === updatedRecord.key ? updatedRecord : item
      );

      setDataSource(updatedDataSource);
      setIsEditModalVisible(false);
    });
  };

  const handleEdit = (record) => {
    setEditRecord(record);
    setIsEditModalVisible(true);
    editForm.setFieldsValue({
      code: record.code,
      name: record.name,
    });
  };

  const handleDelete = (record) => {
    Modal.confirm({
      title: 'Are you sure you want to delete this record?',
      icon: <ExclamationCircleOutlined />,
      content: 'Once deleted the action can be undone',
      okText: 'Delete',
      okType: 'danger',
      cancelText: 'Cancel',
      onOk() {
        const updatedDataSource = dataSource.filter((item) => item.id !== record.id);
        const updatedDataSourceWithSNo = updatedDataSource.map((item, index) => ({
          ...item,
          id: String(index + 1),
          key: String(index + 1),
        }));
        setDataSource(updatedDataSourceWithSNo);
      },
    });
  };

  const showEditModal = (record) => {
    handleEdit(record);
  };

  return (
    <>
    <PageHeader className="ninjadash-page-header-main" title={`Subjects - ${Subjects !== null && Subjects.branchcode}`}
        buttons={[
            <Button onClick={handleButtonClick} key="1" type="primary" size="default">
              Add Subject
            </Button>,
          ]}/>      
      <Main>
        <Row gutter={25}>
          <Col sm={24} xs={24}>
          <ProjectSorting>
              <div className="project-sort-bar">                
                <div className="project-sort-search">
                  <AutoComplete onSearch={handleSearch} dataSource={notData} placeholder="Search subject" patterns />
                </div>
                <div className="project-sort-group">
                  <div className="sort-group">
                    <span>YearSemester</span>
                    <Select onChange={onYearSorting} defaultValue="-1">
                      <Select.Option value='-1'>All</Select.Option>
                      {/* {
                        Subjects !== null && Subjects.yearsem.length !==0 &&  Subjects.yearsem.map((obj)=>
                            <Select.Option value={obj.id}>{obj.code}</Select.Option>  
                        )
                      }                                           */}
                    </Select>
                  </div>
                </div>
                <div>
                    <div className="sort-group">
                    <span>Branch</span>
                    <Select onChange={onBranchSorting} defaultValue="-1">
                      <Select.Option value="-1">All</Select.Option>
                      {/* {
                        branches.length !==0 && branches.map((obj)=>
                            <Select.Option value={obj.id}>{obj.code}</Select.Option>  
                        )
                      } */}
                    </Select>
                  </div>
                </div>
              </div>
            </ProjectSorting>
            <Cards headless>
            <ProjectList>
            <div className="table-responsive">
                  <Table dataSource={dataSource} columns={columns} />
                </div>
              </ProjectList>
            </Cards>
          </Col>
        </Row>        
      </Main>
      <Modal
        title="Add Subject"
        open={isModalVisible}
        onCancel={handleModalCancel}
        footer={[
          <Button key="add" type="primary" onClick={handleModalAdd}>
            Add
          </Button>,
        ]}
      >
        <Form form={form} layout="vertical">
          <Form.Item name="code" label="Code" rules={[{ required: true }]}>
            <Input/>
          </Form.Item>
          <Form.Item name="name" label="Name" rules={[{ required: true }]}>
            <Input/>
          </Form.Item>
          {/* <Form.Item name="regulationId" label="Regulation" rules={[{ required: true }]}>
            <Select placeholder="Select Regulation">
              {regulationData.map((regulation) => (
                <Option key={regulation.code} value={regulation.id}>
                  {regulation.code}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name="yearsemesterId" label="Year-Semester" rules={[{ required: true }]}>
            <Select placeholder="Select Year-Semester">
              {yearsemesterData.map((yearsemester) => (
                <Option key={yearsemester.code} value={yearsemester.id}>
                  {yearsemester.code}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name="branchId" label="Branch" rules={[{ required: true }]}>
            <Select placeholder="Select Branch">
              {branchData.map((branch) => (
                <Option key={branch.code} value={branch.id}>
                  {branch.code}
                </Option>
              ))}
            </Select>
          </Form.Item> */}
          {/* <Form.Item name="status" label="Status" >
            <Select defaultValue="Active">
              <Option value="active">Active</Option>
              <Option value="inactive">Inactive</Option>
            </Select>
          </Form.Item>
          <Form.Item name="topics" label="Topics" >
            <Input/>
          </Form.Item> */}
        </Form>
      </Modal>
      <Modal
        title="Edit Subject"
        visible={isEditModalVisible}
        onCancel={() => setIsEditModalVisible(false)}
        footer={[
          <Button key="update" type="primary" onClick={handleModalUpdate}>
            Update
          </Button>
        ]}
      >
        <Form form={editForm} layout="vertical">
        <Form.Item name="code" label="Code" rules={[{ required: true }]}>
            <Input/>
          </Form.Item>
          <Form.Item name="name" label="Name" rules={[{ required: true }]}>
            <Input/>
          </Form.Item>
          {/* <Form.Item name="regulation" label="Regulation" rules={[{ required: true }]}>
            <Select>
                <Option value="KR21">KR21</Option>
                <Option value="KR22">KR22</Option>
            </Select>
          </Form.Item>
          <Form.Item name="status" label="Status" rules={[{ required: true }]}>
            <Select>
              <Option value="active">Active</Option>
              <Option value="inactive">Inactive</Option>
            </Select>
          </Form.Item>
          <Form.Item name="topics" label="Topics" rules={[{ required: true }]}>
            <Input/>
          </Form.Item> */}
        </Form>
      </Modal>
    </>
  );
}

export default SubjectByBranch;