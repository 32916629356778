import React, { useEffect, useState } from 'react';
import { Modal, Input, Select, Table, message, Collapse, Checkbox } from 'antd';
import { Button } from '../../components/buttons/buttons';
// import 'antd/dist/antd.css';
import { Main } from '../styled';
import { Cards } from '../../components/cards/frame/cards-frame';
import { DataTableStyleWrap } from '../../components/table/Style';
import { useDispatch, useSelector } from 'react-redux';
import { EvaluateAnswersManually, getAnswersData } from '../../redux/answers/actionCreator';
import { readAnswersSuccess, manualEvaluationSuccess } from '../../redux/answers/actions';
import CustomSpinner from '../ui-elements/spinner/CustomSpinner';
import UilPlus from '@iconscout/react-unicons/icons/uil-plus';
import UilMinus from '@iconscout/react-unicons/icons/uil-minus';
import UilEye from '@iconscout/react-unicons/icons/uil-eye';
import UilEyeSlash from '@iconscout/react-unicons/icons/uil-eye-slash';
import { CSVLink } from 'react-csv';
import { FaqWrapper } from './styles/tablestyle';
import UilSearch from '@iconscout/react-unicons/icons/uil-search';
import { DownloadOutlined } from '@ant-design/icons';
import jsPDF from 'jspdf';
import { getTeacherRegulationSubjects } from '../../redux/teacherSubjectsUnits/actionCreator';
import actions from '../../redux/teacherSubjectsUnits/actions';

const { TeacherSubjectsSuccess } = actions

const { Panel } = Collapse;

function ViewAnswers() {

  const dispatch = useDispatch()
  const [subjectId, setSubjectId] = useState(0)
  const [unitId, setUnitId] = useState(0);
  const [topicId, setTopicId] = useState(0);
  const [regulationId, setRegulationId] = useState(0);
  const [yearSemId, setYearSemId] = useState(0);
  const [branchId, setBranchId] = useState(0);
  const [selectedStudentQA, setSelectedStudentQA] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [units, setUnits] = useState([]);
  const [topics, setTopics] = useState([]);

  const [rowInfo, setRowInfo] = useState(null);
  const [showCorrectAns, setShowCorrectAns] = useState(false);

  const { Answers, AnswersLoading, SubjectsUnits, SubjectsUnitsLoading, TeacherRegSubjects, TeacherRegSubjectsLoading, ManualEvalRes, ManualEvalResLoading } = useSelector((state) => {
    return {
      Answers: state.Answers.data,
      AnswersLoading: state.Answers.loading,
      SubjectsUnits: state.SubjectsUnits.data,
      SubjectsUnitsLoading: state.SubjectsUnits.loading,
      TeacherRegSubjects: state.TeacherRegSubjects.data,
      TeacherRegSubjectsLoading: state.TeacherRegSubjects.loading,
      ManualEvalRes: state.ManualEvalRes.data,
      ManualEvalResLoading: state.ManualEvalRes.loading,
    }
  })
  const sectionsSet = new Set();
  Answers.forEach(answer => {
    answer.section !== null && sectionsSet.add(answer.section.name);
  });
  const sections = [...sectionsSet]

  useEffect(() => {
    dispatch(readAnswersSuccess([]))
    dispatch(TeacherSubjectsSuccess([]))
    dispatch(getTeacherRegulationSubjects())
  }, [])

  useEffect(() => {
    if (ManualEvalRes !== null && ManualEvalRes.Error === false) {
      message.success(ManualEvalRes.message)
      dispatch(manualEvaluationSuccess(null))
      dispatch(getAnswersData(regulationId, yearSemId, branchId, topicId));
    }
  }, [ManualEvalRes])

  const columns = [
    {
      title: 'S.No.',
      dataIndex: 'sno',
    },
    {
      title: 'Roll No',
      dataIndex: 'studentUsername',
      sorter: (a, b) => a.studentUsername > b.studentUsername ? 1 : -1,
      sortDirections: ['ascend'],

    },
    {
      title: 'Name',
      dataIndex: 'studentName',
      sorter: (a, b) => a.studentName?.length - b.studentName?.length,
      sortDirections: ['ascend'],

    },
    {
      title: 'Score',
      dataIndex: 'score',
      sorter: (a, b) => a.score > b.score ? 1 : -1,
      // sortDirections: ['descend'],

    },
    {
      title: 'Section',
      dataIndex: 'sectionName',
      filters: sections.length !== 0 && sections.map(section => ({ text: section, value: section })),
      onFilter: (value, record) => record.sectionName === value,
    },
    {
      title: 'Q&A',
      render: (text, record) => (
        <Button
          type='primary'
          onClick={() => {
            const selectedStudent = tableData.find(student => student.id == record.id);
            setSelectedStudentQA(selectedStudent !== null && selectedStudent.qaAttempts);
            setIsModalVisible(true);
            setRowInfo(record)
          }}
          disabled={record.absent}
        >
          View QA
        </Button>
      ),
    },
    {
      title: 'Evaluation Status',
      render: (text, record) => (
        record.status === "ACTIVE" ?
          <Button
            type='primary'
            onClick={() => {
              dispatch(EvaluateAnswersManually(record.userId, topicId))
            }}
            disabled={ManualEvalResLoading}
          >
            Evaluate
          </Button>
          :
          record.status === "EVALUATING" ?
            <span className='text-info'>Evaluating</span> :
            record.status === "INACTIVE" ?
              <span className='text-success'>Evaluated</span> :
              <span className=''>NA</span>
      ),
      sorter: (a, b) => a.status > b.status ? 1 : -1,
    },
    // {
    //   title: '',
    //   render: (_, record) => (
    //     <DownloadOutlined
    //       style={{ cursor: 'pointer' }}
    //       onClick={() => generatePDF(record)}
    //     />
    //   ),
    // },
  ];

  const generatePDF = (rowData) => {
    const doc = new jsPDF();
    doc.setFont('Arial');
    let content = `\n\nRoll No: ${rowData.studentUsername}\nName: ${rowData.studentName}\nSection: ${rowData.sectionName}\n\n`;
    rowData.qaAttempts.forEach((qa, index) => {
      content += `Question ${index + 1}:\n${qa.qaMaster.question}\n\n`;
      content += `Correct Answer:\n${qa.correctAnswer}\n\n`;
      content += `Student Answer:\n${qa.userAnswer || 'N/A'}\n\n`;
      content += `Score Awarded:${qa.score || '0'}\n`;
      content += `Submitted on:${new Date(qa.updatedon).toLocaleString()}\n\n\n`;

    });
    const pageWidth = doc.internal.pageSize.getWidth();
    const margin = 10;
    let yPosition = margin;
    const lineHeight = 10;
    let lines = doc.splitTextToSize(content, pageWidth - 2 * margin);

    lines.forEach((line) => {
      if (yPosition + lineHeight > doc.internal.pageSize.getHeight() - margin) {
        doc.addPage();
        yPosition = margin;
      }
      doc.text(line, margin, yPosition);
      yPosition += lineHeight;
    });

    doc.save(`${rowData.studentUsername}_answers.pdf`);
  };
  //Generates pdf of a specific question

  const generateAnswerPDF = (ans) => {
    const doc = new jsPDF();
    doc.setFont('Arial');
    let ansContent = `\n\nRoll No: ${rowInfo !== null ? rowInfo.studentUsername : ''}\nName: ${rowInfo !== null ? rowInfo.studentName : ''}\nSection: ${rowInfo !== null ? rowInfo.sectionName : ''}\nScore: ${ans.score} out of 10\n\nQuestion:\n${ans.qaMaster.question}\n\nCorrect Answer:\n${ans.correctAnswer}\n\nStudent Answer:\n${ans.userAnswer === null ? 'N/A' : ans.userAnswer}`;
    const pageWidth = doc.internal.pageSize.getWidth();
    const margin = 10;
    let yPosition = margin;
    const lineHeight = 10;
    let lines = doc.splitTextToSize(ansContent, pageWidth - 2 * margin);
    lines.forEach((line) => {
      if (yPosition + lineHeight > doc.internal.pageSize.getHeight() - margin) {
        doc.addPage();
        yPosition = margin;
      }
      doc.text(line, margin, yPosition);
      yPosition += lineHeight;
    });
    doc.save(`${rowInfo !== null ? rowInfo.studentUsername : 'Student_Answers'}` + '.pdf');
  }


  const [tableData, setTableData] = useState([])
  const [filteredData, setFilteredData] = useState([])

  useEffect(() => {
    let data = []
    Answers.length !== 0 && Answers.map((obj, index) => {
      const { id, name, section, usermaster } = obj
      data.push({
        key: index,
        "sno": index + 1,
        id,
        studentUsername: usermaster !== null && usermaster.username,
        userId: usermaster !== null && usermaster.id,
        studentName: name,
        sectionName: section !== null && section.name,
        score: usermaster !== null && usermaster.qaAttempts.length !== 0 ? usermaster.qaAttempts[0].score : <span className='text-danger'>NA</span>,
        qascore: usermaster !== null && usermaster.qaAttempts.length !== 0 ? usermaster.qaAttempts[0].qascore : <span className='text-danger'>NA</span>,
        programscore: usermaster !== null && usermaster.qaAttempts.length !== 0 ? usermaster.qaAttempts[0].programscore : <span className='text-danger'>NA</span>,
        qaAttempts: usermaster !== null && usermaster.qaAttempts.length !== 0 ? usermaster.qaAttempts[0].qaQuestionAttempts : [],
        status: usermaster !== null && usermaster.qaAttempts.length !== 0 && usermaster.qaAttempts[0].status,
        absent: usermaster !== null && usermaster.qaAttempts.length !== 0 ? false : true,

        // extra
        question1: usermaster !== null && usermaster.qaAttempts.length !== 0 ? usermaster.qaAttempts[0].qaQuestionAttempts.length !== 0 && usermaster.qaAttempts[0].qaQuestionAttempts[0].qaMaster !== null && usermaster.qaAttempts[0].qaQuestionAttempts[0].qaMaster.question  : '--',
        correctanswer1: usermaster !== null && usermaster.qaAttempts.length !== 0 ? usermaster.qaAttempts[0].qaQuestionAttempts.length !== 0 && usermaster.qaAttempts[0].qaQuestionAttempts[0].qaMaster !== null && usermaster.qaAttempts[0].qaQuestionAttempts[0].correctAnswer  : '--',
        useranswer1: usermaster !== null && usermaster.qaAttempts.length !== 0 ? usermaster.qaAttempts[0].qaQuestionAttempts.length !== 0 && usermaster.qaAttempts[0].qaQuestionAttempts[0].qaMaster !== null && usermaster.qaAttempts[0].qaQuestionAttempts[0].userAnswer  : '--',
        question2: usermaster !== null && usermaster.qaAttempts.length !== 0 ? usermaster.qaAttempts[0].qaQuestionAttempts.length > 1 && usermaster.qaAttempts[0].qaQuestionAttempts[1].qaMaster !== null && usermaster.qaAttempts[0].qaQuestionAttempts[1].qaMaster.question  : '--',
        correctanswer2: usermaster !== null && usermaster.qaAttempts.length !== 0 ? usermaster.qaAttempts[0].qaQuestionAttempts.length > 1 && usermaster.qaAttempts[0].qaQuestionAttempts[1].qaMaster !== null && usermaster.qaAttempts[0].qaQuestionAttempts[1].correctAnswer  : '--',
        useranswer2: usermaster !== null && usermaster.qaAttempts.length !== 0 ? usermaster.qaAttempts[0].qaQuestionAttempts.length > 1 && usermaster.qaAttempts[0].qaQuestionAttempts[1].qaMaster !== null && usermaster.qaAttempts[0].qaQuestionAttempts[1].userAnswer  : '--',
      })
    })
    setTableData(data)
    setFilteredData(data)
  }, [Answers])


  useEffect(() => {
    if (subjectId !== 0) {
      const selectedSubject = SubjectsUnits.subjects.find(subject => subject.id === subjectId);
      setUnits(selectedSubject.units);
    }
    else {
      setUnits([]);
    }
  }, [subjectId, SubjectsUnits?.subjects]);

  useEffect(() => {
    if (unitId !== 0) {
      const selectedUnit = units.find(unit => unit.id === unitId);
      setTopics(selectedUnit?.topics);
    } else {
      setTopics([]);
    }
  }, [unitId, SubjectsUnits?.subjects?.units]);

  const loadData = () => {
    if (regulationId !== 0 && yearSemId !== 0 && branchId !== 0 && topicId !== 0) {
      dispatch(getAnswersData(regulationId, yearSemId, branchId, topicId));
    } else {
      message.warn("Select all filters!!");
    }
  };

  const searchkeys = ['studentUsername', 'studentName']
  const searchStudent = (e) => {
    const { value } = e.currentTarget;
    const searchData = tableData.length !== 0 && tableData.filter((obj) => searchkeys.some((key) => obj[key].toLowerCase().includes(value.toLowerCase())))
    setFilteredData(searchData)
  };

  const getTopicName = (topics, topicId) => {
    const topicInfo = topics.filter((topic) => topic.id == topicId)
    return topicInfo.length !== 0 && topicInfo[0].name
  }

  const [selectedQuestionIds, setSelectedQuestionIds] = useState([]);
  const [isCompareModalVisible, setIsCompareModalVisible] = useState(false);

  const handleCheckboxChange = (qaId, id) => {
    const updatedQuestionIds = [...selectedQuestionIds];
    updatedQuestionIds.push({ qaMasterId: qaId, aid: id });
    setSelectedQuestionIds(updatedQuestionIds);
  };
  const modalColumns = [
    {
      title: 'Question',
      dataIndex: 'question',
      key: 'question',
    },
    {
      title: 'User Answer',
      dataIndex: 'userAnswer',
      key: 'userAnswer',
    },
  ];


  const handleCompareClick = () => {
    if (selectedQuestionIds.length >= 2 && selectedQuestionIds.length <= 4) {
      const uniqueIds = new Set(selectedQuestionIds.map(({ qaMasterId }) => qaMasterId));

      if (uniqueIds.size === 1) {
        const selectedQuestions = selectedStudentQA.filter((qa) =>
          selectedQuestionIds.some(({ qaMasterId, aid }) => qa.qaMaster.id === qaMasterId && qa.id === aid)
        );

        setIsCompareModalVisible(true);


        Modal.info({
          title: 'Selected Questions and Answers',
          content: (
            <div>
              <Table
                dataSource={selectedQuestions.map((qa) => ({
                  key: qa.qaMaster.id,
                  question: qa.qaMaster.question,
                  userAnswer: qa.userAnswer || 'N/A',
                }))}
                columns={modalColumns}
                pagination={false}
              />
            </div>
          ),
          visible: isCompareModalVisible,
          onOk() {
            setIsCompareModalVisible(false);
          },
          onCancel() {
            setIsCompareModalVisible(false);
          },
          width: 800,
        });
      } else {
        message.warn('Please select same questions to compare.');
      }
    } else {
      message.warn('Please select at least 2 and atmost 4 questions to compare.');
    }
  };

  //export
  const headers = [
    { label: "S.No", key: "sno" },
    { label: "RollNo", key: "studentUsername" },
    { label: "StudentName", key: "studentName" },
    { label: "Section", key: "sectionName" },
    { label: "Question1", key: "question1" },
    { label: "CorrectAnswer1", key: "correctanswer1" },
    { label: "UserAnswer1", key: "useranswer1" },        
    { label: "Question2", key: "question2" },
    { label: "CorrectAnswer2", key: "correctanswer2" },
    { label: "UserAnswer2", key: "useranswer2" },        
    { label: "Score", key: "score" },        
    { label: "QAScore", key: "qascore" },        
    { label: "ProgramScore", key: "programscore" },        
];

const csvReport = {
    filename: "QA.csv",
    headers: headers,
    data: filteredData
}

  return (
    <div>
      <Main className='pt-20'>
        <Cards title="Q & A Submissions">
          <DataTableStyleWrap>
            <div className="ninjadash-datatable-filter">
              <div className="ninjadash-datatable-filter__left">

                <div className="ninjadash-datatable-filter__input">
                  <span className="label">Subject:</span>
                  <Select style={{ width: 400 }} className="ninjadash-data-status" placeholder="Select Subject" onChange={(e) => { setSubjectId(e); setUnitId(0); setTopicId(0); setRegulationId(0); setYearSemId(0); setBranchId(0); }} loading={TeacherRegSubjectsLoading}>
                    {
                      TeacherRegSubjects.length !== 0 && TeacherRegSubjects.sort((a, b) => a.name > b.name ? 1 : -1).map((subjectObj, subjectIndex) =>
                        <Select.Option value={subjectObj.id} key={subjectIndex}>{subjectObj.name}</Select.Option>
                      )
                    }
                  </Select>
                </div>
                <div className="ninjadash-datatable-filter__input">
                  <span className="label">Unit:</span>
                  <Select style={{ width: 200 }} className="ninjadash-data-status" placeholder="Select Unit" value={unitId} onChange={(e) => { setUnitId(e); setTopicId(0); }} loading={TeacherRegSubjectsLoading}>
                    <Select.Option value={0} key={'selectUnit'} >Select</Select.Option>
                    {TeacherRegSubjects.length !== 0 && subjectId !== 0 && TeacherRegSubjects.filter((obj) => obj.id === subjectId)[0].units.length !== 0 && TeacherRegSubjects.filter((obj) => obj.id === subjectId)[0].units.map((unit) => (
                      <Select.Option key={unit.id} value={unit.id}>
                        {unit.name}
                      </Select.Option>
                    ))}
                  </Select>
                </div>
                <div className="ninjadash-datatable-filter__input">
                  <span className="label">Topic:</span>
                  <Select style={{ width: 400 }} className="ninjadash-data-status" placeholder="Select Topic" value={topicId} onChange={(e) => { setTopicId(e); }} loading={TeacherRegSubjectsLoading}>
                    <Select.Option value={0} key={'selectTopic'} >Select</Select.Option>
                    {
                      TeacherRegSubjects.length !== 0 && subjectId !== 0 && unitId !== 0 &&
                      TeacherRegSubjects.filter((obj) => obj.id === subjectId)[0].units.filter((obj) => obj.id === unitId)[0].length !== 0 &&
                      TeacherRegSubjects.filter((obj) => obj.id === subjectId)[0].units.filter((obj) => obj.id === unitId)[0].topics.map((topic) => (
                        <Select.Option key={topic.id} value={topic.id}>
                          {topic.name}
                        </Select.Option>
                      ))
                    }
                  </Select>
                </div>
                <div className="ninjadash-datatable-filter__input">
                  <span className="label">Regulation:</span>
                  <Select style={{ width: 200 }} className="ninjadash-data-status" placeholder="Select Regulation" value={regulationId} onChange={(e) => { setRegulationId(e); setYearSemId(0); setBranchId(0); }} loading={TeacherRegSubjectsLoading}>
                    <Select.Option value={0} key={'selectReg'} >Select</Select.Option>
                    {
                      TeacherRegSubjects.length !== 0 && subjectId !== 0 && unitId !== 0 && topicId !== 0 &&
                      TeacherRegSubjects.filter((obj) => obj.id === subjectId)[0].regulations.length !== 0 && TeacherRegSubjects.filter((obj) => obj.id === subjectId)[0].regulations.map((reg) => (
                        <Select.Option key={reg.id} value={reg.id}>
                          {reg.code}
                        </Select.Option>
                      ))
                    }
                  </Select>
                </div>
                <div className="ninjadash-datatable-filter__input">
                  <span className="label">YearSem:</span>
                  <Select style={{ width: 200 }} className="ninjadash-data-status" placeholder="Select YearSem" value={yearSemId} onChange={(e) => { setYearSemId(e); setBranchId(0); }} loading={TeacherRegSubjectsLoading}>
                    <Select.Option value={0} key={'selectReg'} >Select</Select.Option>
                    {
                      TeacherRegSubjects.length !== 0 && subjectId !== 0 && unitId !== 0 && topicId !== 0 && regulationId !== 0 &&
                      TeacherRegSubjects.filter((obj) => obj.id === subjectId)[0].yearsemesters.length !== 0 && TeacherRegSubjects.filter((obj) => obj.id === subjectId)[0].yearsemesters.map((yrsem) => (
                        <Select.Option key={yrsem.id} value={yrsem.id}>
                          {yrsem.code}
                        </Select.Option>
                      ))
                    }
                  </Select>
                </div>
                <div className="ninjadash-datatable-filter__input">
                  <span className="label">Branch:</span>
                  <Select style={{ width: 200 }} className="ninjadash-data-status" placeholder="Select Branch" value={branchId} onChange={(e) => { setBranchId(e); }} loading={TeacherRegSubjectsLoading}>
                    <Select.Option value={0} key={'selectReg'} >Select</Select.Option>
                    {
                      TeacherRegSubjects.length !== 0 && subjectId !== 0 && unitId !== 0 && topicId !== 0 && regulationId !== 0 &&
                      TeacherRegSubjects.filter((obj) => obj.id === subjectId)[0].branches.length !== 0 && TeacherRegSubjects.filter((obj) => obj.id === subjectId)[0].branches.map((branch) => (
                        <Select.Option key={branch.id} value={branch.id}>
                          {branch.code}
                        </Select.Option>
                      ))
                    }
                  </Select>
                </div>
                <div className="ninjadash-datatable-filter__action">
                  <Button type="primary" size="small" onClick={() => { loadData() }}>
                    Get
                  </Button>
                </div>
                {/* <div className="ninjadash-datatable-filter__action">
                  <Button
                    type="primary"
                    size="small"
                    onClick={handleCompareClick}
                    style={{ margin: 10 }}
                  >
                    Compare
                  </Button>
                </div> */}
              </div>
            </div>
            <div className="mb-20 d-flex align-center-v" style={{ maxWidth: '380px', marginLeft: 'auto' }}>
              <CSVLink {...csvReport}>
                <Button id="Answers_export-button" type="primary" size="small" className="mr-10" disabled={tableData.length !== 0 ? false : true}>
                  Export
                </Button>
              </CSVLink>
              <Input className='' onChange={searchStudent} size="default" placeholder="Search by Rollno. or Name" prefix={<UilSearch />} />
            </div>
            {
              AnswersLoading ?
                <div style={{ width: '100%', height: '400px' }}>
                  <div className="custom-spin">
                    <CustomSpinner Message={'Fetching data... Please wait.'} />
                  </div>
                </div>
                :
                <div>
                  <Table
                    className='table-responsive'
                    pagination={{
                      defaultPageSize: 50,
                      total: filteredData.length,
                      showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                    }}
                    columns={columns}
                    dataSource={filteredData}
                    tableLayout='auto'
                    size='middle'
                    rowClassName={(record) => record.absent ? 'bg-danger-extra-light' : ''}
                  />
                </div>
            }
          </DataTableStyleWrap>
        </Cards>
        <Modal
          title={`Answers of ${rowInfo !== null ? rowInfo.studentUsername : ''} - ${rowInfo !== null ? rowInfo.studentName : ''} `}
          open={isModalVisible}
          onCancel={() => setIsModalVisible(false)}
          footer={null}
          width={1200}
        >
          <div className='height-fixed'>
            <div className='mb-20 d-flex'>
              <p className='m-0 mr-10 fontsize-16'>Topic: </p><h3 className='m-0'>{topics.length !== 0 ? getTopicName(topics, topicId) : ''}</h3>
            </div>
            <div className='mb-20 d-flex'>
              <p className='m-0 mr-10 fontsize-14'>Avg Score: </p><h3 className='m-0 mr-10 fontsize-14'>{rowInfo !== null && rowInfo.score}</h3>
              <p className='m-0 mr-10 fontsize-14'>QA Score: </p><h3 className='m-0 mr-10 fontsize-14'>{rowInfo !== null && rowInfo.qascore}</h3>
              <p className='m-0 mr-10 fontsize-14'>Program Score: </p><h3 className='m-0 fontsize-14'>{rowInfo !== null && rowInfo.programscore}</h3>
            </div>
            {selectedStudentQA.length !== 0 && selectedStudentQA.sort((a, b) => a.updatedon < b.updatedon ? 1 : -1).map((qa, index) => (
              <div key={index}>
                <FaqWrapper>
                  <Collapse defaultActiveKey={['0']} bordered={false} expandIcon={({ isActive }) => (isActive ? <UilMinus /> : <UilPlus />)} className='m-0 mb-5' >
                    <Panel header={
                      <div className='d-flex justify-content-between'>
                        <span>{qa.qaMaster.question}</span>
                        <div>
                          <span className='mr-10'>Score : {qa.score} of 10</span>
                          <span className='text-right fontsize-13 m-0'>| Submitted on : {new Date(qa.updatedon).toLocaleString()}</span>
                        </div>
                      </div>
                    } key={index}>
                      <h4 className='align-center-v'>Correct Answer {showCorrectAns ? <UilEye size={18} className="ml-10 cursor-pointer" onClick={() => { setShowCorrectAns(false) }} /> : <UilEyeSlash size={18} className="ml-10 cursor-pointer" onClick={() => { setShowCorrectAns(true) }} />}</h4>
                      {
                        showCorrectAns &&
                        <p style={{ backgroundColor: '#fdf2ef' }}>{qa.correctAnswer}</p>
                      }
                      <h4>Student Answer</h4>
                      <p style={{ textAlign: 'justify' }} className='m-0'>
                        {qa.userAnswer === null ? 'N/A' : qa.userAnswer}
                      </p>
                      <h4 className='mt-10'>Evaluation Feedback1</h4>
                      <p style={{ textAlign: 'justify', paddingBottom: '15px' }} className='m-0'>
                        {qa.eSummary1 === null ? 'NA' : qa.eSummary1}
                      </p>
                      <div className='mb-20'>{qa.eSummary1 !== null && "Evaluation Score1: " + qa.eScore1}</div>
                      <h4>Evaluation Feedback2</h4>
                      <p style={{ textAlign: 'justify', paddingBottom: '15px' }} className='m-0'>
                        {qa.eSummary2 === null ? 'NA' : qa.eSummary2}
                      </p>
                      <div className='mb-20'>{qa.eSummary2 !== null && "Evaluation Score2: " + qa.eScore2}</div>
                      <h4>Evaluation Feedback3</h4>
                      <p style={{ textAlign: 'justify', paddingBottom: '15px' }} className='m-0'>
                        {qa.eSummary3 === null ? 'NA' : qa.eSummary3}
                      </p>
                      <div className='mb-20'>{qa.eSummary3 !== null && "Evaluation Score2: " + qa.eScore3}</div>
                    </Panel>
                  </Collapse>
                </FaqWrapper>
              </div>
            ))}
          </div>
        </Modal>
      </Main>
    </div>
  );
}

export default ViewAnswers;
