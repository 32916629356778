import actions from './actions';

const {
  GET_SCHEDULE_WEEKS_BEGIN,
  GET_SCHEDULE_WEEKS_SUCCESS,
  GET_SCHEDULE_WEEKS_ERR,
  
  GET_SCHEDULED_DATA_BEGIN,
  GET_SCHEDULED_DATA_SUCCESS,
  GET_SCHEDULED_DATA_ERR,

  UPDATE_SCHEDULE_BEGIN,
  UPDATE_SCHEDULE_SUCCESS,
  UPDATE_SCHEDULE_ERR,

  GET_CURRENT_WEEK_BEGIN,
  GET_CURRENT_WEEK_SUCCESS,
  GET_CURRENT_WEEK_ERR,

  GET_REG_YRS_BEGIN,
  GET_REG_YRS_SUCCESS,
  GET_REG_YRS_ERR,

  GET_NGROK_URL_BEGIN,
  GET_NGROK_URL_SUCCESS,
  GET_NGROK_URL_ERR

} = actions;

const getInitialState = {
  data: [],    
  loading: false,
  error: null,
};

const getSheduledDataInitialState = {
  data: null,    
  loading: false,
  error: null,
};

const updateRespose = {
  data: null,
  loading: false,
  error: null,
};

const currentWeekInitialState = {
  data: null,
  loading: false,
  error: null,
};

const regYrsState = {
  data: null,
  loading: false,
  error: null,
};

const ngrokInitialState = {
  data: null,
  loading: false,
  error: null,
};


const getScheduleWeeksReducer = (state = getInitialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case GET_SCHEDULE_WEEKS_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case GET_SCHEDULE_WEEKS_SUCCESS:
      return {
        ...state,
        data,
        error: false,
        loading: false,
      };

    case GET_SCHEDULE_WEEKS_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

const getScheduledDataReducer = (state = getSheduledDataInitialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case GET_SCHEDULED_DATA_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case GET_SCHEDULED_DATA_SUCCESS:
      return {
        ...state,
        data,
        error: false,
        loading: false,
      };

    case GET_SCHEDULED_DATA_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

const updateScheduleReducer = (state = updateRespose, action) => {
  const { type, data, err } = action;
  switch (type) {
    case UPDATE_SCHEDULE_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case UPDATE_SCHEDULE_SUCCESS:
      return {
        ...state,
        data,
        error: false,
        loading: false,
      };

    case UPDATE_SCHEDULE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

const getCurrentWeekReducer = (state = currentWeekInitialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case GET_CURRENT_WEEK_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case GET_CURRENT_WEEK_SUCCESS:
      return {
        ...state,
        data,
        error: false,
        loading: false,
      };

    case GET_CURRENT_WEEK_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

const getRegYrsReducer = (state = regYrsState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case GET_REG_YRS_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case GET_REG_YRS_SUCCESS:
      return {
        ...state,
        data,
        error: false,
        loading: false,
      };

    case GET_REG_YRS_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

const getNgrokUrlReducer = (state = ngrokInitialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case GET_NGROK_URL_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case GET_NGROK_URL_SUCCESS:
      return {
        ...state,
        data,
        error: false,
        loading: false,
      };

    case GET_NGROK_URL_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};



export { getScheduleWeeksReducer, updateScheduleReducer, getScheduledDataReducer, getCurrentWeekReducer, getRegYrsReducer, getNgrokUrlReducer };
