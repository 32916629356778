import { notification } from 'antd';
import actions from './actions';
import { DataService } from '../../config/dataService/dataService';
import { getItem } from '../../utility/localStorageControl';

const addNotificationSuccess = () => {
  notification.success({
    message: 'Your Record has been Submited',
  });
};

const addNotificationError = (err) => {
  notification.error({
    message: err,
  });
};

const {
  branchAddBegin,
  branchAddSuccess,
  branchAddErr,

  branchReadBegin,
  branchReadSuccess,
  branchReadErr,

  branchUploadBegin,
  branchUploadSuccess,
  branchUploadErr,
} = actions;

const branchDataSubmit = (data) => {
  return async (dispatch) => {
    try {
      await dispatch(branchAddBegin());
      const response = await DataService.post('/branch/create', data);
      const token = getItem('access_token');
      await dispatch(branchAddSuccess(response.data.data));
      if (token) {
        addNotificationSuccess();
      } else {
        await dispatch(branchAddErr('Unauthorized access'));
        addNotificationError('Unauthorized access');
      }
    } catch (err) {
      await dispatch(branchAddErr(err));
      addNotificationError(err);
    }
  };
};

const branchDataRead = () => {
  return async (dispatch) => {
    try {
      await dispatch(branchReadBegin());
      const query = await DataService.get('/branch');
      const token = getItem('access_token');
      await dispatch(branchReadSuccess(query.data));
      if (token) {
      } else {
        await dispatch(branchReadSuccess([]));
        // await dispatch(branchAddErr('Unauthorized access'));
      }
    } catch (err) {
      await dispatch(branchReadErr(err));
    }
  };
};


const branchFileUploder = (imageAsFile) => {
  const data = new FormData();
  data.append('image', imageAsFile);

  return async (dispatch) => {
    try {
      await dispatch(branchUploadBegin());
      const query = await DataService.post('/data/image/upload', data, { 'Content-Type': 'multipart/form-data' });

      dispatch(branchUploadSuccess(`${query.data}`));
    } catch (err) {
      await dispatch(branchUploadErr(err));
    }
  };
};


export {
  branchDataRead,  
  branchDataSubmit,
  branchFileUploder,  
};
