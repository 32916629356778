import React, { useEffect, useLayoutEffect, useState } from 'react';
import { Cards } from '../../components/cards/frame/cards-frame';
import { NoteCardWrap } from '../note/style';
import Heading from '../../components/heading/heading';
import { Document, Page, pdfjs } from 'react-pdf';
// import pdfFile from '../../assets/mockups.pdf'
import sampleFile from '../../assets/sample.pdf'
import PdfControlPanel from './PdfControlPanel';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import CustomSpinner from '../ui-elements/spinner/CustomSpinner';
import { Result } from 'antd';

function ContentPage({ pdf, topicName, setPageNumber, pageNumber, numPages, setNumPages, contentFlag }) {
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

    const pdfFile = process.env.REACT_APP_API_ENDPOINT + pdf

    const [scale, setScale] = useState(1.0);


    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
        setPageNumber(1)
    }

    const [pdfwidth, setPdfWidth] = useState(0);

    useLayoutEffect(() => {
        function updateSize() {
            const width = window.innerWidth;
            setPdfWidth(width);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);

    return (
        <div>
            <Cards headless>
                <Heading as={'h3'} className='text-center'>{topicName}</Heading>
                <NoteCardWrap>
                    {
                        !contentFlag ?
                            <div className='custom-spin'>
                                <Result
                                    status={'warning'}
                                    title="Content is locked by your teacher"
                                />
                            </div>
                            :
                            <div>
                                {
                                    pdf !== null && numPages !== 0 &&
                                    <PdfControlPanel
                                        scale={scale}
                                        setScale={setScale}
                                        numPages={numPages}
                                        pageNumber={pageNumber}
                                        setPageNumber={setPageNumber}
                                        file={pdfFile}
                                    />
                                }
                                {
                                    pdf !== null ?
                                        <Document file={pdfFile} onLoadSuccess={onDocumentLoadSuccess} loading={<div className="custom-spin"><CustomSpinner /></div>} onLoadError={() => setNumPages(0)} className='text-center'>
                                            <Page pageNumber={pageNumber} scale={scale} width={(pdfwidth > 992 ? (pdfwidth) / 1.7 : (pdfwidth < 768 ? (pdfwidth) / 1.2 : (pdfwidth) / 2))} />
                                        </Document>
                                        :
                                        <div className='spin'>
                                            <span style={{ fontSize: '18px' }}>Pdf file not found</span>
                                        </div>
                                }
                                {
                                    pdf !== null && numPages !== 0 &&
                                    <PdfControlPanel
                                        scale={scale}
                                        setScale={setScale}
                                        numPages={numPages}
                                        pageNumber={pageNumber}
                                        setPageNumber={setPageNumber}
                                        file={pdfFile}
                                    />
                                }
                            </div>

                    }
                    {/* <p>
                        Page {pageNumber} of {numPages}
                    </p> */}
                    {/* <iframe
                        title="PDF Viewer"
                        src={pdfFile}
                        style={{ width: '100%', height: '700px', border: 'none' }}
                    /> */}
                </NoteCardWrap>
            </Cards>
        </div>
    );
}

export default ContentPage;