import { message, notification } from 'antd';
import actions from './actions';
import { DataService } from '../../config/dataService/dataService';
import { logOut } from '../authentication/actionCreator';
import { getItem } from '../../utility/localStorageControl';

const {
  getSectionBegin,
  getSectionSuccess,
  getSectionErr,

} = actions;

const getSectionsRead = (regulationId, yearsemesterId, subjectId) => {
  return async (dispatch) => {
    try {
      const userId = getItem('userId')
      await dispatch(getSectionBegin());
      const query = await DataService.get(`/section/teachersections/${regulationId}/${yearsemesterId}/${subjectId}/${userId}`);
      const token = getItem('access_token');
      const data = query.data
      if (token && query.data !== null && query.data.Error === false) {
        await dispatch(getSectionSuccess(query.data.payload));
      }
      else if (data !== null && data.Error === true) {
        if (Object.keys(data).length >= 3 && data.statusCode == 401) {
          message.error(data.message)
          await dispatch(getSectionSuccess([]));
          dispatch(logOut())
        }
        else {
          await dispatch(getSectionSuccess([]));
        }
      }
      else {
        await dispatch(getSectionSuccess([]));
      }
    } catch (err) {
      await dispatch(getSectionErr(err));
    }
  };
};

export {
  getSectionsRead,
};
