
import React, { useState, useEffect } from 'react';
import { Row, Col, Table, Button, Input, Modal, Form, Space, Select, Tag } from 'antd';
import UilEdit from '@iconscout/react-unicons/icons/uil-edit';
import UilTrash from '@iconscout/react-unicons/icons/uil-trash-alt';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Cards } from '../../components/cards/frame/cards-frame';
import { TableWrapper, Main } from '../styled';
import { PageHeader } from '../../components/page-headers/page-headers';
import { useDispatch, useSelector } from 'react-redux';
import { regulationDataSingle, regulationDataSubmit, regulationDataUpdate } from '../../redux/regulation/actionCreator';
import {UserTableStyleWrapper} from './styles/tablestyle'
import { ProjectList, ProjectSorting } from '../project/style';
import { AutoComplete } from '../../components/autoComplete/autoComplete';
import { useParams } from 'react-router-dom';

const { Option } = Select;

function RegulationSingle() {

    const { regulationId } = useParams()

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [form] = Form.useForm();
  const [editForm] = Form.useForm();

  
  const [editRecord, setEditRecord] = useState(null);
  
  const dispatch = useDispatch();
  const { regulations, isLoading } = useSelector((state) => {
    return {
      regulations: state.RegulationSingle.data,
      isLoading: state.RegulationSingle.loading,
    };
  });
  
  useEffect(() => {
    if (dispatch) {
      dispatch(regulationDataSingle(regulationId));
    }
  }, [regulationId]);
  
  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(()=> {    
    if(regulations){
      setDataSource(regulations.subjects)
      setLoading(false);
    }
  },[regulations])  


  const [branches,setBranches] = useState([])
  const [yearSemId,setYearSemId] = useState(-1)
  const [branchId,setBranchId] = useState(-1)

  const onYearSorting = (yearsemId) => {      
      setBranchId(-1)
      if(yearsemId === '-1')
      {
        setYearSemId(-1)
        setBranches([])
          setDataSource(regulations.subjects)
    }    
    else{
        setYearSemId(yearsemId)
        let yearFilter = regulations.yearsem.filter(element => element.id === yearsemId);
        setBranches(yearFilter[0].branches)
        let subFilter = regulations.subjects.filter(element => element.yearsemsterId === yearsemId);
        setDataSource(subFilter)
    }
  };

  const onBranchSorting = (branchId) => {    
    if(branchId === '-1')
    {        
        setBranchId(-1)
        let subFilter = regulations.subjects.filter(element => element.yearsemsterId === yearSemId);
        setDataSource(subFilter)
    }    
    else{                
        setBranchId(branchId)
        let subFilter = regulations.subjects.filter(element => (element.branchId === branchId) && (element.yearsemsterId === yearSemId) );        
        setDataSource(subFilter)
    }
  };

  const keys = ['code','subname']
  const handleSearch = (searchText) => {
    const data = regulations.subjects.filter((item) => keys.some(key=>item[key].toLowerCase().includes(searchText.toLowerCase())));
    setDataSource(data)
  };
  

  const columns = [
    {
      title: 'ID',
      dataIndex: 'subjectId',
      key: 'id',
    },
    {
      title: 'Subject Code',
      dataIndex: 'code',
      key: 'code',
    },
    {
        title: 'Subject Name',
        dataIndex: 'subname',
        key: 'subname',
    },
    {
        title: 'Topics',
        dataIndex: 'topics',
        key: 'topics',
    },    
    {
        title: 'Year Semester',
        dataIndex: 'yearsemester',
        key: 'yearsemester',
    },
    {
        title: 'Branch',
        dataIndex: 'branch',
        key: 'branch',
    },
    {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
    },
    {
      title: 'Created On',
      dataIndex: 'createdon',
      key: 'createdon',
    },
    {
      title: 'Updated On',
      dataIndex: 'updatedon',
      key: 'updatedon',
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (text, record) => (
        <Space>
          <Button icon={<UilEdit />} onClick={() => handleEdit(record)}>
          </Button>
          <Button icon={<UilTrash />} onClick={() => handleDelete(record)}>
          </Button>
        </Space>
      ),
    },
  ];

  const handleButtonClick = () => {
    setIsModalVisible(true);
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
  };

  const handleModalAdd = () => {
    form.validateFields().then((values) => {
      const newRow = {
        key: String(dataSource.length + 1),
        id: String(dataSource.length + 1),
        code: values.code,
        status: values.status || 'ACTIVE',
      
      };
      setDataSource([...dataSource, newRow]);
      form.resetFields();
      setIsModalVisible(false);
      dispatch(regulationDataSubmit(newRow));
    });
  };

  const handleModalUpdate = () => {
    editForm.validateFields().then((values) => {
      const updatedRecord = {
        ...editRecord,
        code: values.code,
        status: values.status,
      };

      const updatedDataSource = dataSource.map((item) =>
        item.id === updatedRecord.id ? updatedRecord : item
      );

      setDataSource(updatedDataSource);
      setIsEditModalVisible(false);
      delete updatedRecord['createdon']
      delete updatedRecord['updatedon']
      dispatch(regulationDataUpdate(updatedRecord));
    });
  };

  const handleEdit = (record) => {
    setEditRecord(record);
    setIsEditModalVisible(true);
    editForm.setFieldsValue({
      code: record.code,
      status: record.status,
    });
  };

  const handleDelete = (record) => {
    Modal.confirm({
      title: 'Are you sure you want to delete this record?',
      icon: <ExclamationCircleOutlined />,
      content: 'Once deleted the action can be undone',
      okText: 'Delete',
      okType: 'danger',
      cancelText: 'Cancel',
      onOk() {
        const updatedDataSource = dataSource.filter((item) => item.id !== record.id);
        const updatedDataSourceWithSNo = updatedDataSource.map((item, index) => ({
          ...item,
          id: String(index + 1),
          key: String(index + 1),
        }));
        setDataSource(updatedDataSourceWithSNo);
      },
    });
  };

  const showEditModal = (record) => {
    handleEdit(record);
  };

  return (
    <>        
      <PageHeader className="ninjadash-page-header-main" title={`Regulation - ${regulations !== null && regulations.regcode}`} 
        buttons={[
            <Button onClick={handleButtonClick} key="1" type="primary" size="default">
              Add Regulation
            </Button>,
          ]}/>    
      <Main>
        <Row gutter={25}>
          <Col sm={24} xs={24}>
          <ProjectSorting>
              <div className="project-sort-bar">                
                <div className="project-sort-search">
                  <AutoComplete onSearch={handleSearch} dataSource={dataSource} placeholder="Search subject" patterns />
                </div>
                <div className="project-sort-group">
                  <div className="sort-group">
                    <span>YearSemester</span>
                    <Select onChange={onYearSorting} defaultValue="-1" value={yearSemId === -1 ? 'All' : yearSemId}>
                      <Select.Option value='-1'>All</Select.Option>
                      {
                        regulations !== null && regulations.yearsem.length !==0 &&  regulations.yearsem.map((obj)=>
                            <Select.Option value={obj.id}>{obj.code}</Select.Option>  
                        )
                      }                                          
                    </Select>
                  </div>
                </div>
                <div>
                    <div className="sort-group">
                    <span>Branch</span>
                    <Select onChange={onBranchSorting} defaultValue="-1" value={branchId === -1 ? 'All' : branchId}>
                      <Select.Option value="-1">All</Select.Option>
                      {
                        branches.length !==0 && branches.map((obj)=>
                            <Select.Option value={obj.id}>{obj.code}</Select.Option>  
                        )
                      }
                    </Select>
                  </div>
                </div>
              </div>
            </ProjectSorting>
            <Cards headless>
            <ProjectList>
            <div className="table-responsive">
                  <Table dataSource={dataSource} columns={columns} />
                </div>
              </ProjectList>
            </Cards>
          </Col>
        </Row>        
      </Main>
      <Modal
        title="Add Regulation"
        open={isModalVisible}
        onCancel={handleModalCancel}
        footer={[
          <Button key="add" type="primary" onClick={handleModalAdd}>
            Add
          </Button>,
        ]}
      >
        <Form form={form} layout="vertical">
          <Form.Item name="code" label="Code" rules={[{ required: true }]}>
            <Input/>
          </Form.Item>
          <Form.Item name="status" label="Status">
            <Select defaultValue="ACTIVE">
              <Option value="ACTIVE">ACTIVE</Option>
              <Option value="INACTIVE">INACTIVE</Option>
            </Select>
          </Form.Item>
          {/* <Form.Item name="year" label="Year" rules={[{ required: true }]}>
          <Select placeholder="Select Year">
            <Option value="1st">1st</Option>
            <Option value="2nd">2nd</Option>
            <Option value="3rd">3rd</Option>
            <Option value="4th">4th</Option>
          </Select>
          </Form.Item> */}
        </Form>
      </Modal>
      <Modal
        title="Edit Regulation"
        open={isEditModalVisible}
        onCancel={() => setIsEditModalVisible(false)}
        footer={[
          <Button key="update" type="primary" onClick={handleModalUpdate}>
            Update
          </Button>
        ]}
      >
        <Form form={editForm} layout="vertical">
        <Form.Item name="code" label="Code" rules={[{ required: true }]}>
            <Input/>
          </Form.Item>
          <Form.Item name="status" label="Status" rules={[{ required: true }]}>
            <Select>
              <Option value="ACTIVE">ACTIVE</Option>
              <Option value="INACTIVE">INACTIVE</Option>
            </Select>
          </Form.Item>
          {/* <Form.Item name="year" label="Year" rules={[{ required: true }]}>
          <Select>
            <Option value="1st">1st</Option>
            <Option value="2nd">2nd</Option>
            <Option value="3rd">3rd</Option>
            <Option value="4th">4th</Option>
          </Select>
          </Form.Item> */}
        </Form>
      </Modal>
    </>
  );
}

export default RegulationSingle;