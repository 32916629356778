import actions from './actions';

const {
  TESSELLATOR_TOPICS_READ_BEGIN,
  TESSELLATOR_TOPICS_READ_SUCCESS,
  TESSELLATOR_TOPICS_READ_ERR,

  TESSELLATOR_UNIT_READ_BEGIN,
  TESSELLATOR_UNIT_READ_SUCCESS,
  TESSELLATOR_UNIT_READ_ERR,

  TESSELLATOR_TOPIC_PROGRAMS_READ_BEGIN,
  TESSELLATOR_TOPIC_PROGRAMS_READ_SUCCESS,
  TESSELLATOR_TOPIC_PROGRAMS_READ_ERR,

  TESSELLATOR_UNIT_PROGRAMS_READ_BEGIN,
  TESSELLATOR_UNIT_PROGRAMS_READ_SUCCESS,
  TESSELLATOR_UNIT_PROGRAMS_READ_ERR,

  MAP_TESSELLATOR_TOPIC_UPDATE_BEGIN,
  MAP_TESSELLATOR_TOPIC_UPDATE_SUCCESS,
  MAP_TESSELLATOR_TOPIC_UPDATE_ERR,

  MAP_TESSELLATOR_UNIT_UPDATE_BEGIN,
  MAP_TESSELLATOR_UNIT_UPDATE_SUCCESS,
  MAP_TESSELLATOR_UNIT_UPDATE_ERR,

  MAP_TESSELLATOR_TOPIC_TO_NULL_UPDATE_BEGIN,
  MAP_TESSELLATOR_TOPIC_TO_NULL_UPDATE_SUCCESS,
  MAP_TESSELLATOR_TOPIC_TO_NULL_UPDATE_ERR,

  MAP_TESSELLATOR_UNIT_TO_NULL_UPDATE_BEGIN,
  MAP_TESSELLATOR_UNIT_TO_NULL_UPDATE_SUCCESS,
  MAP_TESSELLATOR_UNIT_TO_NULL_UPDATE_ERR,

  TELESCOPE_TOPICS_READ_BEGIN,
  TELESCOPE_TOPICS_READ_SUCCESS,
  TELESCOPE_TOPICS_READ_ERR,

  TELESCOPE_TOPIC_PROGRAMS_READ_BEGIN,
  TELESCOPE_TOPIC_PROGRAMS_READ_SUCCESS,
  TELESCOPE_TOPIC_PROGRAMS_READ_ERR,

  MAP_TELESCOPE_TOPIC_UPDATE_BEGIN,
  MAP_TELESCOPE_TOPIC_UPDATE_SUCCESS,
  MAP_TELESCOPE_TOPIC_UPDATE_ERR,

  MAP_TELESCOPE_TOPIC_TO_NULL_UPDATE_BEGIN,
  MAP_TELESCOPE_TOPIC_TO_NULL_UPDATE_SUCCESS,
  MAP_TELESCOPE_TOPIC_TO_NULL_UPDATE_ERR,

} = actions;

const initialTopicsState = {
  data: [],
  loading: false,
  error: null,
};

const initialUnitState = {
  data: [],
  loading: false,
  error: null,
};

const initialProgramsState = {
  data: [],
  loading: false,
  error: null,
};

const initialUnitProgramsState = {
  data: [],
  loading: false,
  error: null,
};

const MapResponse = {
  data: null,
  loading: false,
  error: null,
};

const MapUnitResponse = {
  data: null,
  loading: false,
  error: null,
};

const MapResponseToNull = {
  data: null,
  loading: false,
  error: null,
};

const MapUnitResponseToNull = {
  data: null,
  loading: false,
  error: null,
};

const initialTelescopeTopicsState = {
  data: [],
  loading: false,
  error: null,
};

const initialTelescopeProgramsState = {
  data: [],
  loading: false,
  error: null,
};

const MapTeleResponse = {
  data: null,
  loading: false,
  error: null,
};

const MapTeleResponseToNull = {
  data: null,
  loading: false,
  error: null,
};


const TessellatorTopicsReducer = (state = initialTopicsState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case TESSELLATOR_TOPICS_READ_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case TESSELLATOR_TOPICS_READ_SUCCESS:
      return {
        ...state,
        data,
        error: false,
        loading: false,
      };

    case TESSELLATOR_TOPICS_READ_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

const TessellatorUnitReducer = (state = initialUnitState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case TESSELLATOR_UNIT_READ_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case TESSELLATOR_UNIT_READ_SUCCESS:
      return {
        ...state,
        data,
        error: false,
        loading: false,
      };

    case TESSELLATOR_UNIT_READ_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

const TessellatorTopicPogramsReducer = (state = initialProgramsState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case TESSELLATOR_TOPIC_PROGRAMS_READ_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case TESSELLATOR_TOPIC_PROGRAMS_READ_SUCCESS:
      return {
        ...state,
        data,
        error: false,
        loading: false,
      };

    case TESSELLATOR_TOPIC_PROGRAMS_READ_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

const TessellatorUnitPogramsReducer = (state = initialUnitProgramsState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case TESSELLATOR_UNIT_PROGRAMS_READ_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case TESSELLATOR_UNIT_PROGRAMS_READ_SUCCESS:
      return {
        ...state,
        data,
        error: false,
        loading: false,
      };

    case TESSELLATOR_UNIT_PROGRAMS_READ_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

const MapTessellatorTopicReducer = (state = MapResponse, action) => {
  const { type, data, err } = action;
  switch (type) {
    case MAP_TESSELLATOR_TOPIC_UPDATE_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case MAP_TESSELLATOR_TOPIC_UPDATE_SUCCESS:
      return {
        ...state,
        data,
        error: false,
        loading: false,
      };

    case MAP_TESSELLATOR_TOPIC_UPDATE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

const MapTessellatorUnitReducer = (state = MapUnitResponse, action) => {
  const { type, data, err } = action;
  switch (type) {
    case MAP_TESSELLATOR_UNIT_UPDATE_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case MAP_TESSELLATOR_UNIT_UPDATE_SUCCESS:
      return {
        ...state,
        data,
        error: false,
        loading: false,
      };

    case MAP_TESSELLATOR_UNIT_UPDATE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

const MapTessellatorTopicToNullReducer = (state = MapResponseToNull, action) => {
  const { type, data, err } = action;
  switch (type) {
    case MAP_TESSELLATOR_TOPIC_TO_NULL_UPDATE_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case MAP_TESSELLATOR_TOPIC_TO_NULL_UPDATE_SUCCESS:
      return {
        ...state,
        data,
        error: false,
        loading: false,
      };

    case MAP_TESSELLATOR_TOPIC_TO_NULL_UPDATE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

const MapTessellatorUnitToNullReducer = (state = MapUnitResponseToNull, action) => {
  const { type, data, err } = action;
  switch (type) {
    case MAP_TESSELLATOR_UNIT_TO_NULL_UPDATE_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case MAP_TESSELLATOR_UNIT_TO_NULL_UPDATE_SUCCESS:
      return {
        ...state,
        data,
        error: false,
        loading: false,
      };

    case MAP_TESSELLATOR_UNIT_TO_NULL_UPDATE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

const TelescopeTopicsReducer = (state = initialTelescopeTopicsState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case TELESCOPE_TOPICS_READ_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case TELESCOPE_TOPICS_READ_SUCCESS:
      return {
        ...state,
        data,
        error: false,
        loading: false,
      };

    case TELESCOPE_TOPICS_READ_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};


const TelescopeTopicPogramsReducer = (state = initialTelescopeProgramsState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case TELESCOPE_TOPIC_PROGRAMS_READ_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case TELESCOPE_TOPIC_PROGRAMS_READ_SUCCESS:
      return {
        ...state,
        data,
        error: false,
        loading: false,
      };

    case TELESCOPE_TOPIC_PROGRAMS_READ_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};


const MapTelescopeTopicReducer = (state = MapTeleResponse, action) => {
  const { type, data, err } = action;
  switch (type) {
    case MAP_TELESCOPE_TOPIC_UPDATE_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case MAP_TELESCOPE_TOPIC_UPDATE_SUCCESS:
      return {
        ...state,
        data,
        error: false,
        loading: false,
      };

    case MAP_TELESCOPE_TOPIC_UPDATE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};


const MapTelescopeTopicToNullReducer = (state = MapTeleResponseToNull, action) => {
  const { type, data, err } = action;
  switch (type) {
    case MAP_TELESCOPE_TOPIC_TO_NULL_UPDATE_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case MAP_TELESCOPE_TOPIC_TO_NULL_UPDATE_SUCCESS:
      return {
        ...state,
        data,
        error: false,
        loading: false,
      };

    case MAP_TELESCOPE_TOPIC_TO_NULL_UPDATE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};


export {
  TessellatorTopicsReducer,
  TessellatorTopicPogramsReducer,
  MapTessellatorTopicReducer,
  MapTessellatorTopicToNullReducer,
  TessellatorUnitPogramsReducer,
  TessellatorUnitReducer,
  MapTessellatorUnitReducer,
  MapTessellatorUnitToNullReducer,
  TelescopeTopicsReducer,
  TelescopeTopicPogramsReducer,
  MapTelescopeTopicReducer,
  MapTelescopeTopicToNullReducer
};
