import { readRecentDataBegin, readRecentDataSuccess, readRecentDataError } from './actions';
import { DataService } from '../../config/dataService/dataService';
import { getItem } from '../../utility/localStorageControl';


export const RecentActionsDataRead = () => {
    return async (dispatch) => {
        try {
            const userId = getItem('userId')
            await dispatch(readRecentDataBegin());
            const response = await DataService.get(`userlog/useraction/${userId}`);
            const token = getItem('access_token');
            const data = response.data;            
            if (token && data !== null &&  data.Error === false) {
                await dispatch(readRecentDataSuccess(data.payload));
            } else {
                // await dispatch(readRecentDataSuccess([]));
            }
        } catch (err) {
            await dispatch(readRecentDataError(err));
        }
    };
};
