import { DataService } from '../../config/dataService/dataService';
import {
    readStudentBadgesBegin,
    readStudentBadgesSuccess,
    readStudentBadgesError,
    readBadgeByIdBegin,
    readBadgeByIdSuccess,
    readBadgeByIdError,
    readSubBadgeByIdBegin,
    readSubBadgeByIdSuccess,
    readSubBadgeByIdError,
} from './actions';
import { message } from 'antd';
import { logOut } from '../authentication/actionCreator';
import { getItem } from '../../utility/localStorageControl';


const StudentBadgesRead = () => {
    return async (dispatch) => {
        try {
            const userId = getItem('userId')
            await dispatch(readStudentBadgesBegin());
            const response = await DataService.get(`usermaster/totalbadges/${userId}`);
            const token = getItem('access_token');
            const data = response.data;
            if (token && data !== null && data.Error === false) {
                await dispatch(readStudentBadgesSuccess(data.payload));
            }
            else if (data !== null && data.Error === true) {
                if (Object.keys(data).length >= 3 && data.statusCode == 401) {
                    message.error(data.message)
                    await dispatch(readStudentBadgesSuccess([]));
                    dispatch(logOut())
                }
                else {
                    await dispatch(readStudentBadgesSuccess([]));
                }
            }
            else {
                await dispatch(readStudentBadgesSuccess([]));
            }
        } catch (err) {
            await dispatch(readStudentBadgesError(err));
        }
    };
};

const ReadBadgeById = (badgeId) => {
    return async (dispatch) => {
        try {
            await dispatch(readBadgeByIdBegin());
            const response = await DataService.get(`badges/public/${badgeId}`);
            const token = getItem('access_token');
            const data = response.data;
            if (token && data !== null && data.Error === false) {
                await dispatch(readBadgeByIdSuccess(data));
            } else {
                await dispatch(readBadgeByIdSuccess(data));
            }
        } catch (err) {
            await dispatch(readBadgeByIdError(err));
        }
    };
};

const subjectBadgesDataRead = (subjectId) => {
    return async (dispatch) => {
        try {
            const userId = getItem('userId')
            await dispatch(readSubBadgeByIdBegin());
            const response = await DataService.get(`usermaster/totalbadgesbysub/${subjectId}/${userId}`);
            const token = getItem('access_token');
            const data = response.data;
            if (token && data !== null && data.Error === false) {
                await dispatch(readSubBadgeByIdSuccess(data.payload));
            } else {
                await dispatch(readSubBadgeByIdSuccess(null));
            }
        } catch (err) {
            await dispatch(readSubBadgeByIdError(err));
        }
    };
};


export { StudentBadgesRead, ReadBadgeById, subjectBadgesDataRead }