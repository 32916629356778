// import { firebaseReducer } from 'react-redux-firebase';
import { combineReducers } from 'redux';
import { firestoreReducer } from 'redux-firestore';
import { AuthReducer, ForgotPasswordReducer, VerifyOTPReducer, ResetPassReducer, MessageReducer, CollegeWiseMessageReducer } from './authentication/reducers';
import ChangeLayoutMode from './themeLayout/reducers';
import { SUBJECT_UNITSReducer, SUBJECTReducer, UNITReducer, TeacherRegSubjectsReducer,getUnitQAReducer } from './teacherSubjectsUnits/reducers'
import { TOPICSReducer, TOPICContentReducer, AssessmentFlagUpdateReducer, LearningFlagUpdateReducer, ContentFlagUpdateReducer } from './teacherTopicsMenu/reducers'
import { REGULATIONReducer, REGULATIONSingleReducer } from './regulation/reducers'
import { YEARSEMESTERReducer, YEARSEMESTERSingleReducer } from './yearsemester/reducers'
import { BRANCHReducer, BRANCHSingleReducer } from './branch/reducers'
import { SUBJECTSReducer, SUBJECTSByBranchReducer, SUBJECTSSingleReducer } from './subjects/reducers'
import { UploadContentReducer } from './uploadContent/reducers'
import { ReviewContentReducer, ReviewChunkUpdateReducer, DeleteChunkReducer } from './reviewContent/reducers'
import { CreateTopicReducer,UpdateTopicDetailsReducer,TOPICSingleReducer, TOPICSTATUSReducer, TOPICSByUnitReducer, TopicClearReducer, StudentTopicsByUnitReducer, SendTopicToHodReducer } from './topics/reducers';
import { ReadQAReducer, postResponseReducer, AddNewSingleQuestionReducer, UpdateQAReducer, ReadAllQAByTopicReducer, DeleteQAReducer, AnswerEvaluateReducer, GETATTEMPTEDQAReducer, UnitWiseQaGenerationReducer, UnitWiseQaReadReducer, UnitQAUpdateReducer, DeleteUnitQAReducer, GetUnitAttemptedQAReducer,EvaluationFeedbackReducer } from './generateQuestions/reducers';
import { postQuizResponseReducer, ReadAllQuizByTopicReducer, UpdateQuizReducer, DeleteQuizReducer, ReadAllQuizReducer } from './generateQuiz/reducers';
import { ReadStudentSubjectsReducer, ReadSubjectUnitsReducer } from './studentSubjectsUnits/reducers';
import { getScheduleWeeksReducer, updateScheduleReducer, getScheduledDataReducer, getCurrentWeekReducer, getRegYrsReducer, getNgrokUrlReducer } from './schedule/reducers';
import { ProgressReducer } from './teacherDashboard/reducers';
import { QAFReducer } from './QAFeedback/reducers';
import { ContentFeedbackReducer, GetContentFBQReducer, VerifyFeedbackReducer, PostFeedbackReducer } from './contentFeedback/reducers';
import { RecentActionsDataReducer } from './recentActions/reducers';
import { PdfFileReducer } from './pdf/reducers';
import { ReadAllQuizToAttemptReducer, SaveQuizReducer, SubmitAllQuizReducer, GetQuizResultReducer } from './getQuiztoAttempt/reducers';
import { StudentRecentActionsDataReducer } from './studentRecentActions/reducers';
import { StudentBadgesDataReducer, ReadBadgeByIdReducer, ReadSubBadgeByIdReducer } from './studentBadgesEarned/reducers';
import { StudentWeeklyTopicsReducer } from './studentWeeklyTopics/reducers';
import { TessellatorTopicsReducer, TessellatorTopicPogramsReducer, MapTessellatorTopicReducer, MapTessellatorTopicToNullReducer, TessellatorUnitPogramsReducer, MapTessellatorUnitReducer, MapTessellatorUnitToNullReducer, TelescopeTopicsReducer, TelescopeTopicPogramsReducer, MapTelescopeTopicReducer, MapTelescopeTopicToNullReducer } from './tessellatorTopics/reducers';
import { TeacherMonitorReducer } from './teacher-monitor/reducers';
import { getSectionsReducer } from './section/reducers';
import { ChangePasswordReducer } from './changepassword/reducers';
import { getLeaderboardReducer } from './leaderboard/reducers';
import { DailyLoginCountReducer, CurrentweekYearwiseReducer, TodaysReadAssessmentBadgesReducer } from './studentrelatedcount/reducers';
import LayoutChangeReducer from './themeLayout/reducers';
import { AnswersReducer, ManualEvaluationReducer, QaReportsReducer } from './answers/reducers';
import { TranscribeReducer } from './transcribe/reducers';
import { GetAssessmentLoginsReducer, updateStudentFlagReducer } from './assessmentLogins/reducers';
import { GetStudentsProgramsReducer } from './studentsPrograms/reducers'
import { UnitStatusReducer, SendUnitToHodReducer } from './unit/reducers'
import { ProgrammingReducer, TesselatorReducer } from './programming/reducer';

const rootReducers = combineReducers({
  // fb: firebaseReducer,
  fs: firestoreReducer,
  auth: AuthReducer,
  ForgotPass: ForgotPasswordReducer,
  VerifyOTP: VerifyOTPReducer,
  ResetPassRes: ResetPassReducer,
  ChangeLayoutMode,
  currentWeek: getCurrentWeekReducer,
  ChangePassword: ChangePasswordReducer,
  msg: MessageReducer,
  Layout: LayoutChangeReducer,

  // teacher reducers
  CreateTopicRes: CreateTopicReducer,
  UpdateTopicDetailsRes: UpdateTopicDetailsReducer,
  ProgressData: ProgressReducer,
  QAFeedbackData: QAFReducer,
  CFData: ContentFeedbackReducer,
  RecentActions: RecentActionsDataReducer,
  DailyLoginCount: DailyLoginCountReducer,
  CurrentWeekYrwise: CurrentweekYearwiseReducer,
  NgrokUrl: getNgrokUrlReducer,
  TodaysBadgesCount: TodaysReadAssessmentBadgesReducer,
  AssessmentLogins: GetAssessmentLoginsReducer,
  AssessmentStudentFlagUpdateRes: updateStudentFlagReducer,
  unitStatus: UnitStatusReducer,

  SubjectsUnits: SUBJECT_UNITSReducer,
  Subject: SUBJECTReducer,
  Unit: UNITReducer,
  Topics: TOPICSReducer,
  UploadContentRes: UploadContentReducer,
  ChunksForReview: ReviewContentReducer,
  updatedChunk: ReviewChunkUpdateReducer,
  DeleteChunkRes: DeleteChunkReducer,
  singleTopic: TOPICSingleReducer,
  TopicStatus: TOPICSTATUSReducer,
  GenerateQARes: postResponseReducer,
  QA: ReadQAReducer,
  QAUpdateRes: UpdateQAReducer,
  AllQAByTopic: ReadAllQAByTopicReducer,
  DeleteQARes: DeleteQAReducer,
  AnsEvaluateRes: AnswerEvaluateReducer,
  GenerateQuizRes: postQuizResponseReducer,
  AllQuiz: ReadAllQuizReducer,
  quizUpdateRes: UpdateQuizReducer,
  DeleteQuizRes: DeleteQuizReducer,
  AllQuizByTopic: ReadAllQuizByTopicReducer,
  Weeks: getScheduleWeeksReducer,
  ScheduleUpdateRes: updateScheduleReducer,
  TopicClearRes: TopicClearReducer,
  TopicScheduledData: getScheduledDataReducer,
  TessellatorTopics: TessellatorTopicsReducer,
  TessellatorTopicPrograms: TessellatorTopicPogramsReducer,
  MapTessellatorTopicRes: MapTessellatorTopicReducer,
  MapTsTopicToNullRes: MapTessellatorTopicToNullReducer,
  TeacherMonitor: TeacherMonitorReducer,
  Answers: AnswersReducer,
  Sections: getSectionsReducer,
  RegYrsem: getRegYrsReducer,
  TopicContent: TOPICContentReducer,
  TranscribeRes: TranscribeReducer,
  AssessmentFlagRes: AssessmentFlagUpdateReducer,
  TeacherRegSubjects: TeacherRegSubjectsReducer,
  unitQA: getUnitQAReducer,
  ManualEvalRes: ManualEvaluationReducer,
  StuQaReports: QaReportsReducer,
  UnitWiseQaRes: UnitWiseQaGenerationReducer,
  unitwiseQa: UnitWiseQaReadReducer,
  UnitQAUpdateRes: UnitQAUpdateReducer,
  DeleteUnitQARes: DeleteUnitQAReducer,
  TessellatorUnitPrograms: TessellatorUnitPogramsReducer,
  MapTessellatorUnitRes: MapTessellatorUnitReducer,
  MapTsUnitToNullRes: MapTessellatorUnitToNullReducer,
  AddSingleQuestionRes: AddNewSingleQuestionReducer,
  TelescopeTopics: TelescopeTopicsReducer,
  TelescopeTopicPrograms: TelescopeTopicPogramsReducer,
  MapTelescopeTopicRes: MapTelescopeTopicReducer,
  MapTlTopicToNullRes: MapTelescopeTopicToNullReducer,
  sendTopicToHodRes: SendTopicToHodReducer,
  sendUnitToHodRes: SendUnitToHodReducer,
  LearningFlagRes: LearningFlagUpdateReducer,
  ContentFlagRes: ContentFlagUpdateReducer,

  // admin reducers
  Regulations: REGULATIONReducer,
  RegulationSingle: REGULATIONSingleReducer,
  YearSemester: YEARSEMESTERReducer,
  YearSemesterSingle: YEARSEMESTERSingleReducer,
  Branches: BRANCHReducer,
  BranchSingle: BRANCHSingleReducer,
  Subjects: SUBJECTSReducer,
  SubjectsBranchwise: SUBJECTSByBranchReducer,
  SubjectSingle: SUBJECTSSingleReducer,

  // student reducers
  collegeWiseNotce: CollegeWiseMessageReducer,
  StudentSubjects: ReadStudentSubjectsReducer,
  Units: ReadSubjectUnitsReducer,
  UnitTopics: TOPICSByUnitReducer,
  CFQuestions: GetContentFBQReducer, // content feedback questions
  VerifyRes: VerifyFeedbackReducer,
  FBPostRes: PostFeedbackReducer,
  PdfFile: PdfFileReducer,
  QuiztoAttempt: ReadAllQuizToAttemptReducer,
  QuizSaveRes: SaveQuizReducer,
  SubmitAllQuizRes: SubmitAllQuizReducer,
  QuizResult: GetQuizResultReducer,
  StudentRecentActions: StudentRecentActionsDataReducer,
  StudentBadges: StudentBadgesDataReducer,
  SingleBadgeImg: ReadBadgeByIdReducer,
  SubBadges: ReadSubBadgeByIdReducer,
  StudentWeeklyTopics: StudentWeeklyTopicsReducer,
  studentTopicsByUnit: StudentTopicsByUnitReducer,
  leaderboardResults: getLeaderboardReducer,
  AttemptedQA: GETATTEMPTEDQAReducer,
  studentPrograms: GetStudentsProgramsReducer,
  UnitAttemptedQA: GetUnitAttemptedQAReducer,
  StudentFeedback:EvaluationFeedbackReducer,
  Programming: ProgrammingReducer,
  Tesselator: TesselatorReducer,

});

export default rootReducers;
