import actions from './actions';
import { DataService } from '../../config/dataService/dataService';
import { getItem } from '../../utility/localStorageControl';

const {
    weeklyTopicsReadBegin,
    weeklyTopicsReadSuccess,
    weeklyTopicsReadErr,
}=actions;

const studentWeeklyTopicsDataRead = (regulationId,yearsemesterId,branchId) => {
    return async (dispatch) => {
      try {
        await dispatch(weeklyTopicsReadBegin());
        const query = await DataService.get(`/branch/weeklytopics/${regulationId}/${yearsemesterId}/${branchId}`);
        const token = getItem('access_token');
        if (token && query.data !== null && query.data.Error === false) {
          await dispatch(weeklyTopicsReadSuccess(query.data.payload));
        } else {
          await dispatch(weeklyTopicsReadSuccess([]));
          // await dispatch(weeklyTopicsAddErr('Unauthorized access'));
        }
      } catch (err) {
        await dispatch(weeklyTopicsReadErr(err));
      }
    };
  };
  export {studentWeeklyTopicsDataRead};