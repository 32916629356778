import React, { lazy, Suspense, useEffect } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import Dashboard from '../../container/student/Dashboard';
import Units from '../../container/student/Units';
import withStudentLayout from '../../layout/withStudentLayout';
import TopicContent from '../../container/student/TopicContent';
import CustomSpinner from '../../container/ui-elements/spinner/CustomSpinner';
import ChangePassword from '../../container/ChangePassword';
import Profile from '../../container/student/Profile';
import Password from '../../container/profile/settings/overview/Passwoard';
import { getItem } from '../../utility/localStorageControl';
import LaunchingTessellator from '../../container/student/LaunchingTessellator';

const NotFound = lazy(() => import('../../container/pages/404'));

const Student = React.memo(() => {

  const isForcePasswordEnabled = getItem('isForcePasswordEnabled')
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Suspense
      fallback={
        <div className="">
          <CustomSpinner />
        </div>
      }
    >
      <Routes>
        {isForcePasswordEnabled === true ?
          <Route path="*" element={<Password />} />
          :
          <>
            <Route index path="/" element={<Dashboard />} />
            {/* <Route path="/change-password" element={<ChangePassword />} /> */}
            <Route path="/student-profile/*" element={<Profile />} />
            <Route path="/subject/:subjectId/*" element={<Units />} />
            <Route path="/subject/:subjectId/unit/:unitId/topic/:topicId/*" element={<TopicContent />} />
            <Route path="/subject/:subjectId/unit/:unitId/topic/:topicId/programs/:programId" element={<LaunchingTessellator />} />
            <Route path="*" element={<NotFound />} />
          </>
        }
      </Routes>
    </Suspense>
  );
});

export default withStudentLayout(Student);
